import React from "react";

const PoolSide = () => {
  return (
    <svg
      width="100%"
      height="auto"
      viewBox="0 0 231 288"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M216.79 219.872C216.494 225.517 214.337 228.183 211.855 228.053C209.373 227.923 207.507 225.046 207.802 219.401C208.098 213.757 210.476 206.882 212.958 207.012C215.44 207.142 217.086 214.228 216.79 219.872Z"
        fill="#9395C8"
      />
      <path
        d="M203.224 217.376C205.612 222.499 204.96 225.867 202.707 226.917C200.455 227.967 197.456 226.303 195.067 221.18C192.678 216.057 191.55 208.87 193.802 207.82C196.054 206.769 200.835 212.253 203.224 217.376Z"
        fill="#9395C8"
      />
      <path
        d="M201.5 224C201.375 224.246 200.523 224.196 198.914 223.376C197.305 222.556 196.102 221.692 196.227 221.446C196.352 221.2 197.759 221.665 199.368 222.485C200.977 223.305 201.625 223.754 201.5 224Z"
        fill="#131413"
      />
      <path
        d="M211.68 225.139C211.447 225.288 210.738 224.814 209.764 223.293C208.79 221.772 208.188 220.419 208.421 220.27C208.654 220.121 209.632 221.233 210.606 222.754C211.58 224.275 211.912 224.99 211.68 225.139Z"
        fill="#0C0A0A"
      />
      <path
        d="M202.517 218.078C202.79 218.121 202.782 219.603 202.499 221.387C202.217 223.171 201.773 224.043 201.5 224C201.227 223.957 201.229 223.014 201.512 221.23C201.794 219.446 202.244 218.035 202.517 218.078Z"
        fill="#090A09"
      />
      <path
        d="M215.55 220.543C215.764 220.719 215.008 221.993 213.863 223.389C212.717 224.786 211.893 225.314 211.68 225.139C211.466 224.964 211.944 224.151 213.09 222.755C214.235 221.358 215.337 220.368 215.55 220.543Z"
        fill="#131514"
      />
      <g filter="url(#filter0_d_1022_5)">
        <path
          d="M128.815 192.109L129.897 201.547L128.463 202.215L127.154 190.789C135.313 188.255 139.985 187.639 148.514 188.341L148.685 189.832L149.425 196.289L147.935 196.46L147.195 190.002C139.882 189.642 135.864 190.168 128.815 192.109Z"
          fill="#2D2929"
        />
        <path
          d="M156.209 194.003C156.209 194.003 135.664 196.499 121.657 204.304C120.925 210.471 121.342 213.996 123.502 220.398L161.255 216.072C161.942 207.216 156.209 194.003 156.209 194.003Z"
          fill="#6CA5A1"
        />
        <circle
          cx="127.103"
          cy="207.907"
          r="3"
          transform="rotate(-6.53804 127.103 207.907)"
          fill="#F2F467"
        />
        <circle
          cx="127.786"
          cy="213.868"
          r="2"
          transform="rotate(-6.53804 127.786 213.868)"
          fill="#F2F467"
        />
        <circle
          cx="127.786"
          cy="213.868"
          r="1"
          transform="rotate(-6.53804 127.786 213.868)"
          fill="#C3C581"
        />
        <circle
          cx="127.103"
          cy="207.907"
          r="2"
          transform="rotate(-6.53804 127.103 207.907)"
          fill="#C3C581"
        />
        <rect
          x="142.543"
          y="202.111"
          width="14"
          height="1"
          transform="rotate(-6.53804 142.543 202.111)"
          fill="#413D3D"
        />
        <rect
          x="143.226"
          y="208.072"
          width="14"
          height="1"
          transform="rotate(-6.53804 143.226 208.072)"
          fill="#413D3D"
        />
        <rect
          x="142.999"
          y="206.085"
          width="14"
          height="1"
          transform="rotate(-6.53804 142.999 206.085)"
          fill="#413D3D"
        />
        <rect
          x="142.771"
          y="204.098"
          width="14"
          height="1"
          transform="rotate(-6.53804 142.771 204.098)"
          fill="#413D3D"
        />
        <rect
          x="143.454"
          y="210.059"
          width="14"
          height="1"
          transform="rotate(-6.53804 143.454 210.059)"
          fill="#413D3D"
        />
        <rect
          x="152.902"
          y="195.891"
          width="2"
          height="2"
          rx="1"
          transform="rotate(-6.53804 152.902 195.891)"
          fill="#FF0404"
        />
        <rect
          x="149.922"
          y="196.233"
          width="2"
          height="2"
          transform="rotate(-6.53804 149.922 196.233)"
          fill="#57CA62"
        />
      </g>
      <g filter="url(#filter1_d_1022_5)">
        <circle
          cx="177.292"
          cy="260.598"
          r="20.0596"
          transform="rotate(24.6526 177.292 260.598)"
          fill="white"
        />
      </g>
      <path
        d="M185.659 242.367C158.878 247.779 158.011 259.557 168.196 278.495C167.578 269.04 168.254 257.64 185.659 242.367Z"
        fill="#00C805"
      />
      <path
        d="M168.196 278.495C189.144 274.853 199.87 268.094 185.659 242.367C185.42 268.681 175.115 274.553 168.222 278.48L168.196 278.495Z"
        fill="#8DCC8F"
      />
      <path
        d="M187.5 243.5C187.5 244.605 187.105 246 186 246C184.895 246 183.5 245.105 183.5 244C183.5 242.895 184 242 185 242C186 242.5 187 243 187.5 243.5Z"
        fill="white"
      />
      <path
        d="M169.225 279.314C170.127 278.808 171.09 277.856 170.6 276.981C170.109 276.106 168.758 275.41 167.857 275.915C166.955 276.42 166.446 277.226 166.89 278.019C167.742 278.583 168.594 279.146 169.225 279.314Z"
        fill="white"
      />
      <path
        d="M79.8538 104.536L121.854 128.573L113.867 168.709L66.8674 143.209L79.8538 104.536Z"
        fill="white"
      />
      <path
        d="M119.354 171.573L113.354 168.573L121.854 128.573L119.354 171.573Z"
        fill="#D9D8D8"
      />
      <path
        d="M65.8537 146.536L66.8856 143.073L113.354 168.527L111.822 171.073L65.8537 146.536Z"
        fill="#D9D8D8"
      />
      <rect x="218.354" y="177.073" width="1" height="7" fill="#C4C4C4" />
      <path
        d="M113.353 168.573L119.354 171.073L119.354 174.073L111.853 171.073L113.353 168.573Z"
        fill="#C4C4C4"
      />
      <rect x="119.354" y="174.073" width="1" height="11" fill="#C4C4C4" />
      <rect
        x="121.981"
        y="128.463"
        width="105"
        height="43"
        transform="rotate(4 121.981 128.463)"
        fill="white"
      />
      <path
        d="M119.353 171.073L223.854 178.073L223.354 180.073L119.353 174.073L119.353 171.073Z"
        fill="#D9D8D8"
      />
      <rect x="221.354" y="106.036" width="1" height="6" fill="#C4C4C4" />
      <path
        d="M89.3672 38.1725L125.854 56.5363L117.867 96.6725L70.8672 71.1725L89.3672 38.1725Z"
        fill="white"
      />
      <rect
        x="125.981"
        y="56.4265"
        width="105"
        height="43"
        transform="rotate(4 125.981 56.4265)"
        fill="white"
      />
      <path
        d="M70.8857 74.5363L70.8857 71.0363L117.354 96.4909L115.822 99.0363L70.8857 74.5363Z"
        fill="#D9D8D8"
      />
      <rect x="123.354" y="102.036" width="1" height="11" fill="#C4C4C4" />
      <path
        d="M123.353 99.0363L227.854 106.036L227.354 108.036L123.353 102.036L123.353 99.0363Z"
        fill="#D9D8D8"
      />
      <path
        d="M117.353 96.5364L123.354 99.0363L123.354 102.036L115.853 99.0364L117.353 96.5364Z"
        fill="#C4C4C4"
      />
      <path
        d="M123.354 99.5363L117.354 96.5363L125.854 56.5363L123.354 99.5363Z"
        fill="#D9D8D8"
      />
      <g filter="url(#filter2_d_1022_5)">
        <path
          d="M147 31.5L127.094 31.6474C114.287 13.7452 103.284 4.65161 66.0936 4.14735C86.8306 -1.15539 114.367 -1.21381 147 31.5Z"
          fill="white"
        />
        <path
          d="M31.5664 77.2452L49.0938 60.6472C43.9851 39.2367 42.0755 32.5459 66.0938 4.14734C48.4871 16.3188 28.286 31.1548 31.5664 77.2452Z"
          fill="white"
        />
        <path
          d="M127.094 31.6472L103.094 36.1473C95.2182 17.9308 83.5462 13.2334 65.5938 4.1473C95.2404 2.94146 112.023 6.21703 127.094 31.6472Z"
          fill="#00C805"
        />
        <path
          d="M71.5938 47.1473C66.4532 31.1608 65.2732 21.2483 65.5938 4.14734C45.8408 24.6277 38.1591 36.567 49.0938 60.6473L71.5938 47.1473Z"
          fill="#00C805"
        />
        <path d="M54 69.5L31.5 77L49 60.5L54 69.5Z" fill="#D7D7D7" />
        <path
          d="M129.5 41.5L127 31.5L102.5 36L106 46.5L129.5 41.5Z"
          fill="#04A608"
        />
        <path
          d="M76.5 57.5L71.5 47L103 36L106 46.5L76.5 57.5Z"
          fill="#D7D7D7"
        />
        <path d="M129.5 41.5L147 31.5H127L129.5 41.5Z" fill="#D7D7D7" />
        <path
          d="M53.5 69.5L49 60.5L71.5 47L76.5 57.5L53.5 69.5Z"
          fill="#04A608"
        />
        <path
          d="M83.5586 36.6229L77.9955 38.8705L112 123L119.5 127L83.5586 36.6229Z"
          fill="#090808"
        />
        <path
          d="M103 36C93.4425 11.5826 65.7689 3.99608 65.7689 3.99608C65.7689 3.99608 64.2054 32.2694 71.4193 47.0434L103 36Z"
          fill="white"
        />
        <path d="M66 3.5L65 4L64 1L66 3.5Z" stroke="black" />
      </g>
      <defs>
        <filter
          id="filter0_d_1022_5"
          x="109.324"
          y="182.071"
          width="53.9874"
          height="46.3271"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-5" dy="1" />
          <feGaussianBlur stdDeviation="3.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1022_5"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1022_5"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1022_5"
          x="147.227"
          y="240.534"
          width="50.1289"
          height="43.1289"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_1022_5"
          />
          <feOffset dx="-9" dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1022_5"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1022_5"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_1022_5"
          x="0.213257"
          y="0.687622"
          width="175.787"
          height="188.312"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="32" />
          <feGaussianBlur stdDeviation="15" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1022_5"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1022_5"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default PoolSide;
