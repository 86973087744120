import React from 'react';
import PropTypes from 'prop-types';
import { fieldToTextField } from 'formik-material-ui';
import { Switch } from '@material-ui/core';

const CustomSwitch = props => {
    return (
        <Switch
            {...fieldToTextField(props)}
        />
    );
};

CustomSwitch.propTypes ={
    field: PropTypes.shape({
        name: PropTypes.string
    }).isRequired,
    form: PropTypes.shape({
        setFieldValue: PropTypes.func,
        values: PropTypes.object
    }).isRequired
};

export default CustomSwitch;
