import { getHomeCashflowCalculations } from '../../utils/selectors/zillowThumbnailSelectors';
import { getCapRate } from '../../utils/calculations/rentalCalculations';

const getDefaultHomevalues = currHomeValues => {
  /* Home Values
   ******************************************************** */
  const {
    totalIncome,
    cashflow,
    yearlyCashflow,
    totalInvestment,
    totalCashonCash,
    rentEstimate,
    otherIncome,
    hoa,
    principalAndInterest,
    mortgageInsurance,
    homeInsurance,
    total,
    utilities,
    repairs,
    tax,
    vacancy,
    propertyManagement,
    capEx,
    mortgageTotal,
    additionalExpensesArr,
    totalExpensesAmount
  } = getHomeCashflowCalculations(currHomeValues);

  /* Cash On Cash Chart
   ******************************************************** */
  const defaultCashonCashChartData = {
    labels: ['Total Investment', 'Yearly Cashflow'],
    data: [totalInvestment, yearlyCashflow],
    table: [
      { type: 'Total Investment', value: totalInvestment, expense: true },
      { type: 'Yearly Cashflow', value: yearlyCashflow, expense: false }
    ],
    total: totalCashonCash
  };

  /* Cap Rate Chart
   ******************************************************** */
  const purchasePrice = currHomeValues.mortgageValues.homePrice;
  const capRateExpenses =
    (utilities +
      repairs +
      vacancy +
      propertyManagement +
      tax +
      homeInsurance +
      hoa) *
    12;
  const capRateIncome = totalIncome * 12;
  const { NOI, capRate } = getCapRate({
    totalExpensesAmount: capRateExpenses,
    income: capRateIncome,
    purchasePrice
  });

  const defaultCapRateChartData = {
    labels: ['Home Price', 'Expenses', 'NOI', 'Income'],
    data: [purchasePrice, capRateExpenses, NOI, capRateIncome],
    table: [
      {
        type: 'Home Price',
        value: currHomeValues.mortgageValues.homePrice,
        expense: false
      },
      { type: 'Annual Expenses', value: capRateExpenses, expense: true },
      { type: 'NOI', value: NOI, expense: true },
      { type: 'Annual Income', value: capRateIncome, expense: false }
    ],
    total: capRate
  };

  /* Expenses Chart
   ******************************************************** */
  const defaultExpensesChartData = {
    labels: [
      'Utilities',
      'Repairs',
      'Vacancy',
      'Property Management',
      'CapEx',
      ...additionalExpensesArr.map(({ label }) => `${label}`)
    ],
    data: [
      utilities,
      repairs,
      vacancy,
      propertyManagement,
      capEx,
      ...additionalExpensesArr.map(({ expense }) => expense)
    ],
    table: [
      ...additionalExpensesArr.map(({ label, expense }) => ({
        type: label,
        value: expense,
        expense: true
      })),
      { type: 'Utilities', value: utilities, expense: true },
      { type: 'Repairs', value: repairs, expense: true },
      { type: 'Vacancy', value: vacancy, expense: true },
      { type: 'Property Management', value: propertyManagement, expense: true },
      { type: 'CapEx', value: capEx, expense: true }
    ],
    total: totalExpensesAmount - mortgageTotal
  };

  /* Mortgage Chart
   ******************************************************** */
  const defaultMortgageChartData = {
    labels: [
      'Principal & Interest',
      'Property Taxes',
      'Home Insurance',
      'HOA',
      'Mortgage ins. & other'
    ],
    data: [principalAndInterest, tax, homeInsurance, hoa, mortgageInsurance],
    table: [
      {
        type: 'Principal & Interest',
        value: principalAndInterest,
        expense: true
      },
      { type: 'Property Taxes', value: tax, expense: true },
      { type: 'Home Insurance', value: homeInsurance, expense: true },
      { type: 'HOA', value: hoa, expense: true },
      { type: 'Mortgage ins. & other', value: mortgageInsurance, expense: true }
    ],
    total
  };

  /* Income Chart
   ******************************************************** */
  const defaultIncomeChartData = {
    labels: ['Rent estimate', ...otherIncome.map(({ label }) => `${label}`)],
    data: [rentEstimate, ...otherIncome.map(({ income }) => income)],
    table: [
      ...otherIncome.map(({ label, income }) => ({
        type: label,
        value: income,
        expense: false
      })),
      { type: 'Rent Estimate', value: rentEstimate, expense: false }
    ],
    total: totalIncome
  };

  /* Cashflow Chart
   ******************************************************** */
  const defaultCashflowChartData = {
    labels: ['Total Income', 'Total Expenses'],
    data: [totalIncome, totalExpensesAmount],
    table: [
      { type: 'Total Income', value: totalIncome, expense: false },
      { type: 'Total Expenses', value: totalExpensesAmount, expense: true }
    ],
    total: cashflow
  };

  return {
    defaultCashonCashChartData,
    defaultCapRateChartData,
    defaultExpensesChartData,
    defaultMortgageChartData,
    defaultIncomeChartData,
    defaultCashflowChartData
  };
};

export default getDefaultHomevalues;
