import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CheckIcon from '@material-ui/icons/Check';
import Payment from '../../Payment/Payment';
import { useAuth0 } from '../../../utils/react-auth0-spa';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    maxWidth: 340,
    textAlign: 'center',
    flexGrow: 1
  },
  header: {
    textAlign: 'center'
  },
  title: {
    display: 'inline',
    fontSize: '1.85rem',
    borderBottom: '2px solid',
    fontWeight: 'bold'
  },
  subheader: {
    color: '#000',
    fontSize: '1.85rem',
    fontWeight: 'bold',
    marginTop: '16px'
  },
  list: {
    padding: '20px'
  },
  checkIcon: {
    color: '#00C805',
    fontSize: '1rem',
    marginRight: '2px'
  }
}));

export const PricingCardDemo = React.memo(function PricingCard({
  title,
  subTitle,
  button,
  list,
  cssClass
}: {
  title: string;
  subTitle: string;
  button: {
    color?: ButtonProps['color'];
    variant?: ButtonProps['variant'];
    text?: string;
    payment?: boolean;
  };
  list: string[];
  cssClass?: string;
}) {
  const classes = useStyles();

  const { isAuthenticated, loginWithPopup }: any = useAuth0();

  return (
    <Card className={`${classes.root} ${cssClass}`}>
      <CardHeader
        title={title}
        className={classes.header}
        classes={{
          title: classes.title,
          subheader: classes.subheader
        }}
        subheader={subTitle}
      />
      <CardContent>
        {button.payment ? (
          <Payment fullWidth={false} />
        ) : isAuthenticated ? (
          <Typography variant="subtitle1" align="center">
            Thanks for joining!
          </Typography>
        ) : (
          <Button
            variant={button.variant}
            color={button.color}
            onClick={(): void => loginWithPopup({})}
          >
            {button.text}
          </Button>
        )}
        <div className={classes.list}>
          {list.map(item => (
            <Box
              display="flex"
              alignItems="center"
              key={item}
              marginBottom="4px"
            >
              <CheckIcon className={classes.checkIcon} />
              <Typography align="left">{item}</Typography>
            </Box>
          ))}
        </div>
      </CardContent>
    </Card>
  );
});

export default PricingCardDemo;
