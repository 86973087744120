import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import BoardCard from '../../BoardCard/BoardCard';

const useStyles = makeStyles(() => ({
  Wrapper: {
    borderRadius: 8,
    display: 'flex',
    backgroundColor: 'rgb(235, 236, 240);',
    flexDirection: 'column',
    padding: 8,
    border: 8,
    paddingBottom: 0,
    userSelect: 'none'
  },
  DropZone: {
    minHeight: 250,
    paddingBottom: 8
  },
  ScrollContainer: {
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: 250
  }
}));

const InnerQuoteList = React.memo((props: any) => {
  return props.quotes.map((quote: any, index: number) => (
    <Draggable key={quote.id} draggableId={quote.id} index={index}>
      {(dragProvided, dragSnapshot) => (
        <BoardCard
          key={quote.id}
          quote={quote}
          isDragging={dragSnapshot.isDragging}
          isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
          provided={dragProvided}
        />
      )}
    </Draggable>
  ));
});

const InnerList = (props: any) => {
  const classes = useStyles();
  const { quotes, dropProvided } = props;
  const title = props.title ? <h4>{props.title}</h4> : null;
  return (
    <div>
      {title}
      <div className={classes.DropZone} ref={dropProvided.innerRef}>
        <InnerQuoteList quotes={quotes} />
        {dropProvided.placeholder}
      </div>
    </div>
  );
};

const QuoteList = (props: any) => {
  const classes = useStyles();
  const {
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    isCombineEnabled,
    listId = 'LIST',
    listType,
    style,
    quotes,
    title,
    useClone
  } = props;

  return (
    <Droppable
      droppableId={listId}
      type={listType}
      ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={isDropDisabled}
      isCombineEnabled={isCombineEnabled}
      renderClone={
        useClone
          ? (provided, snapshot, descriptor) => (
              <BoardCard
                provided={provided}
                isDragging={snapshot.isDragging}
                isClone
              />
            )
          : undefined
      }
    >
      {(dropProvided, dropSnapshot) => (
        <div
          className={classes.Wrapper}
          style={style}
          data-isdraggingover={dropSnapshot.isDraggingOver}
          data-isdropdisabled={isDropDisabled}
          data-isdraggingfrom={Boolean(dropSnapshot.draggingFromThisWith)}
          {...dropProvided.droppableProps}
        >
          {internalScroll ? (
            <div
              className={classes.ScrollContainer}
              style={scrollContainerStyle}
            >
              <InnerList
                quotes={quotes}
                title={title}
                dropProvided={dropProvided}
              />
            </div>
          ) : (
            <InnerList
              quotes={quotes}
              title={title}
              dropProvided={dropProvided}
            />
          )}
        </div>
      )}
    </Droppable>
  );
};

export default QuoteList;
