import { createTheme } from '@material-ui/core/styles';

import variants from './variants';
import typography from './typography';
import overrides from './overrides';
import breakpoints from './breakpoints';
import props from './props';
import shadows from './shadows';

const theme = variant => {
  const { body, header, palette, sidebar, name } = variant;

  return createTheme(
    {
      spacing: 4,
      breakpoints,
      overrides,
      props,
      typography,
      shadows,
      body,
      header,
      palette,
      sidebar
    },
    name
  );
};

const themes = variants.map(variant => theme(variant));

export default themes;
