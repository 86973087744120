import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray } from 'formik';
import { TextField } from 'formik-material-ui';
import styled from 'styled-components';
import { Add, Close, CreditCard } from '@material-ui/icons';
import { Fab, Button, InputAdornment } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import InputSlider from '../common/InputSlider';
import AutoSave from '../../utils/Formik/AutoSave';
import i18n from '../../i18n';
import CustomTooltip from '../Tooltip';
import {
  UPDATE_PROPERTY_DATA,
  READ_PROPERTY,
  FETCH_USER_BOARDS_WITH_PROPERTY
} from '../../graphql/param_queries';

const IncomeFormSchema = Yup.object({
  rentEstimate: Yup.number()
    .typeError('rental income must be a valid positive number')
    .min(0, 'Values must be valid positive numbers')
    .required('rental income is a required field'),
  otherIncome: Yup.array().of(
    Yup.object().shape({
      label: Yup.string()
        .typeError('income type is a required field')
        .required('income type is a required field'),
      income: Yup.number()
        .min(0, 'Values must be valid positive numbers')
        .required('income amount is a required field')
    })
  )
});

const cashAdornment = {
  startAdornment: <InputAdornment position="start">$</InputAdornment>
};

const expenseAdornment = {
  startAdornment: (
    <InputAdornment position="start">
      <CreditCard />
    </InputAdornment>
  )
};

const generateKey = () => Math.floor(Math.random() * 90000) + 10000;

const FieldCont = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ButtonCont = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

const FlexCont = styled.div`
  display: flex;
  align-items: center;
`;

const IncomeMaterialUiForm = props => {
  const { homeValue, className } = props;
  const { homeData, id, boardId } = homeValue;
  const { income } = homeData;
  const { rentEstimate, otherIncome } = income;

  const { tooltip } = i18n.getDataByLanguage('en');

  const [currHomeValues, setCurrHomeValues] = useState(homeData);

  const [updatePropertyData] = useMutation(UPDATE_PROPERTY_DATA, {
    onCompleted: res => {
      const {
        update_property_by_pk: { data }
      } = res;
      const resHomeData = JSON.parse(data);
      setCurrHomeValues(resHomeData);
    },
    refetchQueries: [
      { query: READ_PROPERTY, variables: { id } },
      { query: FETCH_USER_BOARDS_WITH_PROPERTY, variables: { id: boardId } }
    ]
  });

  const handleOnAutoSave = values => {
    const { rentEstimate, otherIncome } = values;
    const updatedValues = {
      ...currHomeValues,
      income: {
        rentEstimate,
        otherIncome
      }
    };
    updatePropertyData({
      variables: {
        data: JSON.stringify(updatedValues),
        id
      }
    });
  };
  return (
    <Formik
      initialValues={{
        rentEstimate,
        otherIncome
      }}
      validationSchema={IncomeFormSchema}
    >
      {({ values }) => (
        <Form className={className}>
          <FieldCont>
            <Field
              name="rentEstimate"
              label="Rental Income"
              placeholder="Rental Income"
              tooltiptype="custom"
              tooltipcontent={tooltip.rentalIncome}
              type="number"
              step={10}
              inputProps={cashAdornment}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <FieldArray
              name="otherIncome"
              render={arrayHelpers => (
                <div>
                  {values.otherIncome && values.otherIncome.length > 0 ? (
                    values.otherIncome.map((otherIncome, index) => (
                      <FieldCont key={`${otherIncome.key}`}>
                        <FlexCont>
                          <Field
                            style={{
                              marginRight: '20px'
                            }}
                            name={`otherIncome.${index}.label`}
                            label="Income Type"
                            placeholder="add income type"
                            inputProps={expenseAdornment}
                            component={TextField}
                            variant="outlined"
                          />
                          <Field
                            name={`otherIncome.${index}.income`}
                            label="Amount"
                            type="number"
                            placeholder="add expense amount"
                            inputProps={cashAdornment}
                            component={TextField}
                            variant="outlined"
                          />
                        </FlexCont>
                        <ButtonCont>
                          <Fab
                            color="primary"
                            aria-label="add"
                            style={{
                              height: '25px',
                              width: '25px',
                              minHeight: '25px',
                              marginRight: '20px'
                            }}
                            onClick={() =>
                              arrayHelpers.push({
                                label: 'Income',
                                income: 0,
                                key: generateKey()
                              })
                            }
                          >
                            <Add
                              style={{
                                height: '15px',
                                width: '15px'
                              }}
                            />
                          </Fab>
                          <Fab
                            color="secondary"
                            aria-label="remove"
                            style={{
                              height: '25px',
                              width: '25px',
                              minHeight: '25px'
                            }}
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <Close
                              style={{
                                height: '15px',
                                width: '15px'
                              }}
                            />
                          </Fab>
                        </ButtonCont>
                      </FieldCont>
                    ))
                  ) : (
                    <div>
                      <Button
                        variant="outlined"
                        size="medium"
                        color="secondary"
                        onClick={() =>
                          arrayHelpers.push({
                            label: 'Income',
                            income: 0,
                            key: generateKey()
                          })
                        }
                      >
                        Add Additional Monthly Income{' '}
                        <CustomTooltip
                          tooltiptype="custom"
                          tooltipcontent={tooltip.additionalIncome}
                        />
                      </Button>
                    </div>
                  )}
                </div>
              )}
            />
          </FieldCont>
          <AutoSave onSave={handleOnAutoSave} values={values} />
        </Form>
      )}
    </Formik>
  );
};

IncomeMaterialUiForm.propTypes = {
  className: PropTypes.string,
  homeValue: PropTypes.any.isRequired
};

IncomeMaterialUiForm.defaultProps = {
  className: ''
};

export default IncomeMaterialUiForm;
