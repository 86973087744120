import {
  calculateExpenseValues,
  calculateIncome,
  calculateCashflow
} from '../calculations/rentalCalculations';

export const getHomeCashflowCalculations = currHomeValues => {
  const { zpid, income, cashOnCash, imageLink, streetAddress } = currHomeValues;

  const { closingCost } = cashOnCash;

  const {
    rentEstimate,
    otherIncome,
    hoa,
    principalAndInterest,
    mortgageInsurance,
    downPayment,
    homeInsurance,
    total,
    estimatedRepairCostToFixProperty,
    utilities,
    repairs,
    tax,
    vacancy,
    propertyManagement,
    capEx,
    mortgageTotal,
    additionalExpensesArr,
    totalExpensesAmount
  } = calculateExpenseValues(currHomeValues);

  const incomeArr = otherIncome.map(({ income }) => income);
  const totalIncome = calculateIncome(incomeArr, rentEstimate);

  const cashflow = Math.round(
    calculateCashflow(totalIncome, totalExpensesAmount)
  );
  const yearlyCashflow = Math.round(cashflow * 12);
  const totalInvestment = Math.round(
    downPayment + closingCost + estimatedRepairCostToFixProperty
  );
  const totalCashonCash = Number(
    ((yearlyCashflow / totalInvestment) * 100).toFixed(2)
  );

  return {
    zpid,
    incomeArr,
    totalIncome,
    cashflow,
    yearlyCashflow,
    totalInvestment,
    totalCashonCash,
    income,
    imageLink,
    streetAddress,
    rentEstimate,
    otherIncome,
    hoa,
    principalAndInterest,
    mortgageInsurance,
    downPayment,
    homeInsurance,
    total,
    estimatedRepairCostToFixProperty,
    utilities,
    repairs,
    tax,
    vacancy,
    propertyManagement,
    capEx,
    mortgageTotal,
    additionalExpensesArr,
    totalExpensesAmount
  };
};
