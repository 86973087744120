import React from 'react';
import QuoteList from './QuoteList';
import { Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import { isLeafType } from 'graphql';

const useStyles = makeStyles(() => ({
  Header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    fontSize: 18,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2
  },
  Dragging: {
    backgroundColor: 'red'
  },
  Container: {
    margin: 8,
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  }
}));

const Column = ({
  title,
  quotes,
  index,
  isScrollable,
  isCombineEnabled,
  useClone
}: any) => {
  const classes = useStyles();
  let style = {};
  const statuses = ['To do', 'Analyzed', 'Offer sent', 'Offer accepted'];
  if (title === statuses[0]) {
    style = {
      borderBottom: '2px solid #F2B33F',
      color: '#F2B33F',
      fontWeight: 800
    };
  } else if (title === statuses[1]) {
    style = {
      color: '#A25369',
      borderBottom: '2px solid #A25369',
      fontWeight: 800
    };
  } else if (title === statuses[2]) {
    style = {
      color: '#46638C',
      borderBottom: '2px solid #46638C',
      fontWeight: 800
    };
  } else {
    style = {
      color: '#5FAE89',
      borderBottom: '2px solid #5FAE89',
      fontWeight: 800
    };
  }
  return (
    <Draggable draggableId={title} index={index}>
      {(provided: any) => (
        <div
          className={classes.Container}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div
            {...provided.dragHandleProps}
            aria-label={`${title} property list`}
            className={classes.Header}
          >
            <h4 style={style}>{title}</h4>
          </div>
          <QuoteList
            listId={title}
            listType="PROPERTY"
            quotes={quotes}
            internalScroll={isScrollable}
            isCombineEnabled={Boolean(isCombineEnabled)}
            useClone={Boolean(useClone)}
          />
        </div>
      )}
    </Draggable>
  );
};

export default Column;
