import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import { Button, InputAdornment } from '@material-ui/core';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import InputSlider from '../common/InputSlider';
import { useAuth0 } from '../../utils/react-auth0-spa';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    textAlign: 'center',
    padding: '0 2em',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  linear: {
    width: '100%'
  },
  form: {
    width: '50%',
    height: '100%',
    overflow: 'auto',
    textAlign: 'center',
    padding: '20px'
  }
}));

const UpdateDefaultValuesMutation = gql`
  mutation update_defaultValues(
    $defaultValuesId: String!
    $capExPercent: Int
    $repairsPercent: Int
    $vacancyPercent: Int
    $propertyManagementPercent: Int
    $downPaymentPercentage: Int
    $interestRate: float8
    $loanLengthYears: Int
  ) {
    update_defaultValues(
      _set: {
        capExPercent: $capExPercent
        repairsPercent: $repairsPercent
        vacancyPercent: $vacancyPercent
        propertyManagementPercent: $propertyManagementPercent
        downPaymentPercentage: $downPaymentPercentage
        interestRate: $interestRate
        loanLengthYears: $loanLengthYears
      }
      where: { defaultValuesId: { _eq: $defaultValuesId } }
    ) {
      returning {
        capExPercent
        propertyManagementPercent
        repairsPercent
        vacancyPercent
        downPaymentPercentage
        interestRate
        loanLengthYears
      }
    }
  }
`;

const fetchGlobalValuesQuery = gql`
  query MyQuery($id: String_comparison_exp!) {
    defaultValues(where: { user: { id: $id } }) {
      capExPercent
      defaultValuesId
      downPaymentPercentage
      interestRate
      loanLengthYears
      propertyManagementPercent
      repairsPercent
    }
  }
`;

const percentAdornment = {
  startAdornment: <InputAdornment position="start">%</InputAdornment>
};

const FieldCont = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const DefaultValuesFormSchema = Yup.object({
  repairsPercent: Yup.number()
    .typeError('repairsPercent must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('repairsPercent is a required field'),
  vacancyPercent: Yup.number()
    .typeError('vacancyPercent must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('vacancyPercent is a required field'),
  propertyManagementPercent: Yup.number()
    .typeError('propertyManagementPercent must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('propertyManagementPercent is a required field'),
  capExPercent: Yup.number('capExPercent must be a number')
    .typeError('capExPercent must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('capExPercent is a required field'),
  loanLengthYears: Yup.number()
    .typeError('loanLength must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('loanLength is a required field'),
  downPaymentPercentage: Yup.number()
    .typeError('downPaymentPercentage must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('downPaymentPercentage is a required field'),
  interestRate: Yup.number()
    .typeError('interestRate must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('interestRate is a required field')
});

const DefaultValuesForm = () => {
  const classes = useStyles();
  const { user = { sub: '' } } = useAuth0();

  const [defaultValues, setDefaultValues] = useState({
    capExPercent: 0,
    downPaymentPercentage: 20,
    interestRate: 2.7,
    loanLengthYears: 30,
    propertyManagementPercent: 0,
    repairsPercent: 0,
    vacancyPercent: 0
  });

  const [fetchUserGlobalValues] = useLazyQuery(fetchGlobalValuesQuery, {
    variables: { id: { _eq: user.sub } },
    onCompleted: res => {
      const { defaultValues = [] } = res;
      const [globalValues] = defaultValues;
      setDefaultValues(globalValues);
    }
  });

  const [updateDefaultValues] = useMutation(UpdateDefaultValuesMutation, {
    onCompleted: res => {
      const {
        update_defaultValue: { returning = [] }
      } = res;
      const [updatedGlobalValues] = returning;
      setDefaultValues(updatedGlobalValues);
    }
  });

  React.useEffect(() => {
    if (user && user.sub) {
      fetchUserGlobalValues();
    }
    return;
  }, [user, fetchUserGlobalValues]);

  const {
    repairsPercent,
    vacancyPercent,
    propertyManagementPercent,
    capExPercent,
    downPaymentPercentage,
    interestRate,
    loanLengthYears
  } = defaultValues;

  return (
    <Formik
      initialValues={{
        repairsPercent,
        vacancyPercent,
        propertyManagementPercent,
        capExPercent,
        downPaymentPercentage,
        interestRate,
        loanLengthYears
      }}
      validationSchema={DefaultValuesFormSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const userDefaultValues = values;
        const {
          repairsPercent,
          vacancyPercent,
          propertyManagementPercent,
          capExPercent,
          downPaymentPercentage,
          interestRate,
          loanLengthYears
        } = userDefaultValues;
        updateDefaultValues({
          variables: {
            defaultValuesId: String(user.sub),
            repairsPercent,
            vacancyPercent,
            propertyManagementPercent,
            capExPercent,
            downPaymentPercentage,
            interestRate,
            loanLengthYears
          }
        });
        setSubmitting(false);
      }}
    >
      {({ submitForm, isValid }) => (
        <Form className={classes.form}>
          <FieldCont>
            <Field
              name="repairsPercent"
              label="repairs Percent"
              placeholder="repairs Percent"
              type="number"
              inputProps={percentAdornment}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="vacancyPercent"
              label="vacancy Percent"
              placeholder="vacancy Percent"
              type="number"
              inputProps={percentAdornment}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="propertyManagementPercent"
              label="property ManagementPercent"
              placeholder="property Management Percent"
              type="number"
              inputProps={percentAdornment}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="capExPercent"
              label="capEx Percent"
              placeholder="capEx Percent"
              type="number"
              inputProps={percentAdornment}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="downPaymentPercentage"
              label="Down Payment Percent"
              placeholder="DownPayment Percent"
              type="number"
              inputProps={percentAdornment}
              step={1}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="interestRate"
              label="Interest Rate"
              placeholder="Interest Rate"
              type="number"
              inputProps={percentAdornment}
              step={0.001}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="loanLengthYears"
              label="Loan length years"
              placeholder="Loan length years"
              type="number"
              step={1}
              component={InputSlider}
            />
          </FieldCont>
          <Button
            disabled={!isValid}
            onClick={submitForm}
            variant="contained"
            color="secondary"
          >
            Update Global Default Values
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default DefaultValuesForm;
