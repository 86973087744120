import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useLazyQuery, useMutation } from '@apollo/client';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import {
  FETCH_USER_BOARDS,
  DELETE_BOARD_BY_ID
} from '../graphql/param_queries';
import { useAuth0 } from '../utils/react-auth0-spa';

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardMedia: {
    paddingTop: '56.25%' // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  },
  loadingBar: {
    width: '100%',
    marginBottom: '10px'
  }
}));

export default function Album() {
  const classes = useStyles();
  const { user = { sub: '' } }: any = useAuth0();

  const [fetchUserBoards, { loading: loadingBoards, data }] = useLazyQuery(
    FETCH_USER_BOARDS,
    {
      variables: { id: user.sub }
    }
  );

  const [deleteBoard] = useMutation(DELETE_BOARD_BY_ID);

  React.useEffect(() => {
    if (user && user.sub) {
      fetchUserBoards();
    }
    return;
  }, [user, fetchUserBoards]);

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Saved Boards
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              paragraph
            >
              A collection of all the property boards you created with the
              chrome extension. Easily search and further analyze your
              properties.
            </Typography>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {loadingBoards && (
            <div className={classes.loadingBar}>
              <LinearProgress />
            </div>
          )}
          {/* End hero unit */}
          <Grid container spacing={4}>
            {data &&
            !loadingBoards &&
            data.users_by_pk &&
            data.users_by_pk.boards &&
            data.users_by_pk.boards.length &&
            data.users_by_pk.boards.length > 0 ? (
              data.users_by_pk.boards.map(({ id: boardId, name }: any): any => (
                <Grid item key={boardId} xs={12} sm={6} md={4}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      style={{
                        background: '#8aea8f'
                      }}
                      title={name}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {name}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Link
                        component={RouterLink}
                        to={`/boards/properties/${boardId}`}
                      >
                        View
                      </Link>
                      <IconButton
                        style={{
                          marginLeft: 'auto'
                        }}
                        aria-label="delete"
                        onClick={() => {
                          deleteBoard({
                            variables: {
                              boardId
                            },
                            refetchQueries: [
                              {
                                query: FETCH_USER_BOARDS,
                                variables: { id: user.sub }
                              }
                            ]
                          });
                        }}
                      >
                        <DeleteOutlinedIcon fontSize="small" />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 25,
                  width: '100%'
                }}
              >
                No boards found. Please add some via the chrome extension.
              </div>
            )}
          </Grid>
        </Container>
      </main>
    </React.Fragment>
  );
}
