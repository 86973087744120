import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Link, Box, Avatar } from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ZillowExample from '../public/example1.png';
import DataCard from '../public/dataCard.png';

const useStyles = makeStyles(() => ({
  wrapper: {
    minHeight: '100vh',
    padding: '40px',
    flexDirection: 'column',
    maxWidth: 750
  },
  h3: {
    fontSize: 32,
    fontWeight: 700,
    marginBottom: 8
  },
  h4: {
    fontSize: 21,
    fontWeight: 700,
    color: 'black',
    marginBottom: 8
  },
  subtitle: {
    fontSize: 22,
    fontWeight: 100,
    marginBottom: 8
  },
  stepcopy: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center'
  },
  videoLink: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    color: '#2F80ED',
    fontWeight: 900,
    fontSize: 18,
    marginBottom: 20
  },
  bulletNumber: {
    color: '#00C805',
    fontSize: 18,
    fontWeight: 800,
    marginRight: 10
  },
  stepText: {
    fontWeight: 400
  }
}));

function Pricing() {
  const classes = useStyles();

  return (
    <Grid container className={classes.wrapper}>
      <Typography className={classes.h3} display="block" variant="h3">
        How to install & use our chrome extension
      </Typography>
      <Typography
        className={classes.subtitle}
        display="block"
        variant="subtitle1"
      >
        Install the cashflow calculator to chrome if you want to analyze, edit
        and save properties.
      </Typography>
      <Link
        className={classes.videoLink}
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.youtube.com/watch?v=-EpOvMo6Ihk"
      >
        <PlayCircleOutlineIcon style={{ marginRight: 5 }} />
        See how to install
      </Link>
      <Typography className={classes.h4} display="block" variant="h4">
        Install on chrome
        <Box
          display="block"
          marginLeft={5}
          marginTop={3}
          fontWeight={400}
          fontSize={18}
        >
          <span className={classes.bulletNumber}>1.</span>
          Go to the
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://chrome.google.com/webstore/detail/zillow-cash-flow-calculat/caanploiiehcbbledgfjccgljbgdcdbf"
            style={{ color: '#2F80ED', margin: '0px 5px' }}
          >
            Chrome web store page
          </Link>
        </Box>
        <Box
          display="block"
          marginLeft={5}
          marginTop={3}
          fontWeight={400}
          fontSize={18}
        >
          <span className={classes.bulletNumber}>2.</span>
          Follow the prompts to complete installation.
        </Box>
      </Typography>
      <Typography
        className={classes.h4}
        style={{
          marginTop: 10
        }}
        display="block"
        variant="h4"
      >
        How to use the chrome extension
        <Box
          display="block"
          marginLeft={5}
          marginTop={3}
          fontWeight={400}
          fontSize={18}
        >
          <span className={classes.bulletNumber}>1.</span>
          Once installed visit
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.zillow.com/homes/San-Diego,-CA_rb/"
            style={{ color: '#2F80ED', margin: '0px 5px' }}
          >
            Zillow.com
          </Link>
          to browse homes for sale.
        </Box>
        <Box
          display="block"
          marginLeft={5}
          marginTop={3}
          fontWeight={400}
          fontSize={18}
        >
          <span className={classes.bulletNumber}>2.</span>
          You should see the following Cashflow button within the right side of
          the page as shown below.
        </Box>
        <Avatar
          style={{
            marginLeft: 5,
            height: 180,
            width: 270,
            marginTop: 20
          }}
          variant="square"
          alt="zillow example"
          src={ZillowExample}
        />
        <Box
          display="block"
          marginLeft={5}
          marginTop={3}
          fontWeight={400}
          fontSize={18}
        >
          <span className={classes.bulletNumber}>3.</span>
          Hover over the button, login and start calculating cashflow. The
          extension will append each of the listings with it's corresponding
          cashflow.
        </Box>
        <Avatar
          style={{
            marginLeft: 5,
            height: 215,
            width: 270,
            marginTop: 20
          }}
          variant="square"
          alt="data card example"
          src={DataCard}
        />
      </Typography>
      <Typography className={classes.h4} display="block" variant="h4">
        How to save properties
      </Typography>
      <Link
        className={classes.videoLink}
        target="_blank"
        rel="noopener noreferrer"
        href="https://youtu.be/ric5C2mR2EM?t=52"
      >
        <PlayCircleOutlineIcon style={{ marginRight: 5 }} />
        See how to save properties via the extension
      </Link>
    </Grid>
  );
}

export default Pricing;
