import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import { useAuth0 } from '../../utils/react-auth0-spa';
import Logo from '../../public/logo.png';
import MobileAppBar from './MobileAppBar/MobileAppBar';
import useStyles from './styles';
import { useLazyQuery } from '@apollo/client';
import { FETCH_USER } from '../../graphql/param_queries';
import { useGlobalContext } from '../../context/GlobalContext';

interface MuiStyling {
  title: string;
  textLink: string;
  logoFont: string;
}

interface LProps {
  styling: MuiStyling;
  link?: string;
  hash?: string;
  title: string;
  onClick?: () => void;
}

const CeLink: React.FC<LProps> = ({
  styling,
  link,
  hash,
  title,
  onClick = () => null
}: LProps): JSX.Element => (
  <Typography variant="h6" className={styling.title}>
    <Link
      className={styling.textLink}
      to={{
        pathname: link ?? '/',
        hash
      }}
      onClick={onClick}
    >
      <span className={styling.logoFont}>{title}</span>
    </Link>
  </Typography>
);

const AppNavBar = (): JSX.Element => {
  const classes = useStyles();
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user = {}
  }: any = useAuth0();
  const { updateUserContext }: any = useGlobalContext();
  const [fetchUser, { loading, data }] = useLazyQuery(FETCH_USER, {
    variables: { id: user.sub }
  });

  React.useEffect(() => {
    if (user && user.sub) {
      fetchUser();
    }
    return;
  }, [user, fetchUser]);

  React.useEffect(() => {
    if (!loading && data && data.users) {
      updateUserContext({
        user: data.users[0]
      });
    }
  }, [loading, data]);

  const navigateToPricing = () => {
    const el = document.getElementById('pricingSection');
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent">
        <Toolbar className={classes.toolbar}>
          <Grid container>
            <Grid container item sm={9}>
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                spacing={2}
                className={classes.noWrap}
              >
                <Grid item>
                  <Typography variant="h6" className={classes.title}>
                    <Link className={classes.textLink} to="/">
                      <img
                        className={`${classes.logo} ${classes.navLogo}`}
                        alt="CashFlowExpert main logo"
                        src={Logo}
                      />
                      <Hidden xsDown>
                        <span
                          className={classes.logoFont}
                          style={{ marginLeft: '1.5rem' }}
                        >
                          CashFlowExpert
                        </span>
                      </Hidden>
                    </Link>
                  </Typography>
                </Grid>
                <Grid className={classes.desktopLinkHide} item>
                  <CeLink
                    onClick={navigateToPricing}
                    styling={classes}
                    title="Pricing"
                    hash="#pricing"
                  />
                </Grid>
                <Grid className={classes.desktopLinkHide} item>
                  <CeLink
                    link="/howitworks"
                    styling={classes}
                    title="How it works"
                  />
                </Grid>
                {isAuthenticated && (
                  <Grid className={classes.desktopLinkHide} item>
                    <CeLink
                      styling={classes}
                      title="Dashboard"
                      link="/dashboard"
                    />
                  </Grid>
                )}
                {isAuthenticated && (
                  <Grid className={classes.desktopLinkHide} item>
                    <CeLink styling={classes} title="Boards" link="/boards" />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container item sm={3} justifyContent="flex-end">
              {!isAuthenticated && (
                <Typography
                  variant="h6"
                  className={`${classes.logButtons} ${classes.desktopLinkHide}`}
                  onClick={(): void => loginWithRedirect({})}
                >
                  <span className={classes.logoFont}>Log In</span>
                </Typography>
              )}
              {isAuthenticated && (
                <Typography
                  variant="h6"
                  className={`${classes.logButtons} ${classes.desktopLinkHide}`}
                  onClick={(): void =>
                    logout({
                      returnTo: window.location.origin
                    })
                  }
                >
                  <span className={classes.logoFont}>Logout</span>
                </Typography>
              )}
            </Grid>
          </Grid>
          <MobileAppBar />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default AppNavBar;
