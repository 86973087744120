import mortgageJs from 'mortgage-js';

const { calculatePayment } = mortgageJs;

export const getValueFromPercent = (percent, value) => value * (percent / 100);

export const calculateIncome = (additionalIncome = [], rentalIncome = 0) => {
  const additionalIncomeSum = additionalIncome.reduce(
    (acc, income) => acc + income,
    0
  );
  return rentalIncome + additionalIncomeSum;
};

export const calculateCashflow = (
  totalMontlyIncome = 0,
  totalMonthlyExpenses = 0
) => {
  const cashflow = totalMontlyIncome - totalMonthlyExpenses;
  return cashflow;
};

export const getTotalExpensesAmount = ({
  expenses,
  rentEstimate,
  mortgageTotal
}) => {
  const {
    utilities,
    repairsPercent,
    vacancyPercent,
    propertyManagementPercent,
    capExPercent,
    additionalExpensesArr
  } = expenses;

  // percentages
  const repairs = Math.round(getValueFromPercent(repairsPercent, rentEstimate));
  const vacancy = Math.round(getValueFromPercent(vacancyPercent, rentEstimate));
  const propertyManagement = Math.round(
    getValueFromPercent(propertyManagementPercent, rentEstimate)
  );
  const capEx = Math.round(getValueFromPercent(capExPercent, rentEstimate));

  // calculates other expenses and mortage expense
  const expensesArr = [
    utilities,
    repairs,
    vacancy,
    propertyManagement,
    capEx,
    mortgageTotal,
    ...additionalExpensesArr.map(({ expense }) => expense)
  ];
  const totalExpensesAmount = Math.round(
    expensesArr.reduce((acc, expense) => acc + expense, 0)
  );

  return {
    repairs,
    vacancy,
    propertyManagement,
    capEx,
    totalExpensesAmount
  };
};

export const getMortgageTotal = ({
  mortgageCalculatedVals,
  homeInsurance,
  hoa
}) => {
  return Math.round(mortgageCalculatedVals.total + homeInsurance + hoa);
};

export const getCapRate = ({ totalExpensesAmount, income, purchasePrice }) => {
  const NOI = income - totalExpensesAmount;
  const capRate = ((NOI / purchasePrice) * 100).toFixed(2);
  return {
    totalExpensesAmount,
    NOI,
    capRate
  };
};

export const calculateExpenseValues = response => {
  const { income, mortgageValues, expenses } = response;

  const { rentEstimate, otherIncome } = income;
  const {
    hoa,
    homePrice,
    downPayment,
    interestRate,
    loanLengthYears,
    taxRate,
    homeInsurance,
    estimatedRepairCostToFixProperty,
    mortgageInsuranceRate,
    mortgageInsuranceEnabled,
    mortgageInsuranceThreshold,
    additionalPrincipalPayment
  } = mortgageValues;

  const loanLengthMonths = loanLengthYears * 12;

  const mortgageCalculatedVals = calculatePayment(
    homePrice,
    downPayment,
    interestRate,
    loanLengthMonths,
    taxRate / 100,
    0,
    mortgageInsuranceRate / 100,
    mortgageInsuranceEnabled,
    mortgageInsuranceThreshold,
    additionalPrincipalPayment
  );

  const mortgageTotal = getMortgageTotal({
    mortgageCalculatedVals,
    homeInsurance,
    hoa
  });

  const totalExpenses = getTotalExpensesAmount({
    expenses,
    rentEstimate,
    mortgageTotal
  });

  const mortageUpdatedVals = {
    ...mortgageCalculatedVals,
    total: mortgageTotal
  };

  const { principalAndInterest, mortgageInsurance, tax } = mortageUpdatedVals;

  return {
    ...mortageUpdatedVals,
    estimatedRepairCostToFixProperty,
    principalAndInterest: Math.round(principalAndInterest),
    mortgageInsurance: Math.round(mortgageInsurance),
    tax: Math.round(tax),
    hoa,
    paymentSchedule: [],
    loanLengthYears,
    otherIncome,
    rentEstimate,
    downPayment,
    homeInsurance,
    mortgageTotal,
    ...expenses,
    ...totalExpenses
  };
};
