import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
        padding: 25
      },
      height: '350px',
      padding: 50,
      opacity: 0.98,
      display: 'flex',
      flexDirection: 'column',
      alignItem: 'center',
      justifyContent: 'center',
      alignItems: 'center'
    },
    sectionWrapper: {
      padding: '2em',
      height: '100%',
      [theme.breakpoints.up('md')]: {
        maxHeight: '650px'
      }
    },
    sectionWrapperWithVideo: {
      padding: '7em 2em'
    },
    titleIcon: {
      maxWidth: '50px'
    },
    section: {
      height: '100%',
      width: '100%',
      display: 'flex',
      marginBottom: '2em',
      [theme.breakpoints.up('md')]: {
        maxHeight: '650px'
      }
    },
    primaryButton: {
      width: '277px',
      height: '63px',
      fontSize: '20px',
      fontWeight: 400
    },
    figure: {
      textAlign: 'center',
      width: '100%',
      flexBasis: '100%',
      maxWidth: '650px'
    },
    extenstionfigure: {
      width: '100%',
      flexBasis: '100%',
      maxWidth: '450px'
    },
    mainHeading: {
      marginBottom: '.5em'
    },
    multiSectionTitle: {
      fontWeight: 700
    },
    videoWrapper: {
      boxShadow: '5px 8px 10px rgba(0,0,0,.4)'
    },
    colItem: {
      maxWidth: '550px',
      alignItems: 'center',
      height: '100%',
      [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start'
      }
    },
    colCopy: {
      marginBottom: '2em'
    },
    itemVideo: {
      position: 'relative',
      minWidth: '100%',
      paddingBottom: '1em',
      cursor: 'pointer',
      color: 'rgba(0,0,0,.5)',
      [theme.breakpoints.up('md')]: {
        minWidth: '50%'
      },
      '&:last-child': {
        paddingBottom: '0'
      }
    },
    selected: {
      color: '#212F5D'
    },
    image: {
      width: '100%',
      height: '100%'
    },
    paymentButton: {
      width: '277px',
      height: '63px',
      '& button': {
        width: '277px',
        height: '63px',
        fontSize: '20px',
        fontWeight: 400
      }
    },
    styledSvg: {
      '& svg ': {
        maxHeight: '550px',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
          maxHeight: '200px'
        }
      }
    }
  })
);

export default useStyles;
