import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import { useHistory, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import CountUp from 'react-countup';
import { useMutation } from '@apollo/client';
import { renderTrendLine } from '../PropertyTable/helpers';
import {
  FETCH_USER_BOARDS_WITH_PROPERTY,
  DELETE_PROPERTY_BY_ID
} from '../../graphql/param_queries';

export interface BoardCardProps {
  address?: string;
  price?: number;
  cashflow?: number;
  cashoncash?: number;
  quote?: any;
  isDragging?: any;
  isGroupedOver?: any;
  provided?: any;
  style?: any;
  isClone?: any;
  index?: any;
}

function getStyle(provided: any, style: any) {
  if (!style) {
    return provided.draggableProps.style;
  }
  return {
    ...provided.draggableProps.style,
    ...style
  };
}

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 282,
    margin: '0 0 4px 0',
    borderRadius: 8
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto',
    padding: '8px',
    '&:last-child': {
      paddingBottom: 0
    }
  },
  icons: {
    color: '#7F99B1'
  },
  cover: {
    width: 80,
    height: 60,
    borderRadius: 4,
    marginRight: 8
  },
  divider: {
    marginTop: '4px',
    backgroundColor: '#f5f5f5'
  },
  cardActions: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

export default function BoardCard(props: BoardCardProps) {
  const {
    quote,
    isDragging,
    isGroupedOver,
    provided,
    style,
    isClone,
    index
  }: any = props;

  const { id: boardId }: any = useParams();

  const [deleteProperty] = useMutation(DELETE_PROPERTY_BY_ID);

  const handleDelete = () => {
    deleteProperty({
      variables: {
        id: quote.id
      },
      refetchQueries: [
        { query: FETCH_USER_BOARDS_WITH_PROPERTY, variables: { id: boardId } }
      ]
    });
  };

  const classes = useStyles();

  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <Card
      variant="outlined"
      className={classes.root}
      data-isgroupedover={isGroupedOver}
      data-isclone={isClone}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle(provided, style)}
      data-is-dragging={isDragging}
      data-testid={quote.id}
      data-index={index}
      aria-label={`${quote?.status} address ${quote.address}`}
    >
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Box display="flex" flexDirection="column">
            <Box display="flex">
              <div>
                <CardMedia
                  className={classes.cover}
                  image={quote.image}
                  title="Home image"
                />
              </div>
              <Box flex="1">
                <Box marginBottom={3}>
                  <Typography align="right" variant="body2">
                    {quote.address}
                  </Typography>
                  <Divider variant={'fullWidth'} className={classes.divider} />
                </Box>
                <Box marginBottom={3}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body2">Price</Typography>
                    <Typography variant="body2" component="div">
                      <CountUp
                        start={quote.price}
                        end={quote.price}
                        delay={0}
                        duration={0}
                        decimals={0}
                        separator=","
                        prefix="$"
                      >
                        {({ countUpRef }) => (
                          <div
                            style={{
                              fontSize: 16
                            }}
                          >
                            <span ref={countUpRef} />
                          </div>
                        )}
                      </CountUp>
                    </Typography>
                  </Box>
                  <Divider variant={'fullWidth'} className={classes.divider} />
                </Box>
              </Box>
            </Box>
            <Box flex="1">
              <Box marginBottom={3}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2">Cashflow</Typography>
                  <Typography variant="body2" component="div">
                    <CountUp
                      start={quote.cashflow}
                      end={quote.cashflow}
                      delay={0}
                      duration={0}
                      separator=","
                      decimals={0}
                      prefix="$"
                    >
                      {({ countUpRef }) => {
                        const { IconComponent, color } = renderTrendLine(
                          quote.cashflow
                        );
                        return (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontSize: 16,
                              color
                            }}
                          >
                            <IconComponent
                              style={{
                                margin: '0 10px'
                              }}
                            />
                            <span ref={countUpRef} />
                          </div>
                        );
                      }}
                    </CountUp>
                  </Typography>
                </Box>
                <Divider variant={'fullWidth'} className={classes.divider} />
              </Box>
              <Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2">Cash on cash</Typography>
                  <Typography variant="body2" component="div">
                    <CountUp
                      start={quote.coc}
                      end={quote.coc}
                      delay={0}
                      duration={1}
                      decimals={2}
                      suffix="%"
                    >
                      {({ countUpRef }) => (
                        <div
                          style={{
                            fontSize: 16
                          }}
                        >
                          <span ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                  </Typography>
                </Box>
                <Divider variant={'fullWidth'} className={classes.divider} />
              </Box>
            </Box>
            <CardActions disableSpacing className={classes.cardActions}>
              <IconButton
                className={classes.icons}
                onClick={() => {
                  history.push(`${pathname}/property/${quote.id}`);
                }}
                color="primary"
                aria-label="edit"
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                className={classes.icons}
                aria-label="delete"
                onClick={handleDelete}
              >
                <DeleteOutlinedIcon fontSize="small" />
              </IconButton>
            </CardActions>
          </Box>
        </CardContent>
      </div>
    </Card>
  );
}
