import React from 'react';
import TwoColSection from '../components/TwoColSection/TwoColSection';
import ActionBoardImg from '../public/ActionBoardImg.png';

const ProfitSection = (): JSX.Element => {
  return (
    <div style={{ backgroundColor: '#ededed' }}>
      <TwoColSection
        eyebrow="06"
        title="Keep yourself accountable with the Action Board."
        image={{
          src: ActionBoardImg,
          alt: 'calculated listings',
          style: {
            height: 'auto',
            maxWidth: '600px'
          }
        }}
        bodyContent="Never miss a beat. Keep a record of all your sent, received and accepted offers. Drag and drop properties to their correct columns and keep yourself organized."
        textLeft={true}
        showDemo={false}
      />
    </div>
  );
};

export default ProfitSection;
