import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%',
        width: '100%',
        overflow: 'auto',
        position: 'relative'
    },
    tabs: {
        borderRight: `2px solid ${theme.palette.divider}`,
        '& .MuiTabs-indicator': {
            borderRight: '3px solid #00c807'
        },
        '& .MuiTabs-flexPaper': {
            height: '100%'
        },
        '& .MuiTabs-flexContainer': {
            height: '100%'
        }
    },
    flexCont: {
        display: 'flex',
        height: '100%'
    },
    chartContainer: {
        margin: '0 auto',
        width: '100%',
        maxWidth: 500,
        padding: 20
    },
    form: {
        width: '50%',
        height: '100%',
        overflow: 'auto',
        padding: '25px 20px'
    },
    dashboard: {
        width: '100%',
        padding: '25px',
        height: '100%',
        overflow: 'auto',
        display: 'grid',
        justifyContent: 'center',
        gridTemplateColumns: '500px 500px',
        background: '#e1e6ec'
    },
    charts: {
        position: 'relative',
        marginBottom: 'auto',
        height: '100%',
        width: '50%',
        backgroundColor: '#e5ecee'
    },
    chartsPanel: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    tabpanel: {
        width: '100%',
        height: '100%'
    },
    avatarTab: {
        marginTop: 'auto',
        opacity: 1
    },
    icon: {
        height: '35px',
        width: '35px'
    },
    avatar: {
        height: '55px',
        width: '55px'
    },
    box: {
        height: '100%',
        width: '100%',
        padding: 0
    },
    absoluteBtn: {
        position: 'absolute',
        top: '1em',
        right: '1em',
        zIndex: '1'
    },
    defaultValuesCont: {
        height: '100%',
        padding: 50,
        backgroundColor: '#e5ecee'
    },
    footnote: {
        padding: 15,
        fontSize: 12,
        '.tooltip-label': {
            cursor: 'pointer'
        }
    },
    demo: {
        backgroundColor: theme.palette.background.paper
    },
    title: {
        margin: theme.spacing(4, 0, 2)
    }

}));
