import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iframeWrapper: {
      maxHeight: '345px',
      [theme.breakpoints.up('md')]: {
        width: '546px',
        height: '345px'
      }
    }
  })
);

interface ModalProps {
  buttonText: string;
  header?: string;
  content?: string;
  youtubeId?: string;
  className?: string;
}

const ResponsiveModal: React.FC<ModalProps> = ({
  buttonText,
  header,
  content,
  youtubeId,
  className
}: ModalProps): any => {
  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = React.useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
    setUrl(`https://www.youtube.com/embed/${youtubeId}`);
  };

  const handleClose = () => {
    setOpen(false);
    setUrl('');
  };

  return (
    <div>
      <Button
        className={className}
        variant="contained"
        color="secondary"
        onClick={handleClickOpen}
      >
        {buttonText}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {header && (
          <DialogTitle id="responsive-dialog-title">{header}</DialogTitle>
        )}
        <DialogContent className={classes.iframeWrapper}>
          {content && <DialogContentText> {content} </DialogContentText>}
          {youtubeId && (
            <iframe
              width="100%"
              height="100%"
              title="CashFlowExpert Demo"
              src={`${url}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ResponsiveModal;
