import * as React from 'react';
import StripeCheckout from 'react-stripe-checkout';
import {
  Button,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { useAuth0 } from '../../utils/react-auth0-spa';
import { useAuthToken } from '../../hooks/useAuthToken';
import { useGlobalContext } from '../../context/GlobalContext';

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: '#1976d2',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const Payment = (props: any) => {
  const classes = useStyles();
  const {
    loading,
    isAuthenticated,
    user = {},
    loginWithPopup
  }: any = useAuth0();
  const { userContext, updateUserContext }: any = useGlobalContext();
  const [apiIsLoading, setApiIsLoading]: any = React.useState<boolean>(false);
  const authToken = useAuthToken();
  React.useEffect(() => {
    return;
  }, [userContext]);
  const subscribeToProductPlan = async (token: {
    id: string;
    email: string;
  }) => {
    if (!isAuthenticated || !user) {
      return;
    }
    const bodyParams = {
      stripeToken: token.id,
      email: token.email,
      // eslint-disable-next-line no-undef
      productPlan: process.env.REACT_APP_STRIPE_PRODUCT_ID,
      userId: user.sub,
      authToken
    };

    try {
      setApiIsLoading(true);
      const response = await fetch(
        '/.netlify/functions/createCustomerAndSubscribeToPlan',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyParams)
        }
      );
      const customer = await response.json();
      if (customer.error) {
        throw Error(customer.error);
      }
      updateUserContext({ user: customer.user });
      setApiIsLoading(false);
      return customer;
    } catch (error) {
      setApiIsLoading(false);
      alert(error);
    }
  };

  if (loading) {
    return <Skeleton height={52} />;
  }

  if (!isAuthenticated) {
    return (
      <Button
        fullWidth={props.fullWidth}
        variant="contained"
        color="secondary"
        onClick={(): void => loginWithPopup({})}
      >
        Sign Up - $10 monthly
      </Button>
    );
  }

  if (isAuthenticated && userContext && userContext.user.type !== 'paid') {
    return (
      <StripeCheckout
        token={subscribeToProductPlan}
        // eslint-disable-next-line no-undef
        stripeKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY + ''}
        name="CashFlowExpert"
        description="Unlimited access for $10/month."
        panelLabel="Start membership"
        ComponentClass="div"
        email={user.email}
      >
        <Button
          fullWidth={props.fullWidth}
          variant="contained"
          color="secondary"
          disabled={apiIsLoading}
        >
          {userContext && userContext.user.membershipEndDate
            ? 'Reactivate membership'
            : 'Start membership'}
        </Button>
        {apiIsLoading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </StripeCheckout>
    );
  }

  if (isAuthenticated && userContext && userContext.user.type === 'paid') {
    return (
      <Typography variant="subtitle1" align="center">
        Thanks for joining!
      </Typography>
    );
  }

  // fall back
  return <div></div>;
};

Payment.defaultProps = {
  fullWidth: true
};

export default Payment;
