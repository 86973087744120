import React from 'react';
import { motion } from 'framer-motion';
import Hero from '../components/Hero/Hero';
import PoolHowVideo from '../components/PoolVideo/PoolHowVideo';
import StepOne from '../sections/StepOne';
import StepTwo from '../sections/StepTwo';
import StepThree from '../sections/StepThree';
import StepFour from '../sections/StepFour';
import StepFive from '../sections/StepFive';
import StepSix from '../sections/StepSix';
import PricingSection from '../sections/PricingSection';
import Footer from '../components/Footer/Footer';

const LandingPage: React.FC<{}> = (): React.ReactElement => (
  <motion.div
    exit={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    initial={{ opacity: 0 }}
  >
    <Hero />
    <PoolHowVideo />
    <StepOne />
    <StepTwo />
    <StepThree />
    <StepFour />
    <StepFive />
    <StepSix />
    <PricingSection />
    <Footer />
  </motion.div>
);

export default LandingPage;
