import * as React from 'react';

function SvgComponent(props: any) {
  return (
    <svg
      width={444}
      height={496}
      viewBox="0 0 444 496"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M398.017 490.133h-11.996l4.172-14.033 4.173 7.277 3.651-2.599v9.355z"
        fill="#F0EEEE"
        stroke="#000"
        strokeWidth={0.5}
      />
      <path
        d="M289.968 46.23h-121.32c-3.284-12.548-2.264-17.595 12.204-17.802 2.533-6.026 8.465-7.176 14.118-4.145 5.22-12.485 6.882-15.86 22.493-12.681 9.901-14.762 18.589-14.62 35.176 3.658 6.18-.537 7.722 2.464 6.7 7.316 11.585-9.136 14.277-7.298 16.032 7.072 20.118-5.026 22.773.015 14.597 16.582z"
        fill="#fff"
        stroke="#EBEBEB"
      />
      <path
        d="M416.089 199.46H304.475c-3.021-12.549-2.083-17.595 11.228-17.802 2.33-6.026 7.787-7.177 12.989-4.146 4.801-12.484 6.331-15.86 20.693-12.681 15.424-13.189 22.881-13.181 32.362 3.658 5.686-.537 7.104 2.464 6.164 7.316 8.349-2.79 10.75-.728 14.75 7.072 14.736-.605 18.004 3.469 13.428 16.583z"
        fill="#fff"
        stroke="#DFDFDF"
      />
      <path
        d="M152.951 135.311H27.749c-3.39-12.548-2.337-17.595 12.594-17.802 2.614-6.026 8.735-7.177 14.57-4.146 5.386-12.484 7.102-15.86 23.213-12.68 17.301-13.19 25.666-13.182 36.301 3.658 6.378-.538 7.969 2.463 6.914 7.315 9.366-2.79 12.06-.728 16.546 7.072 16.53-.605 20.196 3.469 15.064 16.583z"
        fill="#fff"
        stroke="#E1E1E1"
      />
      <path d="M133.514 381.924v-163.42" stroke="#A9A8A8" />
      <path fill="#E0E0E0" d="M175.097 226.522h-40.985v6.014h40.985z" />
      <path
        d="M371.026 236.7l-50.981-31.226v191.443h50.981V236.7z"
        fill="#F8F8F8"
      />
      <path
        d="M320.045 207.478H134.112v190.441h185.933V207.478zm-93.082-92.213l94.081 92.213H134.112l92.851-92.213z"
        fill="#fff"
      />
      <path
        d="M285.012 141.084l89.013 90.45-46.738-26.68-101.209-97.608 58.934 33.838z"
        fill="#F6F6F6"
        stroke="#C3C2C2"
      />
      <path
        d="M166.1 292.676v88.204m-30.989 17.039l184.934-1.002"
        stroke="#A9A8A8"
      />
      <path
        fill="#E0E0E0"
        d="M257.067 349.808h-38.986v5.012h38.986zm-7.997 16.037h-38.986v5.012h38.986zm-25.99-107.248h-38.986v5.012h38.986zm-29.99 61.141h-38.986v5.012h38.986z"
      />
      <path
        fill="#EEE"
        d="M320.045 298.69h-39.986v6.014h39.986zm-14.995 62.143h-38.986v6.014h38.986zm14.995-104.241h-38.986v5.012h38.986z"
      />
      <path fill="#E7E7E7" d="M281.059 221.511h-39.986v5.012h39.986z" />
      <path
        fill="#EEE"
        d="M299.052 268.62h-38.986v5.012h38.986zm20.993 112.26H279.06v6.014h40.985zm-12.996-56.13h-40.985v6.014h40.985z"
      />
      <path fill="#E0E0E0" d="M266.064 309.715h-40.985v6.014h40.985z" />
      <path
        d="M355.032 299.191l-24.491-5.012v5.012l24.491 5.011v-5.011zm6.498 33.577l-24.491-5.011v5.011l24.491 5.012v-5.012zm-5.998 18.042l-24.491-4.009v5.012l24.491 4.009v-5.012zm-10.996-38.088l-24.491-5.012v5.012l24.491 5.012v-5.012zm-.5-72.167l-14.994-7.016v5.011l14.994 6.515v-4.51z"
        fill="#EEE"
      />
      <path
        d="M342.037 235.543l-21.992-10.023v5.012l21.992 9.522v-4.511zm28.989 11.527l-14.994-5.513v5.513l14.994 5.513v-5.513zm-.5 59.638l-24.491-5.011v5.011l24.491 5.012v-5.012zm1 64.149l-24.491-5.012v5.012l24.491 5.011v-5.011zm-1-21.049l-24.491-5.012v5.012l24.491 5.012v-5.012zm-25.99 23.053l-24.491-4.009v5.012l24.491 4.009v-5.012z"
        fill="#EEE"
      />
      <path
        fill="#EEE"
        d="M320.045 226.522H279.06v6.014h40.985zm-72.974-73.169h-40.985v6.014h40.985z"
      />
      <path fill="#E0E0E0" d="M227.078 177.409h-40.985v6.014h40.985z" />
      <path fill="#EEE" d="M251.07 193.446h-40.985v6.014h40.985z" />
      <path
        fill="#E0E0E0"
        d="M225.079 199.46h-40.985v6.014h40.985zm8.997 34.079h-40.985v6.014h40.985z"
      />
      <path fill="#EEE" d="M229.077 150.346h-40.985v6.014h40.985z" />
      <path
        fill="#E0E0E0"
        d="M176.097 374.866h-40.985v6.014h40.985zm0-39.091h-40.985v6.014h40.985zm33.987-59.137h-40.985v6.014h40.985zm-33.987-15.034h-40.985v6.014h40.985zm39.985 52.12h-40.985v6.014h40.985z"
      />
      <path
        fill="#EEE"
        d="M287.057 202.467h-40.985v6.014h40.985zm10.996-28.065h-40.985v6.014h40.985z"
      />
      <path fill="#E0E0E0" d="M182.094 272.629h-40.985v5.012h40.985z" />
      <path fill="#E1E1E1" d="M275.061 334.773h-40.985v5.012h40.985z" />
      <path fill="#EEE" d="M320.045 340.787h-39.986v6.014h39.986z" />
      <path fill="#E0E0E0" d="M281.059 386.894h-40.985v5.012h40.985z" />
      <path fill="#EEE" d="M282.058 263.608h-40.985v5.012h40.985z" />
      <path
        d="M331.041 247.571l29.989 10.313v42.309l-14.995-3.258-14.994-3.257v-46.107z"
        fill="#fff"
        stroke="#5F5F5F"
        strokeOpacity={0.6}
      />
      <path
        d="M259.067 290.671h30.988v39.091h-30.988v-39.091z"
        fill="#F8F8F8"
        stroke="#807F7F"
      />
      <path d="M274.561 294.68v32.576m-6.498-20.047h12.996" stroke="#858585" />
      <path
        d="M259.067 224.518h30.988v39.09h-30.988v-39.09z"
        fill="#F8F8F8"
        stroke="#807F7F"
      />
      <path d="M274.561 228.527v32.575m-6.498-20.046h12.996" stroke="#858585" />
      <path
        d="M320.544 206.977l49.982 29.569v5.011l-49.982-25.559v-9.021z"
        fill="#EEE"
      />
      <path
        d="M371.023 236.546l3.002-5.957-46.697-27.332-101.399-96.011-97.815 96.975 4.303 5.818 93.512-93.095 99.398 94.022 45.696 25.58z"
        fill="#FAFAFA"
        stroke="#A9A8A8"
      />
      <path
        d="M320.045 205.975v190.942m50.981-160.371v159.87"
        stroke="#A9A8A8"
      />
      <path
        d="M284.058 396.917h97.964c20.564-6.71 20.437-14.661 14.995-20.321 1.334-13.581-11.496-3.159-10.496-5.452.999-2.292-7.332-12.243-11.996 0-3.175-7.486-3.625-12.182-8.497-7.434-7.473-9.089-16.852-3.05-16.494 10.408-5.449-15.925-5.461-22.489-16.994-6.268-.614-8.578-4.691-2.157-5.844-3.149-1.153-.991-12.048-7.603-12.567 6.443-7.743-11.953-13.013-14.628-14.077-6.802-17.714-20.016-12.481-5.972-10.609 12.254l-5.385 4.957v15.364z"
        fill="#FFFEFE"
        stroke="#A9A8A8"
      />
      <path
        d="M305.55 361.335c3.998 4.009 5.507 5.624 7.497 9.021l-10.496 29.067s-4.064-26.458-5.998-29.569c-1.934-3.111 4.998-12.529 8.997-8.519z"
        fill="#F8F8F8"
      />
      <path
        d="M347.035 259.599l-.5 33.077m-6.497-22.553l13.495 4.01"
        stroke="#9C9C9C"
      />
      <path
        d="M363.529 293.678l-34.987-8.019v9.021l34.987 7.518v-8.52z"
        fill="#F9F9F9"
        stroke="#9C9C9C"
      />
      <path
        d="M329.541 285.158l32.988 7.518c1.386-1.307 2.01-2.046 0-3.509 1.482-2.226 1.476-3.181-2.499-3.508-1.085-.979-1.997-.66-3.998 1.003.57-2.867.353-3.818-2.5-2.506-2.299-1.612-3.433-1.802-4.998 0 1.127-2.875-.826-2.354-2.999-2.506-2.027-.96-2.999.501-2.999 1.504 0 1.002-.596-2.135-2.999-2.005-1.365-1.375-2.133-1.332-3.498 0-.119-2.112-.157-3.325-3.499-2.005-1.194-1.091-1.807-1.207-2.499 2.005-1.801.04-1.834.989-.5 4.009z"
        fill="#FDFDFD"
        stroke="#9C9C9C"
      />
      <path d="M352.033 257.594l6.998 2.506v5.012" stroke="#9C9C9C" />
      <path
        d="M189.092 107.246h15.994v20.548l-15.994 15.536v-36.084z"
        fill="#FEFEFE"
        stroke="#A9A8A8"
      />
      <path
        d="M338.538 284.104c-1.5-1.504-1.432-2.02-2.999-.501-1.802-1.811-2.374-3.293-3.499 0"
        stroke="#9C9C9C"
      />
      <path
        d="M302.551 368.351c2.447-2.078 5.219 1.002 11.496 10.524 2.251-7.626 4.567-8.018 7.497-4.51"
        stroke="#A9A8A8"
      />
      <path fill="#EAEAEA" d="M7.157 227.525h231.916v224.52H7.157z" />
      <path
        d="M301.963 261.256l-63.889-36.738v227.527h65.977l-2.088-190.789z"
        fill="#E8E8E8"
      />
      <path
        d="M121.965 121.279L3.159 228.527h235.915L121.965 121.279z"
        fill="#EAEAEA"
      />
      <path
        d="M218.664 171.608l90.385 89.996-57.896-32.231-127.038-115.111 94.549 57.346z"
        fill="#EBEBEB"
        stroke="#000"
      />
      <path
        d="M204.086 174.507l20.993 19.941v-63.146h-20.993v43.205z"
        fill="#E2E0E0"
        stroke="#000"
      />
      <path
        d="M236.075 140.15l-10.996-8.848v63.641l10.996 2.512V140.15z"
        fill="#CECECE"
        stroke="#000"
      />
      <path
        transform="matrix(-.00903 -.99996 -.99996 .00907 303.49 451.843)"
        stroke="#000"
        d="M0-.5h185.582"
      />
      <path
        d="M262.632 354.041l.834-48.484 21.258 6.688v46.393l-22.092-4.597z"
        fill="#fff"
        stroke="#000"
        strokeOpacity={0.58}
      />
      <path
        d="M274.303 320.604l-.417 27.585m-5.418-18.808l11.254 3.344"
        stroke="#000"
        strokeOpacity={0.58}
      />
      <path
        d="M240.073 230.532l5.998 5.851v215.662h-5.998V230.532z"
        fill="#D8D8D8"
      />
      <path fill="#CCC" d="M10.859 351.533H35.87v4.18H10.859z" />
      <path fill="#fff" d="M28.473 319.743h51.899v48.401H28.473z" />
      <path
        d="M38.371 320.604H28.367l35.796 55.359 14.862-1.514-40.654-53.845z"
        fill="#F2F2F2"
        fillOpacity={0.45}
      />
      <path
        d="M69.217 320.594l-9.596.004 20.428 31.727.015-31.737-10.847.006z"
        fill="#F2F2F2"
        fillOpacity={0.7}
      />
      <path stroke="#0E0D0D" d="M54.257 319.743v48.401m-25.784-23.031h51.899" />
      <path fill="#CCC" d="M23.364 369.087h60.859v7.523H23.364z" />
      <path
        d="M82.14 368.669H25.031s-2.82-3.25-1.153-7.012c1.668-3.761 2.82-1.671 2.82-1.671 2.404-.512 5.089-1.883 6.253 3.761.925-4.227-.98-5.699 3.335-3.761 3.115-5.915 7.92-3.762 7.92 0 5.398-1.152 6.47-.568 6.252 4.504 2.317-2.894 6.984-6.56 10.102-3.669 3.1-.817 5.064-.251 6.155 2.926-.05-2.671 2.095-4.229 4.586-5.945 4.16-1.964 3.295 1.958 5.418 4.598 7.453 1.874 6.579 1.725 5.42 6.269z"
        fill="#E2E0E0"
        stroke="#000"
      />
      <path
        fill="#DBDBDB"
        d="M77.132 294.68h36.987v4.009H77.132zm125.955-15.035h35.987v4.009h-35.987zm-6.998 4.01h36.987v4.009h-36.987z"
      />
      <path
        fill="#C4C4C4"
        d="M159.103 302.699h36.987v4.009h-36.987zm-118.958 2.004h36.987v4.009H40.145z"
      />
      <path fill="#D7D7D7" d="M134.112 286.662h36.987v4.009h-36.987z" />
      <path fill="#E2E2E2" d="M172.098 431.998h35.987v4.009h-35.987z" />
      <path fill="#C4C4C4" d="M16.154 420.973h35.987v4.009H16.154z" />
      <path fill="#CCC" d="M199.088 375.868h36.987v4.009h-36.987z" />
      <path fill="#E0E0E0" d="M22.152 269.622h35.987v4.009H22.152z" />
      <path fill="#D2D2D2" d="M29.149 205.474h36.987v4.009H29.149z" />
      <path fill="#CCC" d="M120.117 274.634h24.991v4.009h-24.991z" />
      <path fill="#E4E4E4" d="M152.105 261.604h39.986v4.009h-39.986z" />
      <path fill="#D0D0D0" d="M183.094 249.576h35.987v4.009h-35.987z" />
      <path fill="#E3E3E3" d="M157.104 237.548h25.991v4.009h-25.991z" />
      <path
        fill="#E1E1E1"
        d="M144.108 392.908h26.99v4.009h-26.99zm131.776 24.838l27.245 6.27v6.269l-27.245-6.856v-5.683zM7.157 308.713h26.99v4.009H7.157zm268.461-41.361l26.197 10.202v4.18l-26.197-9.614v-4.768zm-.122 7.353l26.197 10.202v4.179l-26.197-9.613v-4.768zm.058 152.479l26.973 6.346v4.18l-26.973-5.511v-5.015z"
      />
      <path
        d="M269.66 268.854l26.197 10.201v4.18l-26.197-9.613v-4.768zm.834 152.896l26.197 5.929v4.18l-26.197-5.511v-4.598zm-24.536-44.722l24.166 3.762v3.762l-24.166-3.344v-4.18z"
        fill="#E1E1E1"
      />
      <path
        d="M254.292 380.766l24.166 3.761v3.762l-24.166-3.344v-4.179z"
        fill="#E1E1E1"
      />
      <path fill="#D7D7D7" d="M117.118 304.703h26.99v4.009h-26.99z" />
      <path
        fill="#E1E1E1"
        d="M101.124 337.78h25.991v4.009h-25.991zm101.963-2.005h26.99v4.009h-26.99zm9.996-33.076h25.991v4.009h-25.991z"
      />
      <path
        d="M112.724 139.32h26.363l5.021 4.01h-35.987l4.603-4.01z"
        fill="#B6B6B6"
        fillOpacity={0.15}
      />
      <path fill="#E0E0E0" d="M22.152 287.664h35.987v5.012H22.152z" />
      <path fill="#EAEAEA" d="M47.143 278.643H84.13v4.009H47.143z" />
      <path fill="#E0E0E0" d="M10.156 265.613h36.987v4.009H10.156z" />
      <path fill="#E2E2E2" d="M197.089 235.543h35.987v4.009h-35.987z" />
      <path fill="#E4E4E4" d="M13.155 376.871h36.987v4.009H13.155z" />
      <path fill="#D8D8D8" d="M162.102 216.499h36.987v5.012h-36.987z" />
      <path
        fill="#E0E0E0"
        d="M45.144 244.564h36.987v4.009H45.144zm13.995 199.462h36.987v4.009H59.139z"
      />
      <path fill="#D0D0D0" d="M13.155 230.532h36.987v4.009H13.155z" />
      <path fill="#E1E1E1" d="M62.138 406.94h35.987v4.009H62.138z" />
      <path
        fill="#C4C4C4"
        fillOpacity={0.52}
        d="M151.105 193.446h29.989v4.009h-29.989zm-18.993 127.295h29.989v4.009h-29.989zm60.978 77.178h29.989v5.012H193.09z"
      />
      <path
        fill="#C4C4C4"
        fillOpacity={0.62}
        d="M40.145 201.464h36.987v4.009H40.145zm40.986 124.288h35.987v4.009H81.131z"
      />
      <path fill="#E4E4E4" d="M34.148 379.878h36.987v4.009H34.148z" />
      <path fill="#C4C4C4" d="M31.149 393.91h21.992v4.009H31.149z" />
      <path fill="#C4C4C4" d="M25.151 396.917h21.992v4.009H25.151z" />
      <path fill="#DBDBDB" d="M55.14 223.515h36.987v4.009H55.14z" />
      <path fill="#E0E0E0" d="M192.091 410.95h35.987v4.009h-35.987z" />
      <path
        fill="#C4C4C4"
        fillOpacity={0.68}
        d="M114.119 176.406h36.987v4.009h-36.987zm40.985 243.564h36.987v4.009h-36.987zm-138.95 14.033h35.987v4.009H16.154zM37.147 429.994h35.987v4.009H37.147z"
      />
      <path
        fill="#DBDBDB"
        d="M151.105 339.785h31.988v4.009h-31.988zm47.983 104.241h32.988v4.009h-32.988z"
      />
      <path fill="#E1E1E1" d="M65.136 186.43h31.988v4.009H65.136z" />
      <path
        fill="#E0E0E0"
        d="M126.115 165.381h35.987v4.009h-35.987zM167.1 406.94h35.987v4.009H167.1z"
      />
      <path d="M86.22 157.362h31.897v4.009H82.13l4.09-4.009z" fill="#DBDBDB" />
      <path
        d="M95.304 157.362h13.817m-62.805 280.65H29.149"
        stroke="#000"
        strokeOpacity={0.5}
        strokeWidth={0.5}
      />
      <path
        d="M305.304 268.62l3.745-7.111-58.251-32.629-126.486-114.618-128.15 113.781 5.408 6.275 122.742-110.853 123.99 114.618 57.002 30.537z"
        fill="#E2E0E0"
        stroke="#000"
      />
      <path d="M239.074 229.529v98.228" stroke="#000" />
      <path fill="#fff" d="M168.265 319.745h51.899v48.401h-51.899z" />
      <path
        d="M185.516 320.604h-8.754l32.068 44.417 13.339 3.761-36.653-48.178z"
        fill="#F2F2F2"
        fillOpacity={0.51}
      />
      <path stroke="#000" d="M194.048 319.745v48.4m-25.783-23.031h51.898" />
      <path
        d="M221.781 368.849h-58.26s-2.918-3.344-1.25-7.106c1.667-3.761 4.904 1.579 4.904 1.579 3.12-2.251 4.574-3.972 5.738 1.671.925-4.227 3.016-6.362 5.1-6.362 3.115-5.915 10.263-1.622 13.339 2.183 3.1-.818 8.496-.669 9.587 2.508 3.654-4.273 4.596-4.018 7.087-5.733 4.16-1.964 5.796-.251 7.92 2.389 7.452 1.874 8.336 5.015 5.835 8.871z"
        fill="#E2E0E0"
        stroke="#000"
      />
      <path
        d="M208.557 320.45h-9.588l20.426 21.052V320.45h-10.838z"
        fill="#F2F2F2"
        fillOpacity={0.43}
      />
      <path fill="#CCC" d="M162.59 369.087h60.859v7.523H162.59z" />
      <path
        d="M80.131 363.84v-43.099H28.15v38.874m180.935 2.693c2.41-1.92 3.251-2.012 3.618 0 2.699.762 2.477 1.033 2.261 1.532M84.13 368.852H24.15v7.016h59.98v-7.016zm0 0h-2.482"
        stroke="#000"
      />
      <path d="M153.197 353.959H93.765v96.672h59.432v-96.672z" fill="#D7D7D7" />
      <path
        d="M100.124 359.831v87.154h17.349v-87.154h-17.349zm27.717.897v87.153h17.349v-87.153h-17.349z"
        stroke="#EBEBEB"
      />
      <rect
        x={116.368}
        y={382.132}
        width={2.17}
        height={45.207}
        rx={1.085}
        fill="#AEADAD"
        stroke="#000"
        strokeWidth={0.5}
      />
      <rect
        x={126.158}
        y={381.876}
        width={2.17}
        height={45.207}
        rx={1.085}
        fill="#AEADAD"
        stroke="#000"
        strokeWidth={0.5}
      />
      <path d="M81.13 370.857h-8.996" stroke="#000" strokeWidth={0.5} />
      <path
        d="M8 229l.157 223.045M239.074 337.78v114.265m-86.969-.419V354.82H93.127v97.225m73.986-89.441v-41.863h51.79v40.206m2.088 7.46h2.089v7.461h-60.978v-7.461h.835"
        stroke="#000"
      />
      <path
        d="M219.081 249.576h-12.995m-183.934 43.1h10.996M221.08 397.919h-10.996"
        stroke="#000"
        strokeOpacity={0.5}
        strokeWidth={0.5}
      />
      <path
        d="M55.14 364.048c.628-1.409 2.478-1.805 5.316 0 2.334-.77 3.32-1.498 3.681.795"
        stroke="#000"
      />
      <path
        d="M296.053 279.645l-18.993-8.018m19.993 160.371l-18.993-4.009m3.498-149.847l-6.497-2.506"
        stroke="#000"
        strokeOpacity={0.1}
        strokeWidth={0.5}
      />
      <path
        d="M269.063 309.715l12.995 3.901v12.136"
        stroke="#000"
        strokeOpacity={0.47}
      />
      <path
        d="M-40.5 347.782c0 18.348 18.766 33.405 42.167 33.405 23.402 0 42.167-15.057 42.167-33.405S25.07 314.377 1.667 314.377c-23.401 0-42.167 15.057-42.167 33.405z"
        fill="#E2E0E0"
        stroke="#000"
      />
      <path
        d="M4.167 355.903h-.159l-2.852 96.059h6.022l-3.011-96.059z"
        fill="#63616C"
      />
      <path
        opacity={0.2}
        d="M42.029 337.534c-1.465-3.834-3.788-7.428-6.856-10.61 2.916 6.858 3.054 14.304.34 21.267-3.05 7.819-9.485 14.492-18.122 18.852-8.638 4.359-18.934 6.132-29.053 5.011-9.04-1.002-17.43-4.258-23.981-9.28 2.297 3.453 5.348 6.572 9.025 9.206 4.539 3.251 9.92 5.684 15.771 7.125 5.851 1.441 12.029 1.854 18.103 1.21 6.075-.644 11.896-2.33 17.06-4.937 5.165-2.606 9.545-6.07 12.844-10.143 3.297-4.073 5.434-8.658 6.273-13.436.839-4.778.36-9.644-1.404-14.265z"
        fill="#A3A3A3"
        stroke="#000"
      />
      <path d="M7.157 452.045H168.1" stroke="#000" />
      <path
        d="M150.577 455.03H385.78c7.617-14.557 8.877-20.247-9.648-13.337 2.715-28.878-5.53-31.326-29.977-21.987-3.591-7.023-6.109-8.484-12.059-3.965-5.547-28.747-42.12-30.402-53.407-10.813-15.464-8.961-23.823-12.381-35.145 0-44.576-9.212-24.458 9.678-33.767 16.941-5.767-10.599-8.118-10.052-11.37-2.163-20.125-24.241-22.723-4.067-32.044 13.697-44.048-24.084-33.159 22.81-17.786 21.627z"
        fill="#F0EEEE"
        stroke="#000"
      />
      <path
        d="M8.157 452.045H-7.837m310.888-34.528c19.263-10.927 16.736 6.403 27.46 10.506 8.384-4.687 11.185-2.539 13.525 7.985"
        stroke="#000"
      />
      <path
        d="M291.862 415.454l-14.379-22.406.665-.429 13.645 21.262 11.118-21.231.7.368-11.749 22.436z"
        fill="#3F3D56"
      />
      <path
        d="M303.117 383.578h-34.206v-.594a7.35 7.35 0 00-2.143-5.182 7.307 7.307 0 00-10.347 0 7.332 7.332 0 00-2.142 5.182v.594h-3.362c-1.94 0-3.801.772-5.173 2.147a7.334 7.334 0 000 10.364 7.31 7.31 0 005.173 2.146h3.362v55.383c14.577-.293-.106.296 14.632 0v-55.383h34.206a7.311 7.311 0 005.174-2.146 7.335 7.335 0 002.142-5.182c0-1.944-.77-3.808-2.142-5.182a7.308 7.308 0 00-5.174-2.147z"
        fill="#88878C"
      />
      <path
        d="M307.488 428.743h-31.631a5.64 5.64 0 01-3.983-1.655 5.659 5.659 0 01-1.652-3.99v-10.294a5.655 5.655 0 011.653-3.989 5.632 5.632 0 013.982-1.655h31.631a5.634 5.634 0 013.983 1.655 5.654 5.654 0 011.652 3.989v10.294a5.654 5.654 0 01-1.652 3.99 5.639 5.639 0 01-3.983 1.655z"
        fill="#D2D2D2"
      />
      <path
        d="M299.206 409.87h-19.968c-1.19 0-2.154 1.121-2.154 2.503 0 1.383.964 2.504 2.154 2.504h19.968c1.189 0 2.153-1.121 2.153-2.504 0-1.382-.964-2.503-2.153-2.503zm-6.865 6.676h-13.772c-.82 0-1.485 1.121-1.485 2.503 0 1.383.665 2.504 1.485 2.504h13.772c.82 0 1.485-1.121 1.485-2.504 0-1.382-.665-2.503-1.485-2.503z"
        fill="#E5E5E5"
      />
      <path d="M173.793 380.918h18.066v6.004h-18.066v-6.004z" fill="#EAEAEA" />
      <path d="M121.116 356.824v96.724" stroke="#000" strokeWidth={0.2} />
      <path d="M385.521 454.551H444" stroke="#000" />
      <path
        d="M366.028 495.145h-5.998m0 0l-3.499-2.506-1.499 2.506"
        stroke="#000"
        strokeWidth={0.5}
      />
    </svg>
  );
}

export default SvgComponent;
