import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TextField, Typography, Slider } from '@material-ui/core';
import { fieldToTextField } from 'formik-material-ui';
import styled from 'styled-components';
import CustomTooltip from '../../Tooltip';

/* eslint-disable no-restricted-properties */
const oneThousand = Math.pow(10, 3);
const oneMillion = Math.pow(10, 6);
/* eslint-enable no-restricted-properties */

const abbreviateValue = value => {
    if (value < oneThousand) {
        return value;
    }
    if (value >= oneThousand && value < oneMillion) {
        return `${(value / oneThousand).toFixed(1)}K`;
    }
    if (value >= oneMillion) {
        return `${(value / oneMillion).toFixed(1)}M`;
    }
    return '0';
};


const PrettoSlider = withStyles({
    root: {
        height: 8
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus,&:hover,&$active': {
            boxShadow: 'inherit'
        }
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)'
    },
    track: {
        height: 8,
        borderRadius: 4
    },
    rail: {
        height: 8,
        borderRadius: 4
    }
})(Slider);

const Container = styled.div`
  width: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const useStyles = makeStyles({
    root: {
        width: 400
    },
    label: {
        display: 'flex'
    },
    input: {
        maxWidth: 150,
        widht: 100
    }
});

const InputSlider = ({ label, step, max = 0, inputProps, tooltiptype, tooltipcontent, ...props }) => {
    const classes = useStyles();
    const { value } = fieldToTextField(props);
    const [maxVal, setMax] = useState(max);

    useEffect(() => {
        const currVal = value || 10;
        if (max <= currVal) {
            setMax(currVal * 2);
        }
    }, []);

    return (
        <Container>
            <FlexContainer>
                <Typography variant="subtitle1" id="input-slider" className={classes.label}>
                    {label} {tooltipcontent !== '' && <CustomTooltip tooltiptype={tooltiptype} tooltipcontent={tooltipcontent}/>}
                </Typography>
                <TextField
                    variant="outlined"
                    className={classes.input}
                    {...fieldToTextField(props)}
                    InputProps={inputProps || null}
                    onBlur={(event) => {
                        const { value } = event.target;
                        const { homePrice } = props.form.values;
                        const currVal = value ? Number(value) : null;
                        if (currVal) {
                            props.form.setFieldValue(props.field.name, currVal);
                            if (currVal >= maxVal) {
                                setMax((currVal * .2) + currVal);
                            }
                            if (props.field.name === 'homePrice') {
                                const { downPaymentPercentage } = props.form.values;
                                props.form.setFieldValue('downPayment', Math.round(currVal * (downPaymentPercentage / 100)));
                            }
                            if (props.field.name === 'downPayment') {
                                const downPaymentVal = currVal || 0;
                                props.form.setFieldValue(props.field.name, Math.round(downPaymentVal));
                                props.form.setFieldValue('downPaymentPercentage', Math.round((downPaymentVal / homePrice) * 100));
                            }
                            if (props.field.name === 'downPaymentPercentage') {
                                const downPaymentPercentageVal = currVal || 0;
                                props.form.setFieldValue(props.field.name, downPaymentPercentageVal);
                                props.form.setFieldValue('downPayment', Math.round((downPaymentPercentageVal / 100) * homePrice));
                            }
                        } else {
                            props.form.setFieldValue(props.field.name, currVal);
                        }
                    }}
                />
            </FlexContainer>
            <PrettoSlider
                color="secondary"
                valueLabelDisplay="auto"
                ValueLabelComponent={CustomTooltip}
                valueLabelFormat={abbreviateValue}
                {...fieldToTextField(props)}
                onChange={(event, newValue) => {
                    if (newValue >= maxVal) {
                        setMax((newValue * .2) + newValue);
                    }
                    props.form.setFieldValue(props.field.name, newValue);
                    const { homePrice } = props.form.values;

                    if (props.field.name === 'homePrice') {
                        const { downPaymentPercentage } = props.form.values;
                        props.form.setFieldValue('downPayment', Math.round(newValue * (downPaymentPercentage / 100)));
                    }
                    if (props.field.name === 'downPayment') {
                        const downPaymentVal = newValue || 0;
                        props.form.setFieldValue(props.field.name, Math.round(downPaymentVal));
                        props.form.setFieldValue('downPaymentPercentage', Math.round((downPaymentVal / homePrice) * 100));
                    }
                    if (props.field.name === 'downPaymentPercentage') {
                        const downPaymentPercentageVal = newValue || 0;
                        props.form.setFieldValue(props.field.name, downPaymentPercentageVal);
                        props.form.setFieldValue('downPayment', Math.round((downPaymentPercentageVal / 100) * homePrice));
                    }
                }}
                max={maxVal}
                step={step}
                aria-labelledby="input-slider"
            />
        </Container>
    );
};

InputSlider.propTypes = {
    inputProps: PropTypes.any,
    label: PropTypes.string.isRequired,
    max: PropTypes.number,
    tooltiptype: PropTypes.string,
    tooltipcontent: PropTypes.string,
    step: PropTypes.number,
    field: PropTypes.shape({
        name: PropTypes.string
    }).isRequired,
    form: PropTypes.shape({
        setFieldValue: PropTypes.func,
        values: PropTypes.object
    }).isRequired
};

InputSlider.defaultProps = {
    max: 0,
    step: 1,
    inputProps: {},
    tooltiptype: '',
    tooltipcontent: ''
};

export default InputSlider;
