import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import propTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';
import HelpIcon from '@material-ui/icons/Help';

const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 11
    }
}))(Tooltip);

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9'
    }
}))(Tooltip);

const useStyles = makeStyles({
    tooltipBtn: {
        width: '15px',
        height: '15px',
        marginLeft: '5px',
        cursor: 'pointer'
    }
});

const CustomTooltip = ({ tooltipcontent, children, open, value, tooltiptype }) => {
    const classes = useStyles();
    const popperRef = React.useRef(null);
    React.useEffect(() => {
        if (popperRef.current) {
            popperRef.current.update();
        }
    });

    return (
        <div >
            {tooltiptype === 'custom'
                ? (
                    <HtmlTooltip
                        title={ tooltipcontent }
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                    >
                        <HelpIcon className={classes.tooltipBtn} />
                    </HtmlTooltip>
                )
                : (
                    <LightTooltip
                        TransitionComponent={Zoom}
                        open={open}
                        enterTouchDelay={0}
                        placement="top"
                        PopperProps={{ popperRef }}
                        title={value}
                    >
                        {children}
                    </LightTooltip>
                )
            }
        </div>
    );
};

CustomTooltip.propTypes = {
    tooltipcontent: propTypes.string,
    tooltiptype: propTypes.string,
    children: propTypes.element,
    open: propTypes.bool,
    value: propTypes.any
};
CustomTooltip.defaultProps = {
    tooltipcontent: '',
    tooltiptype: 'simple',
    children: <div />,
    open: false,
    value: 0
};


export default CustomTooltip;
