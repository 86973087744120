import React from 'react';
import TwoColSection from '../components/TwoColSection/TwoColSection';
import PropertyTableImg from '../public/PropertiesTable.png';

const StatsSection = (): JSX.Element => {
  return (
    <div style={{ backgroundColor: '#fafafa' }}>
      <TwoColSection
        eyebrow="05"
        title="Saved properties table"
        bodyContent="Export your findings to Excel. Sort properties by greatest to least cashflow. Delete and edit properties from table and more."
        image={{
          src: PropertyTableImg,
          alt: 'CashFlowExpert dashboard',
          height: '450px'
        }}
      />
    </div>
  );
};

export default StatsSection;
