import React from 'react';
import clsx from 'clsx';
import { withTheme } from '@material-ui/core';
import { Grid, Typography, Button } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import ResponsiveModal from '../Modal';
import SwipeableTextMobileStepper from '../Carousel/Carousel';
import Payment from '../Payment/Payment';
import useStyles from './styles';

interface Image {
  src: string;
  alt: string;
  width?: string;
  height?: string;
  figureClass?: string;
  imgClass?: string;
  style?: object;
}

interface Video {
  src: string;
  width?: string;
  height?: string;
}
interface CTA {
  url: string;
  alt: string;
  text: string;
  target?: string;
  variant?: string;
  rel?: string;
}
interface SectionProps {
  textLeft?: boolean;
  eyebrow?: string;
  title: string;
  cta?: CTA;
  bodyContent: any;
  image?: Image;
  video?: Video;
  showDemo?: boolean;
  slides?: any;
  type?: string;
  altComponent?: JSX.Element;
  icon?: string;
  svg?: any;
  payment?: boolean;
}

const TwoColSection: React.FC<SectionProps> = ({
  eyebrow,
  title,
  bodyContent,
  image,
  video,
  textLeft,
  cta,
  showDemo,
  slides,
  type,
  altComponent,
  icon,
  svg,
  payment
}: SectionProps): JSX.Element => {
  const classes = useStyles();
  const hasArray = Array.isArray(bodyContent);
  const [videoTime, setVideoTime] = React.useState(0);
  const [selected, setSelected] = React.useState(0);

  let videoRef: any = '';

  const updateVideoTime = (index: number): void => {
    setSelected(index);
    switch (index) {
      case 1:
        setVideoTime(17);
        break;
      case 2:
        setVideoTime(32);
        break;
      default:
        setVideoTime(0);
        break;
    }
  };

  React.useEffect(() => {
    let interval: number;
    if (videoRef !== '') {
      videoRef.currentTime = videoTime;
      if (videoRef.paused && selected !== 2) {
        videoRef.play();
      }

      interval = setInterval(() => {
        if (selected === 2) {
          clearInterval(interval);
        } else {
          updateVideoTime(selected + 1);
          setSelected(selected + 1);
        }
      }, 17000);
    }

    return (): void => {
      clearInterval(interval);
    };
  }, [videoTime, selected, videoRef]);

  return (
    <Grid
      container
      direction={textLeft ? 'row-reverse' : 'row'}
      alignItems="center"
      justifyContent="space-between"
      className={
        video ? classes.sectionWrapperWithVideo : classes.sectionWrapper
      }
    >
      <Grid
        item
        container
        xs={12}
        sm={6}
        justifyContent="center"
        className={classes.section}
      >
        <>
          {image && (
            <figure
              className={
                image.figureClass ? classes.extenstionfigure : classes.figure
              }
            >
              <img
                className={image.imgClass ? image.imgClass : classes.image}
                width={image.width && image.width}
                height={image.height && image.height}
                style={image.style}
                src={image.src}
                alt={image.alt}
              />
            </figure>
          )}
          {svg && <ReactSVG className={classes.styledSvg} src={svg} />}
          {altComponent && altComponent}
          {slides && <SwipeableTextMobileStepper slides={slides} />}
          {video && (
            <video
              ref={(el): HTMLVideoElement | null => (videoRef = el)}
              className={classes.videoWrapper}
              width={video.width || '340px'}
              height={video.height || '240px'}
              muted
              autoPlay
            >
              <source src={video.src} type="video/mp4" />
            </video>
          )}
        </>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={video ? 5 : 6}
        justifyContent="center"
        className={classes.section}
      >
        <Grid container className={classes.colItem} direction="column">
          {eyebrow && (
            <Typography color="secondary" variant="h2" display="block">
              {eyebrow}
            </Typography>
          )}
          <Typography
            variant="h3"
            className={classes.mainHeading}
            gutterBottom
            style={video && { color: 'white' }}
          >
            {title}{' '}
            {icon && (
              <img className={classes.titleIcon} src={icon} alt="title icon" />
            )}
          </Typography>
          {hasArray ? (
            <div style={{ marginBottom: '2em' }}>
              {bodyContent.map(
                (content: any, index: number): React.ReactElement =>
                  type === 'complex' ? (
                    <div
                      key={index}
                      className={
                        selected === index
                          ? clsx(classes.itemVideo, classes.selected)
                          : classes.itemVideo
                      }
                      onClick={(): void => updateVideoTime(index)}
                    >
                      <Typography
                        variant="h4"
                        className={classes.multiSectionTitle}
                        style={video && { color: 'white' }}
                      >
                        {index + 1}. {content.title}
                      </Typography>
                      <Typography
                        key={index}
                        style={video && { color: 'white' }}
                        variant="subtitle1"
                        gutterBottom
                      >
                        {content.desc}
                      </Typography>
                    </div>
                  ) : (
                    <div key={index} style={{ width: '100%' }}>
                      <Typography
                        variant="h4"
                        className={classes.multiSectionTitle}
                        style={video && { color: 'white' }}
                      >
                        {content.title}
                      </Typography>
                      <Typography
                        key={index}
                        style={video && { color: 'white' }}
                        variant="subtitle1"
                        gutterBottom
                      >
                        {content.desc}
                      </Typography>
                    </div>
                  )
              )}
            </div>
          ) : (
            <Typography variant="h4" className={classes.colCopy}>
              {bodyContent}
            </Typography>
          )}
          {cta && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.primaryButton}
              href={cta.url}
              target={cta.target}
              rel={cta.rel ? cta.rel : ''}
            >
              {cta.text}
            </Button>
          )}
          {payment && (
            <div className={classes.paymentButton}>
              <Payment />
            </div>
          )}
          {showDemo && (
            <ResponsiveModal
              className={classes.primaryButton}
              buttonText="View Demo"
              youtubeId="ric5C2mR2EM"
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTheme(TwoColSection);
