import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import useStyles from './styles';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

interface Slide {
  label?: string;
  imgPath?: string;
}

interface SliderProps {
  slides: any;
}

const SwipeableTextMobileStepper: React.FC<SliderProps> = ({
  slides
}: any): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = slides.length;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      {slides[activeStep].label && (
        <Paper square elevation={0} className={classes.header}>
          <Typography>{slides[activeStep].label}</Typography>
        </Paper>
      )}
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {slides.map(
          (step: any, index: number): JSX.Element => (
            <div key={`${step.label}-${index}`} style={{ height: '100%' }}>
              {Math.abs(activeStep - index) <= 2 && (
                <img
                  className={classes.img}
                  src={step.imgPath}
                  alt={step.label}
                />
              )}
            </div>
          )
        )}
      </AutoPlaySwipeableViews>
      <MobileStepper
        className={classes.slider}
        steps={maxSteps}
        position="static"
        nextButton={
          <Button
            className={classes.button}
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
            className={classes.button}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </Button>
        }
      />
    </div>
  );
};

export default SwipeableTextMobileStepper;
