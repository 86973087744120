import React from "react";
import ReactPlayer from "react-player/youtube";
import VideoPlaySvg from "../Svgs/VideoPlaySvg";
import PoolImage from "../../public/pool&floaty.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%",
  },

  reactPlayer: {
    position: "absolute",
    top: "0",
    left: "0",
  },
}));

const Player = () => {
  const classes = useStyles();
  return (
    <div className={classes.playerWrapper}>
      <ReactPlayer
        url="https://www.youtube.com/watch?v=ric5C2mR2EM"
        className={classes.reactPlayer}
        playing={true}
        width="100%"
        height="100%"
        controls={true}
        light={PoolImage}
        loop={true}
        playIcon={<VideoPlaySvg />}
      />
    </div>
  );
};

export default Player;
