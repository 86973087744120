import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useQuery } from '@apollo/client';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuTabScreen from '../components/MenuTabScreen';
import { READ_PROPERTY } from '../graphql/param_queries';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  toolBar: {
    height: '70px'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  homeIcon: {
    borderRadius: '2px',
    width: 80,
    marginRight: 20,
    height: 'auto',
    border: '2px solid white'
  }
}));

export default function FullScreenDialog() {
  const history = useHistory();
  const { propertyId }: any = useParams();
  const [currHomeValues, setCurrHomeValues]: any = React.useState(null);

  useQuery(READ_PROPERTY, {
    variables: { id: propertyId },
    fetchPolicy: 'cache-and-network',
    onCompleted: (res: any): any => {
      const {
        property_by_pk: { data = '', board_id }
      } = res;
      const resHomeData = JSON.parse(data);
      setCurrHomeValues({
        id: propertyId,
        boardId: board_id,
        homeData: resHomeData
      });
    }
  });

  const classes = useStyles();
  return (
    <div>
      {currHomeValues ? (
        <>
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="close"
                onClick={() => history.goBack()}
                style={{ marginLeft: '12px' }}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Back
              </Typography>
              <Avatar
                className={classes.homeIcon}
                variant="square"
                alt="Remy Sharp"
                src={currHomeValues.homeData.imageLink}
              />
              <Typography variant="subtitle1">
                {currHomeValues.homeData.streetAddress}
              </Typography>
            </Toolbar>
          </AppBar>
          {currHomeValues && <MenuTabScreen homeValue={currHomeValues} />}
        </>
      ) : (
        <LinearProgress color="secondary" />
      )}
    </div>
  );
}
