import { makeStyles } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';

const defaultTheme = createTheme();
const useStylesAntDesign = makeStyles(
  theme => ({
    root: {
      '& .super-app.negative': {},
      '& .super-app.positive': {},
      '& .super-app.neutral': {},
      background: 'white',
      boxShadow: '0px 12px 24px #eeeeee',
      margin: '20px 0px',
      borderRadius: '8px',
      border: `0.8px solid #DBDBDB`,
      color:
        theme.palette.type === 'light'
          ? 'rgba(0,0,0,.85)'
          : 'rgba(255,255,255,0.85)',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      WebkitFontSmoothing: 'auto',
      letterSpacing: 'normal',
      '& .MuiDataGrid-columnsContainer': {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        backgroundColor: '#F9F9F9'
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none'
      },
      '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {},
      '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {},
      '& .MuiDataGrid-cell': {
        color:
          theme.palette.type === 'light'
            ? 'rgba(0,0,0,.85)'
            : 'rgba(255,255,255,0.85)',
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"'
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-columnsContainer': {
          backgroundColor:
            theme.palette.type === 'light' ? '#fafafa' : '#1d1d1d'
        },
        '& .MuiDataGrid-iconSeparator': {
          display: 'none'
        },
        '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {},
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {},
        '& .MuiDataGrid-cell': {
          color:
            theme.palette.type === 'light'
              ? 'rgba(0,0,0,.85)'
              : 'rgba(255,255,255,0.65)'
        },
        '& .MuiPaginationItem-root': {
          borderRadius: 0
        },
        '& .MuiCheckbox-root svg': {
          width: 16,
          height: 16,
          backgroundColor: 'transparent',
          border: `1px solid ${
            theme.palette.type === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
          }`,
          borderRadius: 2
        },
        '& .MuiCheckbox-root svg path': {
          display: 'none'
        },
        '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
          backgroundColor: '#1890ff',
          borderColor: '#1890ff'
        },
        '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
          position: 'absolute',
          display: 'table',
          border: '2px solid #fff',
          borderTop: 0,
          borderLeft: 0,
          transform: 'rotate(45deg) translate(-50%,-50%)',
          opacity: 1,
          transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
          content: '""',
          top: '50%',
          left: '39%',
          width: 5.71428571,
          height: 9.14285714
        },
        '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
          width: 8,
          height: 8,
          backgroundColor: '#1890ff',
          transform: 'none',
          top: '39%',
          border: 0
        }
      }
    }
  }),
  { defaultTheme }
);

export default useStylesAntDesign;
