import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { useAuth0 } from '../utils/react-auth0-spa';
import { useGlobalContext } from '../context/GlobalContext';

import {
  Avatar as MuiAvatar,
  CardContent,
  Box,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Typography
} from '@material-ui/core';

import { spacing } from '@material-ui/system';
import Payment from '../components/Payment/Payment';
import Unsubscribe from '../components/Unsubscribe/Unsubscribe';
import bgImage from '../public/account-bg.jpg';

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Spacer = styled.div(spacing);

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const Centered = styled.div`
  text-align: center;
`;

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100vh;
  background-image: url('${bgImage}');
  background-size: cover;
  background-position: center;
`;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: '350px',
      boxShadow: '1px 1px 10px rgba(0,0,0, .8)',
      backgroundColor: '#fff',
      margin: '0 auto'
    },
    avatar: {
      marginBottom: '1em'
    }
  })
);

const AccountPage: React.FC<{}> = (): React.ReactElement => {
  const classes = useStyles();
  const { user = {} }: any = useAuth0();
  const { userContext }: any = useGlobalContext();

  const renderPaymentButton = () => {
    if (userContext && userContext.user.type !== 'paid') {
      return <Payment />;
    }
    if (userContext && userContext.user.type === 'paid') {
      return <Unsubscribe />;
    }
  };

  const getAccountType = () => {
    if (userContext && userContext.user.type !== 'paid') {
      return 'Inactive';
    }
    if (userContext && userContext.user.type === 'paid') {
      return 'Active';
    }
  };

  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
    >
      <Root>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Card px={6} pt={6} className={classes.card}>
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <Spacer mb={4} />
                    <Centered>
                      <Avatar
                        alt=""
                        src={user.picture}
                        className={classes.avatar}
                      />
                      <Typography variant="body2" component="div" gutterBottom>
                        <Box fontWeight="fontWeightMedium">{user.name}</Box>
                        <Box fontWeight="fontWeightRegular">{user.email}</Box>
                      </Typography>
                    </Centered>
                  </Grid>
                  <Grid item container xs={12} justify="center">
                    <Typography variant="h6" gutterBottom>
                      Membership status: {getAccountType()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    {renderPaymentButton()}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Root>
    </motion.div>
  );
};

export default AccountPage;
