import React from 'react';
import TwoColSection from '../components/TwoColSection/TwoColSection';
import CashFlowGif from '../public/cashflowEdit.gif';

const HowItWorks: React.FC<{}> = (): React.ReactElement => {
  return (
    <div style={{ background: '#fafafa' }}>
      <TwoColSection
        eyebrow="01"
        title="Add CashFlowExpert to chrome"
        bodyContent="It only takes two clicks to add CashFlowExpert to Chrome and start calculating cashflow on zillow."
        image={{
          src: CashFlowGif,
          alt: 'CashFlowExpert extension',
          figureClass: 'extenstionfigure',
          height: '450px'
        }}
        type="complex"
        showDemo={true}
      />
    </div>
  );
};

export default HowItWorks;
