import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { gql, useMutation } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles, BootstrapInput } from './styles';
import statusConstants from './statusConstants';

const UPDATE_PROPERTY_STATUS = gql`
  mutation updatepropery($status: String!, $id: uuid!) {
    update_property_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
      status
    }
  }
`;

export default function CustomizedSelects({ currentStatus, propertyId }) {
  const classes = useStyles();

  const [status, setStatus] = React.useState(statusConstants[currentStatus]);

  const [updatePropertyStatus, { loading }] = useMutation(
    UPDATE_PROPERTY_STATUS,
    {
      onCompleted: data => {
        const {
          update_property_by_pk: { status }
        } = data;
        setStatus(status);
      },
      update: (
        cache,
        {
          data: {
            update_property_by_pk: { status }
          }
        }
      ) => {
        const cacheId = cache.identify({
          id: propertyId,
          __typename: 'property'
        });
        cache.modify({
          id: cacheId,
          fields: {
            status() {
              return status;
            }
          }
        });
      }
    }
  );

  const handleChange = event => {
    updatePropertyStatus({
      variables: {
        status: event.target.value,
        id: propertyId
      }
    });
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={status}
            onChange={handleChange}
            input={<BootstrapInput />}
          >
            <MenuItem value={statusConstants.UNASSIGNED}>
              <Chip
                style={{
                  background: '#EEEEEE',
                  color: '#4A4A4A',
                  fontWeight: 800,
                  fontSize: 15,
                  lineHeight: 18
                }}
                label="UNASSIGNED"
              />
            </MenuItem>
            <MenuItem value={statusConstants.TODO}>
              <Chip
                style={{
                  background: '#FEF8E8',
                  color: '#F2B33F',
                  fontWeight: 800,
                  fontSize: 15,
                  lineHeight: 18
                }}
                label="TO DO"
              />
            </MenuItem>
            <MenuItem value={statusConstants.ANALYZED}>
              <Chip
                style={{
                  background: '#FCF4F7',
                  color: '#A25369',
                  fontWeight: 800,
                  fontSize: 15,
                  lineHeight: 18
                }}
                label="ANALYZED"
              />
            </MenuItem>
            <MenuItem value={statusConstants.OFFER_SENT}>
              <Chip
                style={{
                  background: '#F3F4F9',
                  color: '#46638C',
                  fontWeight: 800,
                  fontSize: 15,
                  lineHeight: 18
                }}
                label="OFFER SENT"
              />
            </MenuItem>
            <MenuItem value={statusConstants.OFFER_ACCEPTED}>
              <Chip
                style={{
                  background: '#EEF9F6',
                  color: '#5FAE89',
                  fontWeight: 800,
                  fontSize: 15,
                  lineHeight: 18
                }}
                label="OFFER ACCEPTED"
              />
            </MenuItem>
          </Select>
        </FormControl>
      )}
    </div>
  );
}

CustomizedSelects.propTypes = {
  currentStatus: PropTypes.string.isRequired,
  propertyId: PropTypes.string.isRequired
};
