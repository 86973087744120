import React from 'react';
import TwoColSection from '../components/TwoColSection/TwoColSection';
import ListingPositive from '../public/ListingPositive.png';

const ProfitSection = (): JSX.Element => {
  return (
    <div style={{ backgroundColor: '#ededed' }}>
      <TwoColSection
        eyebrow="02"
        title="Let us do the heavylifting for you"
        image={{
          src: ListingPositive,
          alt: 'calculated listings',
          style: {
            height: 'auto',
            maxWidth: '350px'
          }
        }}
        bodyContent="With cashflowexpert you can analyze 50+ properties within seconds. Don't take our word for it, watch the extension in action below."
        textLeft={true}
        showDemo={true}
      />
    </div>
  );
};

export default ProfitSection;
