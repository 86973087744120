import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography
} from '@material-ui/core';
import useStyles from './styles';

const TabPanel = ({ children, value, index, ...other }) => {
    const classes = useStyles();

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            <Box className={classes.box} p={3}>
                {children}
            </Box>
        </Typography>
    );
};

TabPanel.propTypes = {
    children: PropTypes.any.isRequired,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

export default TabPanel;
