import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import useStyles from './styles';

const Footer = (): JSX.Element => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item container justifyContent="center" xs={9}>
          <Typography variant="body1" className={classes.title}>
            {`The CashFlowExpert calculator is designed to be used for informational and educational purposes only and, when used alone, does not constitute investment advice. Cashflow Expert recommends that you seek the advice of a real estate professional before making any type of investment. The results presented may not reflect the actual return of your own investments. Cashflow Expert is not responsible for the consequences of any decisions or actions taken in reliance upon or as a result of the information provided by these tools. Furthermore, Cashflow Expert is not responsible for any human or mechanical errors or omissions. `}
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
