import React from "react";

const VideoPlay = () => {
  return (
    <svg
      width="30%"
      height="30%"
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M53 0C23.744 0 0 23.744 0 53C0 82.256 23.744 106 53 106C82.256 106 106 82.256 106 53C106 23.744 82.256 0 53 0ZM46.64 73.67L71.391 55.12C72.822 54.06 72.822 51.94 71.391 50.88L46.64 32.33C44.891 31.005 42.4 32.277 42.4 34.45V71.55C42.4 73.723 44.891 74.995 46.64 73.67ZM10.6 53C10.6 76.373 29.627 95.4 53 95.4C76.373 95.4 95.4 76.373 95.4 53C95.4 29.627 76.373 10.6 53 10.6C29.627 10.6 10.6 29.627 10.6 53Z"
        fill="white"
      />
    </svg>
  );
};

export default VideoPlay;
