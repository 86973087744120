import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DoughnutChart from '../DoughnutChart';
import useStyles from './styles';
import TabNavigation from './TabNavigation';
import getDefaultHomevalues from './getDefaultValues';
import {
  IncomePanel,
  ExpensesPanel,
  ClosingCostPanel,
  DashboardPanel,
  MortgagePanel
} from './ChartPanels';

const VerticalTabs = ({ homeValue }) => {
  const { homeData } = homeValue;
  const currHomeValues = homeData;
  const {
    defaultCashonCashChartData,
    defaultCapRateChartData,
    defaultExpensesChartData,
    defaultMortgageChartData,
    defaultIncomeChartData,
    defaultCashflowChartData
  } = getDefaultHomevalues(currHomeValues);

  const classes = useStyles();

  const [incomeChartData, setIncomeChartData] = useState(
    defaultIncomeChartData
  );
  const [mortgageChartData, setMortgageChartData] = useState(
    defaultMortgageChartData
  );
  const [expenseChartData, setExpenseChartData] = useState(
    defaultExpensesChartData
  );
  const [cashflowChartData, setCashflowChartData] = useState(
    defaultCashflowChartData
  );
  const [cashOnCashData, setCashOnCashData] = useState(
    defaultCashonCashChartData
  );
  const [capRateData, setCapRatehData] = useState(defaultCapRateChartData);
  const [value, setValue] = useState(0);

  useEffect(() => {
    setIncomeChartData(defaultIncomeChartData);
    setMortgageChartData(defaultMortgageChartData);
    setExpenseChartData(defaultExpensesChartData);
    setCashflowChartData(defaultCashflowChartData);
    setCashOnCashData(defaultCashonCashChartData);
    setCapRatehData(defaultCapRateChartData);
  }, [homeValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderCashFlowChart = () => {
    const colors = ['#79bd8f', '#F96876', '#F74959', '#E72234'];
    return (
      <DoughnutChart
        titleText="Cashflow"
        colorTheme=""
        color={colors}
        chartData={cashflowChartData}
      />
    );
  };

  return (
    <div className={classes.root}>
      <TabNavigation value={value} handleChange={handleChange} />
      <IncomePanel
        value={value}
        homeValue={homeValue}
        renderCashFlowChart={renderCashFlowChart}
        incomeChartData={incomeChartData}
        cashOnCashData={cashOnCashData}
        capRateData={capRateData}
      />
      <MortgagePanel
        value={value}
        homeValue={homeValue}
        renderCashFlowChart={renderCashFlowChart}
        mortgageChartData={mortgageChartData}
        cashOnCashData={cashOnCashData}
      />
      <ExpensesPanel
        value={value}
        homeValue={homeValue}
        renderCashFlowChart={renderCashFlowChart}
        expenseChartData={expenseChartData}
        cashOnCashData={cashOnCashData}
      />
      <ClosingCostPanel
        value={value}
        homeValue={homeValue}
        renderCashFlowChart={renderCashFlowChart}
        cashOnCashData={cashOnCashData}
      />
      <DashboardPanel
        value={value}
        expenseChartData={expenseChartData}
        renderCashFlowChart={renderCashFlowChart}
        cashOnCashData={cashOnCashData}
        mortgageChartData={mortgageChartData}
        incomeChartData={incomeChartData}
        capRateData={capRateData}
      />
    </div>
  );
};

VerticalTabs.propTypes = {
  homeValue: PropTypes.any.isRequired
};

export default VerticalTabs;
