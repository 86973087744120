import React from 'react';
import PropTypes from 'prop-types';
import { Card, Tooltip } from '@material-ui/core';
import DoughnutChart from '../../DoughnutChart';
import TabPanel from '../TabPanel';
import useStyles from '../styles';

const DashboardPanel = ({
  value,
  expenseChartData,
  renderCashFlowChart,
  cashOnCashData,
  mortgageChartData,
  incomeChartData,
  capRateData
}) => {
  const classes = useStyles();

  return (
    <TabPanel className={classes.tabpanel} value={value} index={4}>
      <div className={classes.flexCont}>
        <div className={classes.dashboard}>
          <div className={classes.chartContainer}>
            <DoughnutChart
              titleText="Cash on Cash"
              colorTheme="tableau.HueCircle19"
              chartData={cashOnCashData}
              percentage
            />
          </div>
          <div className={classes.chartContainer}>{renderCashFlowChart()}</div>
          <div className={classes.chartContainer}>
            <DoughnutChart
              titleText="Monthly Expenses"
              colorTheme="tableau.HueCircle19"
              chartData={expenseChartData}
            />
          </div>
          <div className={classes.chartContainer}>
            <DoughnutChart
              titleText="Monthly Mortgage"
              colorTheme="tableau.HueCircle19"
              chartData={mortgageChartData}
            />
          </div>
          <div className={classes.chartContainer}>
            <DoughnutChart
              titleText="Monthly Income"
              colorTheme="tableau.HueCircle19"
              chartData={incomeChartData}
            />
          </div>
          <div className={classes.chartContainer}>
            <DoughnutChart
              titleText="CapRate"
              colorTheme="tableau.HueCircle19"
              chartData={capRateData}
              percentage
            />
            <Card className={classes.footnote}>
              <p>
                CapRate Formula:{' '}
                <Tooltip title="NOI = income - expenses">
                  <span className="tooltip-label">NOI</span>
                </Tooltip>{' '}
                / PurchasePrice
              </p>
              <p>
                Expenses Used: utilities, repairs, vacancy, property management,
                property taxes, home insurance, HOA
              </p>
            </Card>
          </div>
        </div>
      </div>
    </TabPanel>
  );
};

DashboardPanel.propTypes = {
  value: PropTypes.any.isRequired,
  expenseChartData: PropTypes.any.isRequired,
  renderCashFlowChart: PropTypes.any.isRequired,
  cashOnCashData: PropTypes.any.isRequired,
  mortgageChartData: PropTypes.any.isRequired,
  incomeChartData: PropTypes.any.isRequired,
  capRateData: PropTypes.any.isRequired
};

export default DashboardPanel;
