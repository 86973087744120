import React, { useEffect, useRef } from 'react';
import { Box, Button, Grid, Typography, Hidden } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import lottie from 'lottie-web';
import LeftHouseSvg from '../Svgs/LeftHouseSvg';
import RightHouseSvg from '../Svgs/RightHouseSvg';
import MoneyImage from '../../public/moneyImage.png';

const useStyles = makeStyles(theme =>
  createStyles({
    section: {
      height: '650px',
      [theme.breakpoints.down('sm')]: {
        height: '410px'
      },
      [theme.breakpoints.down('xs')]: {
        height: '450px'
      },
      width: '100%',
      display: 'flex',
      backgroundColor: '#e5ecee'
    },
    root: {
      position: 'relative',
      overflow: 'hidden',
      background: '#FFFFFF',
      flexGrow: 1,
      height: 617
    },
    subTitle: {
      marginTop: 30,
      marginBottom: 37,
      fontFamily: 'Satisfy',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 25,
      color: '#504B4B'
    },
    houseBackground: {
      zIndex: 1,
      position: 'absolute',
      top: '74%',
      backgroundColor: '#ededed',
      left: 0,
      right: 0,
      bottom: 0
    },
    subText: {
      fontWeight: 'normal',
      fontSize: 18,
      marginTop: 14,
      marginBottom: 25,
      margin: '0 auto'
    },
    title: {
      fontSize: 49,
      fontWeight: 400,
      color: '#4A4A4A',
      fontFamily: 'Merriweather',
      [theme.breakpoints.down('sm')]: {
        fontSize: 35
      }
    },
    dogLottie: {
      maxWidth: 150,
      position: 'absolute',
      bottom: '-14px',
      left: '286px'
    },
    ladyLottie: {
      maxWidth: 125,
      position: 'absolute',
      top: '190px',
      left: '70px'
    },
    moneyIcon: {
      height: 50,
      [theme.breakpoints.down('sm')]: {
        height: 25
      }
    },
    button: {
      marginTop: 25,
      [theme.breakpoints.down('sm')]: {
        margin: '25px auto 0 auto',
        width: 250
      },
      padding: 15,
      width: 300,
      color: '#FFFFFF',
      fontStyle: ' normal',
      fontWeight: 'bold',
      fontSize: 18,
      background: '#00C805',
      boxShadow: '0px 4px 25px rgba(2, 223, 7, 0.58)',
      borderRadius: 5
    }
  })
);
const Hero = (): React.ReactElement => {
  const classes = useStyles();
  const containerDog = useRef<HTMLDivElement>(null);
  const containerLady = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerDog.current) {
      lottie.loadAnimation({
        container: containerDog.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: require('../../public/dogLottie.json')
      });
    }
  }, [containerDog]);

  useEffect(() => {
    if (containerLady.current) {
      lottie.loadAnimation({
        container: containerLady.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: require('../../public/ladyLottie.json')
      });
    }
  }, [containerLady]);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
        style={{
          position: 'absolute',
          bottom: 30,
          zIndex: 2
        }}
      >
        <Grid item sm={12} md={5} style={{ position: 'relative' }}>
          <div className={classes.dogLottie} ref={containerDog} />
          <div className={classes.ladyLottie} ref={containerLady} />
          <LeftHouseSvg />
        </Grid>
        <Hidden smDown>
          <Grid item md={2}></Grid>
          <Grid item md={5} style={{ textAlign: 'end', position: 'relative' }}>
            <RightHouseSvg
              style={{ position: 'absolute', bottom: '50px', right: '0px' }}
            />
          </Grid>
        </Hidden>
      </Grid>
      <Grid
        container
        spacing={3}
        style={{
          textAlign: 'center',
          position: 'relative',
          zIndex: 3
        }}
      >
        {/* <Grid item xs={12}>
          <Typography>
            <Box className={classes.subTitle}>{`CashFlowExpert`}</Box>
          </Typography>
        </Grid> */}
        <Grid item xs={12}>
          <Typography variant="h2" style={{ marginTop: 55 }}>
            <Box className={classes.title}>{`If there's cashflow,`}</Box>
            <Box className={classes.title}>
              {`we'll find it  `}
              <img
                className={classes.moneyIcon}
                alt="money icon"
                src={MoneyImage}
              />
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Hidden xsDown>
            <Typography>
              <Box
                maxWidth={550}
                padding={'0 10'}
                className={classes.subText}
              >{`Our zillow chrome extension automates the calculation process so that you can focus on your next deal.`}</Box>
            </Typography>
          </Hidden>
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.button}
            target="_blank"
            variant="contained"
            size="large"
            color="secondary"
            href="https://chrome.google.com/webstore/detail/zillow-cash-flow-calculat/caanploiiehcbbledgfjccgljbgdcdbf"
            rel="noopener noreferrer"
          >
            Add to Chrome - Free
          </Button>
        </Grid>
      </Grid>
      <div className={classes.houseBackground} />
    </div>
  );
};

export default Hero;
