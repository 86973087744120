import React from 'react';

import TwoColSection from '../components/TwoColSection/TwoColSection';
import DashboardImg from '../public/dashboard.png';

const StatsOnStats = {
  cta: {
    url:
      'https://chrome.google.com/webstore/detail/zillow-cash-flow-calculat/caanploiiehcbbledgfjccgljbgdcdbf',
    text: 'Add to Chrome',
    alt: 'Link property search',
    target: '_blank',
    rel: 'noopener noreferrer'
  }
};

const StatsSection = (): JSX.Element => {
  return (
    <div style={{ backgroundColor: '#fafafa' }}>
      <TwoColSection
        eyebrow="03"
        title="Make the numbers work for you"
        bodyContent="Edit rental property values and view your results in a detailed dashboard."
        image={{
          src: DashboardImg,
          alt: 'CashFlowExpert dashboard',
          height: 'auto'
        }}
        cta={StatsOnStats.cta}
      />
    </div>
  );
};

export default StatsSection;
