import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import AccountPage from './pages/Account';
import Pricing from './pages/Pricing';
import PrivateRoute from './utils/PrivateRoute';
import AppNavBar from './components/AppBar/AppBar';
import BoardsPage from './pages/Boards';
import SingleBoardPage from './pages/SingleBoard';
import EditPropertyPage from './pages/EditPropertyPage';
import HowToPage from './pages/HowToPage';
import { AnimatePresence } from 'framer-motion';

const routes = (
  <>
    <AppNavBar />
    <AnimatePresence>
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/howitworks" exact component={HowToPage} />
        <PrivateRoute path="/dashboard" component={AccountPage} />
        <PrivateRoute exact path="/boards" component={BoardsPage} />
        <PrivateRoute
          exact
          path="/boards/properties/:id"
          component={SingleBoardPage}
        />
        <PrivateRoute
          exact
          path="/boards/properties/:id/property/:propertyId"
          component={EditPropertyPage}
        />
        <Route path="/pricing" component={Pricing} />
      </Switch>
    </AnimatePresence>
  </>
);

export default routes;
