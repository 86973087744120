import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 'auto',
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
        padding: 25
      },
      height: '150px',
      opacity: 0.98,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#3f3d56'
    },
    title: {
      color: '#fff',
      textAlign: 'center'
    }
  })
);

export default useStyles;
