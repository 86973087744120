import React from 'react';
import { Auth0Provider } from './utils/react-auth0-spa';
import config from './utils/auth_config.json';
import history from './utils/history';
import './styles.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import ApolloComponent from './ApolloComponent';

export default function App(): React.ReactElement {
  const onRedirectCallback = (appState: any): void => {
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };
  return (
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <CssBaseline />
      <ApolloComponent />
    </Auth0Provider>
  );
}
