import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2em',
      flexGrow: 1,
      height: '100%',
      paddingBottom: '70px'
    },
    titleGrid: {
      marginBottom: '76px'
    },
    sectionTitle: {
      color: '#4A4A4A',
      lineHeight: '44px',
      marginTop: '90px',
      fontWeight: 'bold'
    },
    sectionSubTitle: {
      color: '#5f5f5f',
      marginTop: '44px'
    },
    cardWrapper: {
      [theme.breakpoints.down('sm')]: {
        marginRight: '0',
        marginBottom: '24px'
      },
      [theme.breakpoints.up('md')]: {
        marginRight: '34px'
      },
      [theme.breakpoints.up('lg')]: {
        marginRight: '64px'
      }
    },
    paidCard: {
      border: '1px solid #00C805'
    }
  })
);

export default useStyles;
