import React, { useState, useContext } from 'react';

export const GlobalContext = React.createContext<any>({
  user: {},
  searchAddress: {}
});

export const useGlobalContext = () => useContext(GlobalContext);

export const GlobalContextProvider = ({
  children
}: any): React.ReactElement => {
  const [userContext, setUserContext] = useState<any>();
  const [searchContext, setSearchContext] = useState<any>({});
  const [loadingFetch, setLoadingFetch] = useState<any>(false);
  const [properties, setProperties] = useState<any>([]);
  const [errFetch, setErrorFetch] = useState<any>(false);
  const [price, setPrice] = useState<any>({ priceMax: null, priceMin: null });
  const [page, setPage] = React.useState(0);
  const [propertiesCount, setPropertiesCount] = useState<any>(0);
  const [homeTypes, setHomeTypes] = useState<any>('');

  //calculations
  const [expenses, setExpenses] = useState<any>({
    repairsPercent: 3,
    vacancyPercent: 3,
    propertyManagementPercent: 8,
    capExPercent: 10
  });

  const [loanDetails, setLoanDetails] = useState({
    downPaymentPercent: 20,
    interestRate: 3.5,
    loanLengthYears: 30
  });

  const [rates, setRates] = useState({
    propertyTaxPercent: 1.25,
    insuranceRatePercent: 0.3
  });

  const updateUserContext = (user: any): void => {
    setUserContext(user);
  };

  const updateSearchContext = (address: any): void => {
    setSearchContext(address);
  };

  return (
    <GlobalContext.Provider
      value={{
        userContext,
        updateUserContext,
        searchContext,
        updateSearchContext,
        loadingFetch,
        setLoadingFetch,
        errFetch,
        setErrorFetch,
        properties,
        setProperties,
        propertiesCount,
        setPropertiesCount,
        page,
        setPage,
        price,
        setPrice,
        homeTypes,
        setHomeTypes,
        expenses,
        setExpenses,
        loanDetails,
        setLoanDetails,
        rates,
        setRates
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
