import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    marginTop: 20
  },
  tabs: {
    '& .MuiButtonBase-root': {
      fontWeight: 700,
      fontSize: 18
    },
    '& .PrivateTabIndicator-root-19': {
      height: 4
    }
  }
}));

export default function SimpleTabs({ componetsArray = [] }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          className={classes.tabs}
        >
          {componetsArray.map(({ label }, index) => (
            <Tab key={label} label={label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </div>
      {componetsArray.map(({ component: TabComponent }, index) => (
        <TabPanel key={index} value={value} index={index}>
          <TabComponent />
        </TabPanel>
      ))}
    </div>
  );
}

SimpleTabs.propTypes = {
  componetsArray: PropTypes.array
};
