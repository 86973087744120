import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { gql, useMutation } from '@apollo/client';
import reorder, { reorderQuoteMap } from './helpers/reorder';
import Column from './components/Column';
import authorQuoteMap, { statusEnum } from './helpers/data';

const UPDATE_PROPERTY_STATUS = gql`
  mutation updatepropery($status: String!, $id: uuid!) {
    update_property_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
      status
      id
    }
  }
`;

const useStyles = makeStyles(() => ({
  parentContainer: {
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  Container: {
    height: '100%',
    width: '100%',
    display: 'inline-flex'
  }
}));

interface Board {
  properties: {
    address: string;
    casflow: number;
    coc: number;
    edit: any;
    id: string;
    image: string;
    price: number;
    status: string;
  }[];
}

const Board = (props: Board) => {
  const classes = useStyles();
  const [columns, setColumns] = useState<any>(authorQuoteMap(props.properties));
  const [ordered, setOrdered] = useState<any>(
    Object.keys(authorQuoteMap(props.properties))
  );

  const [updatePropertyStatus] = useMutation(UPDATE_PROPERTY_STATUS, {
    update: (
      cache,
      {
        data: {
          update_property_by_pk: { status, id }
        }
      }
    ) => {
      const cacheId = cache.identify({
        id,
        __typename: 'property'
      });
      cache.modify({
        id: cacheId,
        fields: {
          status() {
            return status;
          }
        }
      });
    }
  });

  const onDragEnd = (result: any) => {
    if (result.combine) {
      if (result.type === 'COLUMN') {
        const shallow = [...ordered];
        shallow.splice(result.source.index, 1);
        setOrdered(shallow);
        return;
      }

      const column = columns[result.source.droppableId];
      const withQuoteRemoved = [...column];
      withQuoteRemoved.splice(result.source.index, 1);
      setColumns({
        ...columns,
        [result.source.droppableId]: withQuoteRemoved
      });
      return;
    }

    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // reordering column
    if (result.type === 'COLUMN') {
      setOrdered(reorder(ordered, source.index, destination.index));
      return;
    }
    if (
      result.type === 'PROPERTY' &&
      result?.destination?.droppableId &&
      result?.draggableId
    ) {
      updatePropertyStatus({
        variables: {
          status: statusEnum[result.destination.droppableId],
          id: result.draggableId
        }
      });
    }

    const data = reorderQuoteMap({
      quoteMap: columns,
      source,
      destination
    });

    setColumns(data.quoteMap);
  };

  const board = (
    <Droppable droppableId="board" type="COLUMN" direction="horizontal">
      {(provided: any) => (
        <div
          className={classes.Container}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {ordered.map((key: number, index: number) => (
            <Column key={key} index={index} title={key} quotes={columns[key]} />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );

  return (
    <React.Fragment>
      <DragDropContext onDragEnd={onDragEnd}>{board}</DragDropContext>
    </React.Fragment>
  );
};

export default Board;
