import statusConstants from '../../StatusMenu/statusConstants';

const getByStatus = (status: any, items: any) =>
  items.filter((property: any) => property.status === status);

const todo = {
  id: '1',
  status: statusConstants.TODO,
  name: 'To do'
};

const analyzed = {
  id: '2',
  status: statusConstants.ANALYZED,
  name: 'Analyzed'
};

const offerSent = {
  id: '3',
  status: statusConstants.OFFER_SENT,
  name: 'Offer sent'
};

const offerAccepted = {
  id: '4',
  status: statusConstants.OFFER_ACCEPTED,
  name: 'Offer accepted'
};

export const status = [todo, analyzed, offerSent, offerAccepted];

export const statusEnum = {
  [todo.name]: todo.status,
  [offerAccepted.name]: offerAccepted.status,
  [analyzed.name]: analyzed.status,
  [offerSent.name]: offerSent.status
};

const statusMap = (properties: any) =>
  status.reduce(
    (previous, property) => ({
      ...previous,
      [property.name]: getByStatus(property.status, properties)
    }),
    {}
  );

export default statusMap;
