import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Card } from '@material-ui/core';
import IncomeForm from '../../IncomeForm';
import DoughnutChart from '../../DoughnutChart';
import TabPanel from '../TabPanel';
import useStyles from '../styles';

const IncomePanel = ({
  homeValue,
  value,
  renderCashFlowChart,
  incomeChartData,
  cashOnCashData,
  capRateData
}) => {
  const classes = useStyles();

  return (
    <TabPanel className={classes.tabpanel} value={value} index={0}>
      <div className={classes.flexCont}>
        <IncomeForm className={classes.form} homeValue={homeValue} />
        <div className={classes.charts}>
          <div className={classes.chartsPanel}>
            <div className={classes.chartContainer}>
              <DoughnutChart
                titleText="Monthly Income"
                colorTheme="tableau.HueCircle19"
                chartData={incomeChartData}
              />
            </div>
            <div className={classes.chartContainer}>
              {renderCashFlowChart()}
            </div>
            <div className={classes.chartContainer}>
              <DoughnutChart
                titleText="Cash on Cash"
                colorTheme="tableau.HueCircle19"
                chartData={cashOnCashData}
                percentage
              />
            </div>
            <div className={classes.chartContainer}>
              <DoughnutChart
                titleText="CapRate"
                colorTheme="tableau.HueCircle19"
                chartData={capRateData}
                percentage
              />
              <Card className={classes.footnote}>
                <p>
                  CapRate Formula:{' '}
                  <Tooltip title="NOI = income - expenses">
                    <span className="tooltip-label">NOI</span>
                  </Tooltip>{' '}
                  / PurchasePrice
                </p>
                <p>
                  Expenses Used: utilities, repairs, vacancy, property
                  management, property taxes, home insurance, HOA
                </p>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </TabPanel>
  );
};

IncomePanel.propTypes = {
  value: PropTypes.any.isRequired,
  homeValue: PropTypes.any.isRequired,
  renderCashFlowChart: PropTypes.any.isRequired,
  incomeChartData: PropTypes.any.isRequired,
  cashOnCashData: PropTypes.any.isRequired,
  capRateData: PropTypes.any.isRequired
};

export default IncomePanel;
