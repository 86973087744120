import * as React from 'react';
import PropTypes from 'prop-types';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport
} from '@material-ui/data-grid';
import tableStyles from './styles';

function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{
        backgroundColor: '#F9F9F9',
        borderRadius: 8
      }}
    >
      <GridToolbarExport
        csvOptions={{
          fields: [
            'address',
            'price',
            'cashflow',
            'coc',
            'yearlyCashflow',
            'totalIncome',
            'totalInvestment',
            'totalExpensesAmount',
            'mortgageTotal',
            'rentEstimate',
            'hoa',
            'principalAndInterest',
            'mortgageInsurance',
            'homeInsurance',
            'utilities',
            'repairs',
            'tax',
            'vacancy',
            'propertyManagement',
            'capEx',
            'estimatedRepairCostToFixProperty',
            'downPayment'
          ]
        }}
      />
    </GridToolbarContainer>
  );
}

export default function DataGridDemo({ rows, columns }) {
  const antDesignClasses = tableStyles();

  return (
    <div style={{ display: 'flex', minHeight: '575px' }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          components={{
            Toolbar: CustomToolbar
          }}
          className={antDesignClasses.root}
          rows={rows}
          rowHeight={70}
          columns={columns}
          pageSize={6}
          disableSelectionOnClick
        />
      </div>
    </div>
  );
}

DataGridDemo.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired
};
