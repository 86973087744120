import * as React from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';

import { useAuth0 } from '../../utils/react-auth0-spa';
import { useAuthToken } from '../../hooks/useAuthToken';
import { useGlobalContext } from '../../context/GlobalContext';

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: '#1976d2',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
`;

const Unsubscribe = (props: any) => {
  const classes = useStyles();
  const {
    loading,
    isAuthenticated,
    user = {},
    loginWithPopup
  }: any = useAuth0();
  const { userContext, updateUserContext }: any = useGlobalContext();
  const [apiIsLoading, setApiIsLoading]: any = React.useState<boolean>(false);
  const authToken = useAuthToken();

  const cancelSubscription = async () => {
    if (!isAuthenticated || !userContext || userContext.user.type !== 'paid') {
      return;
    }
    const bodyParams = {
      userId: user.sub,
      authToken
    };

    try {
      setApiIsLoading(true);
      const response = await fetch('/.netlify/functions/cancelSubscription', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyParams)
      });
      const customer = await response.json();
      if (customer.error) {
        throw Error(customer.error);
      }
      updateUserContext({ user: customer.user });
      setApiIsLoading(false);
      return customer;
    } catch (error) {
      setApiIsLoading(false);
      alert(error);
    }
  };

  if (loading) {
    return <Skeleton height={52} />;
  }

  if (!isAuthenticated) {
    return (
      <Wrapper>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={(): void => loginWithPopup({})}
        >
          Login
        </Button>
      </Wrapper>
    );
  }

  if (isAuthenticated && userContext && userContext.user.type === 'paid') {
    return (
      <Wrapper>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={cancelSubscription}
        >
          Cancel Membership
        </Button>
        {apiIsLoading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </Wrapper>
    );
  }

  // fall back
  return <div></div>;
};

export default Unsubscribe;
