import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 550,
    flexGrow: 1,
    position: 'relative',
    boxShadow: '1px 1px 10px rgba(0,0,0, 0.6)'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  slideWrapper: {
    position: 'relative'
  },
  img: {
    display: 'block',
    maxWidth: 550,
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  button: {
    color: 'white',
    backgroundColor: 'rgba(0,0,0,0.4)',
    minWidth: '35px'
  },
  slider: {
    position: 'absolute',
    top: '50%',
    left: '0',
    right: '0',
    transform: 'translateY(-50%)',
    backgroundColor: 'transparent',
    '& > .MuiMobileStepper-dots': {
      display: 'none'
    }
  }
}));

export default useStyles;
