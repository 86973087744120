import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      background: '#FFFFFF'
    },
    toolbar: {
      width: '90%',
      maxWidth: '1440px',
      margin: '0 auto',
      padding: '.5em 0'
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    noWrap: {
      flexWrap: 'nowrap'
    },
    logo: {
      width: '100%',
      height: '100%'
    },
    navLogo: {
      width: '30px',
      height: 'auto'
    },
    title: {
      flexBasis: '200px'
    },
    link: {
      textDecoration: 'none',
      color: '#000',
      width: '50px',
      height: '50px',
      display: 'flex',
      alignItems: 'flex-end'
    },
    textLink: {
      textDecoration: 'none',
      color: '#000',
      height: '50px',
      display: 'flex',
      alignItems: 'flex-end',
      whiteSpace: 'nowrap'
    },
    logoFont: {
      fontWeight: 700,
      marginLeft: '1.5rem',
      '&:hover': {
        color: theme.palette.primary.main,
        transition: 'ease-in .2s color'
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: '4rem'
      }
    },
    logButtons: {
      display: 'flex',
      alignItems: 'flex-end',
      cursor: 'pointer'
    },
    secondaryLink: {
      textDecoration: 'none',
      color: '#000',
      marginLeft: '1em',
      fontWeight: 300
    },
    mobileCollapse: {
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    desktopLinkHide: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  })
);

export default useStyles;
