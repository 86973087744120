import React from 'react';
import TwoColSection from '../components/TwoColSection/TwoColSection';
import SaveBoardImg from '../public/SaveBoardImg.png';

const ProfitSection = (): JSX.Element => {
  return (
    <div style={{ backgroundColor: '#ededed' }}>
      <TwoColSection
        eyebrow="04"
        title="Keep your cashflow positive properties organized."
        image={{
          src: SaveBoardImg,
          alt: 'calculated listings',
          style: {
            height: 'auto',
            maxWidth: '450px'
          }
        }}
        bodyContent="Categorize your saved properties by creating boards.
        Save to a new or previously existing board, close out and let the search continue"
        textLeft={true}
        showDemo={false}
      />
    </div>
  );
};

export default ProfitSection;
