import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import { Link } from 'react-router-dom';
import LinkMaterial from '@material-ui/core/Link';

import useStyles from '../styles';
import { useAuth0 } from '../../../utils/react-auth0-spa';

const MobileAppBar: React.FC = () => {
  const { isAuthenticated, loginWithRedirect, logout }: any = useAuth0();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogOut = () => {
    handleClose();
    logout({
      returnTo: window.location.origin
    });
  };

  const onLogIn = () => {
    handleClose();
    loginWithRedirect({});
  };

  const navigateToPricing = () => {
    handleClose();
    const el = document.getElementById('pricingSection');
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.mobileCollapse}>
      <MenuIcon fontSize="large" onClick={handleMenu}>
        <HomeIcon />
      </MenuIcon>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
      >
        <Link
          className={classes.textLink}
          to="/#pricing"
          onClick={navigateToPricing}
        >
          <MenuItem>Pricing</MenuItem>
        </Link>
        <Link className={classes.textLink} to="/howitworks">
          <MenuItem>How it works</MenuItem>
        </Link>
        <Link className={classes.textLink} to="/boards">
          <MenuItem>Boards</MenuItem>
        </Link>
        {isAuthenticated && (
          <Link
            className={classes.textLink}
            to="/dashboard"
            onClick={handleClose}
          >
            <MenuItem>Dashboard</MenuItem>
          </Link>
        )}
        {!isAuthenticated && (
          <LinkMaterial className={classes.textLink} onClick={onLogIn}>
            <MenuItem>Log In</MenuItem>
          </LinkMaterial>
        )}
        {isAuthenticated && (
          <LinkMaterial className={classes.textLink} onClick={onLogOut}>
            <MenuItem>Logout</MenuItem>
          </LinkMaterial>
        )}
      </Menu>
    </div>
  );
};

export default MobileAppBar;
