import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray } from 'formik';
import { TextField } from 'formik-material-ui';
import styled from 'styled-components';
import { Add, Close, CreditCard } from '@material-ui/icons';
import { Fab, Button, InputAdornment } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import InputSlider from '../common/InputSlider';
import AutoSave from '../../utils/Formik/AutoSave';
import i18n from '../../i18n';
import {
  UPDATE_PROPERTY_DATA,
  READ_PROPERTY,
  FETCH_USER_BOARDS_WITH_PROPERTY
} from '../../graphql/param_queries';

const ExpensesFormSchema = Yup.object({
  utilities: Yup.number()
    .typeError('utilities must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('utilities is a required field'),
  repairsPercent: Yup.number()
    .typeError('repairsPercent must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('repairsPercent is a required field'),
  vacancyPercent: Yup.number()
    .typeError('vacancyPercent must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('vacancyPercent is a required field'),
  propertyManagementPercent: Yup.number()
    .typeError('propertyManagementPercent must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('propertyManagementPercent is a required field'),
  capExPercent: Yup.number('capExPercent must be a number')
    .typeError('capExPercent must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('capExPercent is a required field'),
  additionalExpensesArr: Yup.array().of(
    Yup.object().shape({
      label: Yup.string()
        .typeError('expenses label is a required field')
        .required('expenses label is a required field'),
      expense: Yup.number()
        .min(0, 'Values must be valid positive numbers')
        .required('expense amount is a required field')
    })
  )
});

const cashAdornment = {
  startAdornment: <InputAdornment position="start">$</InputAdornment>
};

const expenseAdornment = {
  startAdornment: (
    <InputAdornment position="start">
      <CreditCard />
    </InputAdornment>
  )
};

const percentAdornment = {
  startAdornment: <InputAdornment position="start">%</InputAdornment>
};

const generateKey = () => Math.floor(Math.random() * 90000) + 10000;

const FieldCont = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ButtonCont = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

const FlexCont = styled.div`
  display: flex;
  align-items: center;
`;

const ExpensesMaterialUiForm = props => {
  const { homeValue, className } = props;
  const { homeData, id, boardId } = homeValue;
  const { tooltip } = i18n.getDataByLanguage('en');

  const [currHomeValues, setCurrHomeValues] = useState(homeData);

  const [updatePropertyData] = useMutation(UPDATE_PROPERTY_DATA, {
    onCompleted: res => {
      const {
        update_property_by_pk: { data }
      } = res;
      const resHomeData = JSON.parse(data);
      setCurrHomeValues(resHomeData);
    },
    refetchQueries: [
      { query: READ_PROPERTY, variables: { id } },
      { query: FETCH_USER_BOARDS_WITH_PROPERTY, variables: { id: boardId } }
    ]
  });

  const { expenses } = currHomeValues;

  const {
    utilities,
    repairsPercent,
    vacancyPercent,
    propertyManagementPercent,
    capExPercent,
    additionalExpensesArr
  } = expenses;

  const handleOnAutoSave = values => {
    const updatedValues = {
      ...currHomeValues,
      expenses: {
        ...currHomeValues.expenses,
        ...values
      }
    };
    updatePropertyData({
      variables: {
        data: JSON.stringify(updatedValues),
        id
      }
    });
  };

  return (
    <Formik
      initialValues={{
        utilities,
        repairsPercent,
        vacancyPercent,
        propertyManagementPercent,
        capExPercent,
        additionalExpensesArr
      }}
      validationSchema={ExpensesFormSchema}
    >
      {({ values }) => (
        <Form className={className}>
          <FieldCont>
            <Field
              name="utilities"
              label="Utilities"
              placeholder="Utilities"
              type="number"
              inputProps={cashAdornment}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="repairsPercent"
              label="Repairs Percent"
              placeholder="Repairs Percent"
              type="number"
              tooltiptype="custom"
              tooltipcontent={tooltip.repairsPercentage}
              inputProps={percentAdornment}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="vacancyPercent"
              label="Vacancy Percent"
              placeholder="Vacancy Percent"
              type="number"
              tooltiptype="custom"
              tooltipcontent={tooltip.vacancyPercent}
              inputProps={percentAdornment}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="propertyManagementPercent"
              label="Property ManagementPercent"
              placeholder="Property Management Percent"
              type="number"
              tooltiptype="custom"
              tooltipcontent={tooltip.propertyManagementPercent}
              inputProps={percentAdornment}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="capExPercent"
              label="capEx Percent"
              placeholder="capEx Percent"
              type="number"
              tooltiptype="custom"
              tooltipcontent={tooltip.capExPercent}
              inputProps={percentAdornment}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <FieldArray
              name="additionalExpensesArr"
              render={arrayHelpers => (
                <div>
                  {values.additionalExpensesArr &&
                  values.additionalExpensesArr.length > 0 ? (
                    values.additionalExpensesArr.map(
                      (additionalExpensesArr, index) => (
                        <FieldCont key={`${additionalExpensesArr.key}`}>
                          <FlexCont>
                            <Field
                              style={{
                                marginRight: '20px'
                              }}
                              name={`additionalExpensesArr.${index}.label`}
                              label="Expense Type"
                              placeholder="add expense type"
                              inputProps={expenseAdornment}
                              component={TextField}
                            />
                            <Field
                              name={`additionalExpensesArr.${index}.expense`}
                              label="Amount"
                              type="number"
                              placeholder="add expense amount"
                              inputProps={cashAdornment}
                              component={TextField}
                            />
                          </FlexCont>
                          <ButtonCont>
                            <Fab
                              color="primary"
                              aria-label="add"
                              style={{
                                height: '25px',
                                width: '25px',
                                minHeight: '25px',
                                marginRight: '20px'
                              }}
                              onClick={() =>
                                arrayHelpers.push({
                                  label: 'Expense',
                                  expense: 0,
                                  key: generateKey()
                                })
                              }
                            >
                              <Add
                                style={{
                                  height: '15px',
                                  width: '15px'
                                }}
                              />
                            </Fab>
                            <Fab
                              color="secondary"
                              aria-label="remove"
                              style={{
                                height: '25px',
                                width: '25px',
                                minHeight: '25px'
                              }}
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <Close
                                style={{
                                  height: '15px',
                                  width: '15px'
                                }}
                              />
                            </Fab>
                          </ButtonCont>
                        </FieldCont>
                      )
                    )
                  ) : (
                    <div>
                      <Button
                        variant="outlined"
                        size="medium"
                        color="secondary"
                        onClick={() =>
                          arrayHelpers.push({
                            label: 'Expense',
                            expense: 0,
                            key: generateKey()
                          })
                        }
                      >
                        Add Additional Monthly Expenses
                      </Button>
                    </div>
                  )}
                </div>
              )}
            />
          </FieldCont>
          <AutoSave onSave={handleOnAutoSave} values={values} />
        </Form>
      )}
    </Formik>
  );
};

ExpensesMaterialUiForm.propTypes = {
  className: PropTypes.string,
  homeValue: PropTypes.any.isRequired,
  zpid: PropTypes.string.isRequired
};

ExpensesMaterialUiForm.defaultProps = {
  className: ''
};

export default ExpensesMaterialUiForm;
