import React from "react";
import HowItWorksSvg from "../Svgs/HowItWorksSvg";
import PoolSideSvg from "../Svgs/PoolSideSvg";
import ReactPlayer from "../ReactPlayer/ReactPlayer";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  PoolVideo: {
    backgroundColor: "#ededed",
  },

  HowItWorks: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "3%",
    paddingBottom: "3%",
  },
  PoolContainer: {
    display: "grid",
    gridTemplateColumns: "15% 68%",
    marginInline: "10%",
    paddingBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  PoolSide: {
    paddingTop: "35%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  Pool: {
    marginInline: "10%",
  },
}));

const PoolVideo = () => {
  const classes = useStyles();
  return (
    <div className={classes.PoolVideo}>
      <div className={classes.HowItWorks}>
        <HowItWorksSvg />
      </div>
      <div className={classes.PoolContainer}>
        <div className={classes.PoolSide}>
          <PoolSideSvg />
        </div>
        <div className={classes.Pool}>
          <ReactPlayer />
        </div>
      </div>
    </div>
  );
};

export default PoolVideo;
