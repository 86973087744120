import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import { InputAdornment, FormControlLabel } from '@material-ui/core';
import mortgageJs from 'mortgage-js';
import { useMutation } from '@apollo/client';
import InputSlider from '../common/InputSlider';
import CustomSwitch from '../common/CustomSwitch';
import AutoSave from '../../utils/Formik/AutoSave';
import i18n from '../../i18n';
import {
  UPDATE_PROPERTY_DATA,
  READ_PROPERTY,
  FETCH_USER_BOARDS_WITH_PROPERTY
} from '../../graphql/param_queries';

const MortgageFormSchema = Yup.object({
  homePrice: Yup.number()
    .typeError('home price must be a valid number')
    .min(0, 'Values must be valid positive numbers')
    .required('home price is a required field'),
  estimatedRepairCostToFixProperty: Yup.number()
    .typeError('estimated repair cost must be a valid number')
    .min(0, 'Values must be valid positive numbers')
    .required('estimated repair cost is a required field'),
  downPayment: Yup.number()
    .typeError('downPayment must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('downPayment is a required field'),
  downPaymentPercentage: Yup.number()
    .typeError('downPaymentPercentage must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('downPaymentPercentage is a required field'),
  interestRate: Yup.number()
    .typeError('interestRate must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('interestRate is a required field'),
  loanLengthYears: Yup.number()
    .typeError('loanLength must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('loanLength is a required field'),
  taxRate: Yup.number()
    .typeError('taxRate must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('taxRate is a required field'),
  insurance: Yup.number()
    .typeError('insurance must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('insurance is a required field'),
  mortgageInsuranceRate: Yup.number()
    .typeError('mortgageInsuranceRate must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('mortgageInsuranceRate is a required field'),
  additionalPrincipalPayment: Yup.number()
    .typeError('additionalPrincipalPayment must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('additionalPrincipalPayment is a required field'),
  hoa: Yup.number()
    .typeError('hoa must be a number')
    .min(0, 'Values must be valid positive numbers')
    .required('hoa is a required field')
});

const cashAdornment = {
  startAdornment: <InputAdornment position="start">$</InputAdornment>
};

const percentAdornment = {
  startAdornment: <InputAdornment position="start">%</InputAdornment>
};

const FieldCont = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const MortgageMaterialUiForm = props => {
  const { homeValue, className } = props;
  const { id, homeData, boardId } = homeValue;

  const { tooltip } = i18n.getDataByLanguage('en');

  const [currHomeValues, setCurrHomeValues] = useState(homeData);

  const [updatePropertyData] = useMutation(UPDATE_PROPERTY_DATA, {
    onCompleted: res => {
      const {
        update_property_by_pk: { data }
      } = res;
      const resHomeData = JSON.parse(data);
      setCurrHomeValues(resHomeData);
    },
    refetchQueries: [
      { query: READ_PROPERTY, variables: { id } },
      { query: FETCH_USER_BOARDS_WITH_PROPERTY, variables: { id: boardId } }
    ]
  });

  const { mortgageValues } = currHomeValues;

  const {
    hoa,
    homePrice,
    downPaymentPercentage,
    interestRate,
    loanLengthYears,
    taxRate,
    homeInsurance,
    mortgageInsuranceRate,
    mortgageInsuranceEnabled,
    mortgageInsuranceThreshold,
    additionalPrincipalPayment,
    estimatedRepairCostToFixProperty
  } = mortgageValues;

  const updateMortgageValues = values => {
    const interestRate = values.interestRate / 100;
    const loanLengthMonths = values.loanLengthYears * 12;
    const mortgageCalc = mortgageJs.calculatePayment(
      values.homePrice,
      values.downPayment,
      interestRate,
      loanLengthMonths,
      values.taxRate / 100,
      0,
      values.mortgageInsuranceRate / 100,
      values.mortgageInsuranceEnabled,
      mortgageInsuranceThreshold,
      values.additionalPrincipalPayment
    );

    const {
      total,
      principalAndInterest,
      tax,
      mortgageInsurance
    } = mortgageCalc;

    const mortgageValues = {
      ...mortgageCalc,
      ...values,
      paymentSchedule: [],
      loanLengthYears: values.loanLengthYears,
      interestRate,
      tax: Math.floor(tax),
      homeInsurance: Math.floor(values.insurance),
      mortgageInsurance: Math.floor(mortgageInsurance),
      principalAndInterest: Math.floor(principalAndInterest),
      total: Math.floor(total + values.insurance + values.hoa)
    };

    const updatedValues = {
      ...currHomeValues,
      mortgageValues: {
        ...currHomeValues.mortgageValues,
        ...mortgageValues
      }
    };

    return {
      updatedValues,
      mortgageValues
    };
  };

  const handleOnAutoSave = values => {
    const { updatedValues } = updateMortgageValues(values);
    updatePropertyData({
      variables: {
        data: JSON.stringify(updatedValues),
        id
      }
    });
  };

  return (
    <Formik
      initialValues={{
        homePrice,
        estimatedRepairCostToFixProperty,
        downPayment: Number(
          (homePrice * (downPaymentPercentage / 100)).toFixed(2)
        ),
        downPaymentPercentage,
        interestRate: Number((interestRate * 100).toFixed(3)),
        loanLengthYears,
        taxRate,
        insurance: homeInsurance,
        mortgageInsuranceRate,
        mortgageInsuranceEnabled,
        hoa,
        additionalPrincipalPayment
      }}
      validationSchema={MortgageFormSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
      }}
    >
      {({ values }) => (
        <Form className={className}>
          <FieldCont>
            <Field
              name="homePrice"
              label="Home Price"
              placeholder="Home Price"
              type="number"
              tooltiptype="custom"
              tooltipcontent={tooltip.homePrice}
              step={100}
              inputProps={cashAdornment}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="downPayment"
              label="Down Payment"
              placeholder="downPayment"
              type="number"
              tooltiptype="custom"
              tooltipcontent={tooltip.downPayment}
              inputProps={cashAdornment}
              component={InputSlider}
              step={1000}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="downPaymentPercentage"
              label="Down Payment Percent"
              placeholder="DownPayment Percent"
              type="number"
              tooltiptype="custom"
              tooltipcontent={tooltip.downPaymentPercent}
              inputProps={percentAdornment}
              step={1}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="hoa"
              label="Hoa Fee"
              placeholder="Hoa Fee"
              type="number"
              step={1}
              inputProps={cashAdornment}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="interestRate"
              label="Interest Rate"
              placeholder="Interest Rate"
              type="number"
              inputProps={percentAdornment}
              step={0.001}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="loanLengthYears"
              label="Loan length years"
              placeholder="Loan length years"
              type="number"
              tooltiptype="custom"
              tooltipcontent={tooltip.loanLength}
              step={1}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="taxRate"
              label="Tax Rate"
              placeholder="Tax Rate"
              type="number"
              inputProps={percentAdornment}
              step={0.01}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="insurance"
              label="Insurance"
              placeholder="Insurance"
              type="number"
              inputProps={cashAdornment}
              step={1}
              component={InputSlider}
            />
          </FieldCont>
          <FormControlLabel
            control={
              <Field
                checked={values.mortgageInsuranceEnabled}
                label="Mortgage Insurance"
                name="mortgageInsuranceEnabled"
                component={CustomSwitch}
              />
            }
            label="Mortgage Insurance"
          />
          <FieldCont>
            <Field
              name="mortgageInsuranceRate"
              label="Mortgage Insurance Rate"
              placeholder="Mortgage Insurance Rate"
              type="number"
              inputProps={percentAdornment}
              component={InputSlider}
              step={0.01}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="estimatedRepairCostToFixProperty"
              label="Estimated Repair Cost To Fix Property"
              placeholder="Estimated Repair Cost"
              type="number"
              step={100}
              max={20000}
              tooltiptype="custom"
              tooltipcontent={tooltip.repairCost}
              inputProps={cashAdornment}
              component={InputSlider}
            />
          </FieldCont>
          <FieldCont>
            <Field
              name="additionalPrincipalPayment"
              label="Additional Principal Pay"
              placeholder="Additional Principal Pay"
              type="number"
              inputProps={cashAdornment}
              component={InputSlider}
            />
          </FieldCont>
          <AutoSave onSave={handleOnAutoSave} values={values} />
        </Form>
      )}
    </Formik>
  );
};

MortgageMaterialUiForm.propTypes = {
  className: PropTypes.string,
  homeValue: PropTypes.any.isRequired,
  zpid: PropTypes.string.isRequired
};

MortgageMaterialUiForm.defaultProps = {
  className: ''
};

export default MortgageMaterialUiForm;
