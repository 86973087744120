import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { green, red } from '@material-ui/core/colors';
import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    CardHeader,
    Table,
    TableBody,
    TableCell as MuiTableCell,
    TableHead,
    TableRow as MuiTableRow,
    Typography
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-colorschemes';

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: 20px;
  }
`;

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;

  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const GreenText = styled.span`
  color: ${() => green[400]};
  font-size: 16px;
  font-weight: 500;
`;

const RedText = styled.span`
  color: ${() => red[400]};
  font-size: 16px;
  font-weight: 500;
`;

const DoughnutChart = ({ chartData, colorTheme, color, titleText, percentage }) => {
    const {
        labels,
        table,
        data,
        total
    } = chartData;

    const colors = color ? {
        backgroundColor: color
    } : {};

    const dataValues  = {
        labels,
        datasets: [
            {
                data,
                ...colors,
                borderWidth: 5
            }
        ]
    };

    const options = {
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        cutoutPercentage: 80
    };

    return (
        <Card mb={3}>
            <CardHeader
                title={titleText}
            />

            <CardContent>
                <ChartWrapper>
                    <DoughnutInner variant="h4">
                        <Typography variant="h4">{percentage ? `%${total}` : `$${total}`}</Typography>
                        <Typography variant="caption">{titleText}</Typography>
                    </DoughnutInner>
                    <Doughnut options={{
                        ...options,
                        plugins: {
                            colorschemes: {
                                scheme: colorTheme
                            }
                        }

                    }} data={dataValues} />
                </ChartWrapper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle1">Source</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="subtitle1">Value</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table.sort((a, b) => b.value - a.value).map(({ type, value, expense }) => (
                            <TableRow key={type}>
                                <TableCell component="th" scope="row">
                                    <Typography variant="subtitle1">{type}</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    {expense ? <RedText>$ {value}</RedText>  : <GreenText>$ {value}</GreenText> }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

DoughnutChart.propTypes = {
    chartData: PropTypes.any.isRequired,
    colorTheme: PropTypes.string.isRequired,
    titleText: PropTypes.string.isRequired,
    color: PropTypes.array,
    percentage: PropTypes.bool
};

DoughnutChart.defaultProps = {
    color: null,
    percentage: false
};

export default DoughnutChart;
