import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import {
  AccountBalance as AccountBalanceIcon,
  HomeWork as HomeWorkIcon,
  Payment as PaymentIcon,
  AttachMoney as AttachMoneyIcon,
  Dashboard as DashboardIcon
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import useStyles from './styles';

const TabNavigation = ({ handleChange, value }) => {
  const classes = useStyles();
  const a11yProps = index => ({
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  });

  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs"
      className={classes.tabs}
    >
      <StyledTab
        label="Income"
        icon={<AccountBalanceIcon className={classes.icon} />}
        {...a11yProps(0)}
      />
      <StyledTab
        label="Mortgage"
        icon={<HomeWorkIcon className={classes.icon} />}
        {...a11yProps(1)}
      />
      <StyledTab
        label="Expenses"
        icon={<PaymentIcon className={classes.icon} />}
        {...a11yProps(2)}
      />
      <StyledTab
        label="Closing Cost"
        icon={<AttachMoneyIcon className={classes.icon} />}
        {...a11yProps(3)}
      />
      <StyledTab
        label="Dashboard"
        icon={<DashboardIcon className={classes.icon} />}
        {...a11yProps(4)}
      />
    </Tabs>
  );
};

const StyledTab = withStyles({
  root: {
    minWidth: 'auto',
    padding: '20px',
    color: '#868f99'
  },
  selected: {
    color: '#00c807'
  }
})(Tab);

TabNavigation.propTypes = {
  children: PropTypes.any.isRequired,
  handleChange: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default TabNavigation;
