/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import * as React from 'react';
import {
  TrendingUpRounded,
  TrendingDown,
  TrendingFlat
} from '@material-ui/icons';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import IconButton from '@material-ui/core/IconButton';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CountUp from 'react-countup';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import { getHomeCashflowCalculations } from '../../utils/selectors/zillowThumbnailSelectors';
import StatusMenu from '../StatusMenu';
import {
  FETCH_USER_BOARDS_WITH_PROPERTY,
  DELETE_PROPERTY_BY_ID
} from '../../graphql/param_queries';

export const renderTrendLine = value => {
  if (value < -50) {
    return {
      IconComponent: props => <TrendingDown {...props} />,
      color: '#FF0000',
      negative: true,
      neutral: false,
      positive: false
    };
  } else if (value >= -50 && value <= 50) {
    return {
      IconComponent: props => <TrendingFlat {...props} />,
      color: '#F2B33F',
      negative: false,
      neutral: true,
      positive: false
    };
  }
  return {
    IconComponent: props => <TrendingUpRounded {...props} />,
    color: '#57CA62',
    negative: false,
    neutral: false,
    positive: true
  };
};

const columns = [
  { field: 'id', headerName: 'ID', flex: 1, hide: true },
  {
    field: 'image',
    headerName: 'Property',
    align: 'center',
    hideSortIcons: true,
    disableColumnMenu: true,
    width: 100,
    headerAlign: 'center',
    renderCell: ({ value }) => (
      <Avatar
        variant="square"
        style={{
          borderRadius: '2px',
          width: 80,
          height: 'auto'
        }}
        alt="home thumbnail"
        src={value}
      />
    )
  },
  {
    headerAlign: 'center',
    field: 'address',
    headerName: 'Address',
    align: 'center',
    flex: 1
  },
  {
    field: 'price',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    headerName: 'Home Price',
    type: 'number',
    renderCell: ({ value }) => (
      <CountUp
        start={value}
        end={value}
        delay={0}
        duration={0}
        separator=","
        prefix="$"
      >
        {({ countUpRef }) => (
          <div
            style={{
              fontSize: 16,
              fontWeight: 700
            }}
          >
            <span ref={countUpRef} />
          </div>
        )}
      </CountUp>
    )
  },
  {
    field: 'cashflow',
    flex: 1,
    headerName: 'Cashflow',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    cellClassName: ({ value }) =>
      clsx('super-app', {
        negative: renderTrendLine(value).negative,
        positive: renderTrendLine(value).positive,
        neutral: renderTrendLine(value).neutral
      }),
    renderCell: ({ value }) => (
      <CountUp
        start={value}
        end={value}
        delay={0}
        duration={0}
        separator=","
        decimals={2}
        prefix="$"
      >
        {({ countUpRef }) => {
          const { IconComponent, color } = renderTrendLine(value);
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 16,
                fontWeight: 700,
                color
              }}
            >
              <IconComponent
                style={{
                  margin: '0 10px'
                }}
              />
              <span ref={countUpRef} />
            </div>
          );
        }}
      </CountUp>
    )
  },
  {
    field: 'coc',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    headerName: 'Cash On Cash',
    type: 'number',
    renderCell: ({ value }) => (
      <CountUp
        start={value}
        end={value}
        delay={0}
        duration={0}
        decimals={2}
        suffix="%"
      >
        {({ countUpRef }) => (
          <div
            style={{
              fontSize: 16,
              fontWeight: 700
            }}
          >
            <span ref={countUpRef} />
          </div>
        )}
      </CountUp>
    )
  },
  {
    field: 'status',
    headerAlign: 'center',
    align: 'center',
    headerName: 'Status',
    width: 190,
    renderCell: ({ value, row }) => {
      const { id = '' } = row;
      return <StatusMenu currentStatus={value} propertyId={id} />;
    }
  },
  {
    field: 'edit',
    headerAlign: 'center',
    align: 'center',
    headerName: 'Edit',
    hideSortIcons: true,
    disableColumnMenu: true,
    width: 80,
    renderCell: function EditButton({ id }) {
      const { pathname } = useLocation();
      const history = useHistory();
      return (
        <IconButton
          onClick={() => {
            history.push(`${pathname}/property/${id}`);
          }}
          color="primary"
          aria-label="edit home"
        >
          <EditRoundedIcon />
        </IconButton>
      );
    }
  },
  {
    field: 'delete',
    headerAlign: 'center',
    align: 'center',
    headerName: 'Delete',
    hideSortIcons: true,
    disableColumnMenu: true,
    width: 80,
    renderCell: function RemoveButton(data) {
      const { value } = data;
      const { id, boardId } = value;
      const [deleteProperty] = useMutation(DELETE_PROPERTY_BY_ID, {
        onCompleted: () => {
          window.location.reload(); // temp solution to cause app to re-render
        },
        refetchQueries: [
          {
            query: FETCH_USER_BOARDS_WITH_PROPERTY,
            variables: { id: boardId }
          }
        ]
      });

      const handleDelete = () => {
        deleteProperty({
          variables: {
            id
          }
        });
      };
      return (
        <IconButton
          onClick={handleDelete}
          color="primary"
          aria-label="remove home"
        >
          <DeleteOutlineRoundedIcon />
        </IconButton>
      );
    }
  },
  {
    field: 'yearlyCashflow',
    headerName: 'Yearly Cashflow',
    flex: 1,
    hide: true
  },
  {
    field: 'totalIncome',
    headerName: 'Monthly Total Income',
    flex: 1,
    hide: true
  },
  {
    field: 'totalInvestment',
    headerName: 'Total Investment',
    flex: 1,
    hide: true
  },
  {
    field: 'totalExpensesAmount',
    headerName: 'Monthly Total Expenses',
    flex: 1,
    hide: true
  },
  {
    field: 'mortgageTotal',
    headerName: 'Monthly Total Mortgage',
    flex: 1,
    hide: true
  },
  { field: 'rentEstimate', headerName: 'Rental Estimate', flex: 1, hide: true },
  { field: 'hoa', headerName: 'Monthly Hoa Fees', flex: 1, hide: true },
  {
    field: 'principalAndInterest',
    headerName: 'Monthly Principal & Interest',
    flex: 1,
    hide: true
  },
  {
    field: 'mortgageInsurance',
    headerName: 'Monthly Mortgage Insurance',
    flex: 1,
    hide: true
  },
  {
    field: 'homeInsurance',
    headerName: 'Monthly Home Insurance',
    flex: 1,
    hide: true
  },
  {
    field: 'utilities',
    headerName: 'Monthly Utilities Expense',
    flex: 1,
    hide: true
  },
  {
    field: 'repairs',
    headerName: 'Monthly Repairs Expense',
    flex: 1,
    hide: true
  },
  {
    field: 'tax',
    headerName: 'Monthly Property Tax Expense',
    flex: 1,
    hide: true
  },
  {
    field: 'vacancy',
    headerName: 'Monthly Vacancy Expense',
    flex: 1,
    hide: true
  },
  {
    field: 'propertyManagement',
    headerName: 'Monthly Property Management Expense',
    flex: 1,
    hide: true
  },
  {
    field: 'capEx',
    headerName: 'Monthly Capital Expenditures Expense',
    flex: 1,
    hide: true
  },
  {
    field: 'downPayment',
    headerName: 'Down Payment Total',
    flex: 1,
    hide: true
  },
  {
    field: 'estimatedRepairCostToFixProperty',
    headerName: 'Estimated Repair Cost To Fix Property',
    flex: 1,
    hide: true
  }
];

export const formatHomesTableData = (homeValues, boardId) => {
  const rows = homeValues.map(({ data, id, status }) => {
    const home = JSON.parse(data);
    const {
      cashflow,
      totalCashonCash,
      imageLink,
      streetAddress,
      yearlyCashflow,
      totalIncome,
      totalInvestment,
      totalExpensesAmount,
      mortgageTotal,
      rentEstimate,
      hoa,
      principalAndInterest,
      mortgageInsurance,
      downPayment,
      homeInsurance,
      estimatedRepairCostToFixProperty,
      utilities,
      repairs,
      tax,
      vacancy,
      propertyManagement,
      capEx
    } = getHomeCashflowCalculations(home);

    const {
      mortgageValues: { homePrice }
    } = home;

    return {
      id,
      address: streetAddress,
      price: homePrice,
      cashflow,
      coc: totalCashonCash,
      status,
      image: imageLink,
      yearlyCashflow,
      totalIncome,
      totalInvestment,
      totalExpensesAmount,
      mortgageTotal,
      rentEstimate,
      hoa,
      principalAndInterest,
      mortgageInsurance,
      downPayment,
      homeInsurance,
      estimatedRepairCostToFixProperty,
      utilities,
      repairs,
      tax,
      vacancy,
      propertyManagement,
      capEx,
      edit: {
        id,
        boardId
      },
      delete: {
        id,
        boardId
      }
    };
  });

  return {
    rows,
    columns
  };
};
