import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FETCH_USER_BOARDS_WITH_PROPERTY } from '../graphql/param_queries';
import { formatHomesTableData } from '../components/PropertyTable/helpers';
import PropertyNav from '../components/PropertyNav';
import PropertyTable from '../components/PropertyTable';
import Board from '../components/Board/Board';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      overflow: 'auto',
      marginTop: '13px'
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary
    }
  })
);

export const Album = () => {
  const classes = useStyles();
  const { id: boardId }: any = useParams();

  const [dataTable, setDataTable]: any = useState(null);

  const [fetchUserBoardsProperties, { data, loading }] = useLazyQuery(
    FETCH_USER_BOARDS_WITH_PROPERTY,
    {
      variables: { id: boardId }
    }
  );

  React.useEffect(() => {
    if (boardId) {
      fetchUserBoardsProperties();
    }
    return;
  }, [boardId, fetchUserBoardsProperties]);

  React.useEffect(() => {
    if (
      data &&
      data.boards_by_pk &&
      data.boards_by_pk.properties &&
      data.boards_by_pk.properties.length > 0
    ) {
      const formattedDataTable = formatHomesTableData(
        data.boards_by_pk.properties,
        boardId
      );
      setDataTable(formattedDataTable);
    }
  }, [data]);

  return (
    <Container maxWidth="lg" className={classes.root}>
      {loading && <LinearProgress />}
      {dataTable && !loading ? (
        <PropertyNav
          componetsArray={[
            {
              label: 'List',
              component: function propertyFunc() {
                return (
                  <PropertyTable
                    rows={dataTable.rows}
                    columns={dataTable.columns}
                  />
                );
              }
            },
            {
              label: 'Action Board',
              component: function boardFunc() {
                return <Board properties={dataTable.rows} />;
              }
            }
          ]}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 25
          }}
        >
          No properties found. Please add some to this board via the chrome
          extension.
        </div>
      )}
    </Container>
  );
};

export default Album;
