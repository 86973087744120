import { useState, useEffect } from 'react';
import { useAuth0 } from '../utils/react-auth0-spa';

export const useAuthToken = () => {
  const { getIdTokenClaims, loading, isAuthenticated }: any = useAuth0();
  const [token, setToken]: any = useState<any>();
  useEffect(() => {
    const callAPI = async () => {
      if (getIdTokenClaims) {
        try {
          const tempToken = await getIdTokenClaims();
          setToken(tempToken.__raw);
        } catch (err) {
          console.log(err);
        }
      }
    };
    if (!loading && !token && isAuthenticated && getIdTokenClaims) {
      callAPI();
    }
  }, [loading, getIdTokenClaims, token, isAuthenticated]);

  return token;
};
