import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import {
  InputAdornment,
  Typography,
  Accordion,
  List,
  ListItem,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core';
import { useMutation } from '@apollo/client';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputSlider from '../common/InputSlider';
import closingCostConstants from './constants';
import AutoSave from '../../utils/Formik/AutoSave';
import i18n from '../../i18n';
import {
  UPDATE_PROPERTY_DATA,
  READ_PROPERTY,
  FETCH_USER_BOARDS_WITH_PROPERTY
} from '../../graphql/param_queries';

const percentAdornment = {
  startAdornment: <InputAdornment position="start">%</InputAdornment>
};

const FieldCont = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const DislayTextField = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const CostWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const ClosingCostWrapper = styled.div`
  margin: 12px 0;
  display: flex;
  flex-grow: 1;
`;

const CloasingCostMaterialUiForm = props => {
  const { homeValue, className } = props;
  const { id, homeData, boardId } = homeValue;
  const { tooltip } = i18n.getDataByLanguage('en');

  const [currHomeValues, setCurrHomeValues] = useState(homeData);

  const [updatePropertyData] = useMutation(UPDATE_PROPERTY_DATA, {
    onCompleted: res => {
      const {
        update_property_by_pk: { data }
      } = res;
      const resHomeData = JSON.parse(data);
      setCurrHomeValues(resHomeData);
    },
    refetchQueries: [
      { query: READ_PROPERTY, variables: { id } },
      { query: FETCH_USER_BOARDS_WITH_PROPERTY, variables: { id: boardId } }
    ]
  });

  const { mortgageValues, cashOnCash } = currHomeValues;

  const { points, originatinFee } = cashOnCash;

  const {
    homePrice,
    downPaymentPercentage,
    interestRate,
    taxRate
  } = mortgageValues;

  const loanAmount = homePrice - homePrice * (downPaymentPercentage / 100);

  const calculateLenderClosingCost = values => {
    const { originatinFee, points } = values;
    const totalOrigination = closingCostConstants.originationFee(
      loanAmount,
      originatinFee
    );
    const totalDiscountPoints = closingCostConstants.discountPointsFee(
      loanAmount,
      points
    );
    const {
      adminFee,
      underWritingFee,
      docPrepFee,
      commitmentFee
    } = closingCostConstants;
    const totalLenderClosingCost =
      totalOrigination +
      totalDiscountPoints +
      adminFee(loanAmount) +
      underWritingFee +
      docPrepFee +
      commitmentFee;
    return {
      totalLenderClosingCost,
      lenderCostBreakDown: [
        {
          label: 'Origination',
          total: totalOrigination
        },
        {
          label: 'Points',
          total: totalDiscountPoints
        },
        {
          label: 'Admin',
          total: adminFee(loanAmount)
        },
        {
          label: 'Underwriting',
          total: underWritingFee
        },
        {
          label: 'Doc Prep Fee',
          total: docPrepFee
        },
        {
          label: 'Commitment',
          total: commitmentFee
        }
      ]
    };
  };

  const calculateThirdPartyClosingCost = () => {
    const {
      notaryFee,
      creditReportFee,
      courierFee,
      taxServiceFee,
      wireTransferFee,
      lendersInspectionFee,
      recordingFee
    } = closingCostConstants;
    const totalAppraisalCost = closingCostConstants.appraisalFee(loanAmount);
    const totalFloodCertFee = closingCostConstants.floodCertFee(loanAmount);
    const totalTitleInsuranceFee = closingCostConstants.titleInsuranceFee(
      loanAmount
    );
    const totalClosingEscrowFee = closingCostConstants.closingEscrowFee(
      loanAmount
    );
    const totalThirdPartyClosingCost =
      creditReportFee +
      totalAppraisalCost +
      courierFee +
      totalFloodCertFee +
      taxServiceFee +
      wireTransferFee +
      lendersInspectionFee +
      recordingFee +
      notaryFee +
      totalTitleInsuranceFee +
      totalClosingEscrowFee;
    return {
      totalThirdPartyClosingCost,
      thirdPartyCostBreakDown: [
        {
          label: 'Credit Report',
          total: creditReportFee
        },
        {
          label: 'Appraisal',
          total: totalAppraisalCost
        },
        {
          label: 'Courier',
          total: courierFee
        },
        {
          label: 'Flood Certification',
          total: totalFloodCertFee
        },
        {
          label: 'Tax Service',
          total: taxServiceFee
        },
        {
          label: 'Wire Transfer',
          total: wireTransferFee
        },
        {
          label: 'Lender Inspection',
          total: lendersInspectionFee
        },
        {
          label: 'Recording',
          total: recordingFee
        },
        {
          label: 'Notary',
          total: notaryFee
        },
        {
          label: 'Title Insurance',
          total: totalTitleInsuranceFee
        },
        {
          label: 'Closing/Escrow',
          total: totalClosingEscrowFee
        }
      ]
    };
  };

  const calculateSettlementClosingCost = () => {
    const prePaidInterest = closingCostConstants.prePaidInterestFee(
      loanAmount,
      interestRate
    );
    const homeownersInsurance = closingCostConstants.homeownersInsuranceFee(
      loanAmount
    );
    const mortgageInsImpound = closingCostConstants.mortgageInsImpoundFee(
      loanAmount
    );
    const propertyTaxesImpound = closingCostConstants.propertyTaxesImpoundFee(
      loanAmount,
      taxRate / 100
    );
    const totalSettlementClosingCost =
      prePaidInterest +
      homeownersInsurance +
      mortgageInsImpound +
      propertyTaxesImpound;
    return {
      totalSettlementClosingCost,
      settlementCostBreakDown: [
        {
          label: 'Pre-Paid Interest',
          total: prePaidInterest
        },
        {
          label: 'Homeowners Insurance',
          total: homeownersInsurance
        },
        {
          label: 'Mortgage Insurance Impound',
          total: mortgageInsImpound
        },
        {
          label: 'Property Taxes Impound',
          total: propertyTaxesImpound
        }
      ]
    };
  };

  const renderBreakDown = (breakDownTitle, closingCost, breakDownValues) => {
    return (
      <CostWrapper>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <DislayTextField>
              <Typography variant="subtitle1" component="h6">
                {breakDownTitle}
              </Typography>
              <Typography variant="subtitle1" component="h2">
                $ {Math.round(closingCost)}
              </Typography>
            </DislayTextField>
          </AccordionSummary>
          <AccordionDetails>
            <List style={{ width: '100%' }}>
              {breakDownValues.map(({ label, total }) => {
                return (
                  <ListItem key={label}>
                    <DislayTextField>
                      <Typography>{label}</Typography>
                      <Typography>$ {Math.round(total)}</Typography>
                    </DislayTextField>
                  </ListItem>
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion>
      </CostWrapper>
    );
  };

  const handleOnAutoSave = values => {
    const { points, originatinFee } = values;
    const { totalLenderClosingCost } = calculateLenderClosingCost(values);
    const { totalThirdPartyClosingCost } = calculateThirdPartyClosingCost(
      values
    );
    const { totalSettlementClosingCost } = calculateSettlementClosingCost(
      values
    );
    const totalClosingCost =
      totalLenderClosingCost +
      totalThirdPartyClosingCost +
      totalSettlementClosingCost;

    const updatedValues = {
      ...currHomeValues,
      cashOnCash: {
        ...currHomeValues.cashOnCash,
        points,
        originatinFee,
        closingCost: totalClosingCost
      }
    };

    updatePropertyData({
      variables: {
        data: JSON.stringify(updatedValues),
        id
      }
    });
  };

  return (
    <Formik
      initialValues={{
        points,
        originatinFee
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
      }}
    >
      {({ values }) => {
        const {
          totalLenderClosingCost,
          lenderCostBreakDown
        } = calculateLenderClosingCost(values);
        const {
          totalThirdPartyClosingCost,
          thirdPartyCostBreakDown
        } = calculateThirdPartyClosingCost(values);
        const {
          totalSettlementClosingCost,
          settlementCostBreakDown
        } = calculateSettlementClosingCost(values);
        const totalClosingCost = Math.round(
          totalLenderClosingCost +
            totalThirdPartyClosingCost +
            totalSettlementClosingCost
        );
        return (
          <Form className={className}>
            <FieldCont>
              <DislayTextField>
                <Typography variant="subtitle1" component="h6">
                  Loan Amount
                </Typography>
                <Typography variant="subtitle1" component="h2">
                  $ {loanAmount}
                </Typography>
              </DislayTextField>
            </FieldCont>
            <FieldCont>
              <Field
                name="originatinFee"
                label="Lender Origination Fee"
                placeholder="Lender Origination Fee"
                type="number"
                inputProps={percentAdornment}
                step={0.05}
                max={10}
                component={InputSlider}
              />
            </FieldCont>
            <FieldCont>
              <Field
                name="points"
                label="Points"
                placeholder="Points"
                type="number"
                tooltiptype="custom"
                tooltipcontent={tooltip.points}
                step={0.05}
                max={10}
                component={InputSlider}
              />
            </FieldCont>

            <FieldCont>
              <ClosingCostWrapper>
                <DislayTextField>
                  <Typography variant="subtitle1" component="h6">
                    Total Closing Cost
                  </Typography>
                  <Typography variant="subtitle1" component="h2">
                    $ {totalClosingCost}
                  </Typography>
                </DislayTextField>
              </ClosingCostWrapper>
            </FieldCont>
            <FieldCont>
              {renderBreakDown(
                'Lender Closing Cost',
                totalLenderClosingCost,
                lenderCostBreakDown
              )}
            </FieldCont>
            <FieldCont>
              {renderBreakDown(
                'Third Party Closing Cost',
                totalThirdPartyClosingCost,
                thirdPartyCostBreakDown
              )}
            </FieldCont>

            <FieldCont>
              {renderBreakDown(
                'Settlement Closing Cost',
                totalSettlementClosingCost,
                settlementCostBreakDown
              )}
            </FieldCont>

            <AutoSave onSave={handleOnAutoSave} values={values} />
          </Form>
        );
      }}
    </Formik>
  );
};

CloasingCostMaterialUiForm.propTypes = {
  className: PropTypes.string,
  homeValue: PropTypes.any.isRequired
};

CloasingCostMaterialUiForm.defaultProps = {
  className: ''
};

export default CloasingCostMaterialUiForm;
