import React from 'react';
import PropTypes from 'prop-types';
import ClosingCostForm from '../../ClosingCostForm';
import DoughnutChart from '../../DoughnutChart';
import TabPanel from '../TabPanel';
import useStyles from '../styles';

const ClosingCostPanel = ({
  value,
  homeValue,
  renderCashFlowChart,
  cashOnCashData
}) => {
  const classes = useStyles();

  return (
    <TabPanel className={classes.tabpanel} value={value} index={3}>
      <div className={classes.flexCont}>
        <ClosingCostForm className={classes.form} homeValue={homeValue} />
        <div className={classes.charts}>
          <div className={classes.chartsPanel}>
            <div className={classes.chartContainer}>
              <DoughnutChart
                titleText="Cash on Cash"
                colorTheme="tableau.HueCircle19"
                chartData={cashOnCashData}
                percentage
              />
            </div>
            <div className={classes.chartContainer}>
              {renderCashFlowChart()}
            </div>
          </div>
        </div>
      </div>
    </TabPanel>
  );
};

ClosingCostPanel.propTypes = {
  value: PropTypes.any.isRequired,
  homeValue: PropTypes.any.isRequired,
  renderCashFlowChart: PropTypes.any.isRequired,
  cashOnCashData: PropTypes.any.isRequired
};

export default ClosingCostPanel;
