import * as React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import PricingSection from '../sections/PricingSection';
import { Grid, Typography } from '@material-ui/core';

const Header = styled.div`
  padding: 24px 0;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      minHeight: '100vh',
      marginBottom: '4em'
    }
  })
);

function Pricing() {
  const classes = useStyles();

  const sendEmail = async () => {
    try {
      const response = await fetch('/.netlify/functions/getCampaigns', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      });
      const json = await response.json();
    } catch (err) {
      throw Error(err);
    }
  };

  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
    >
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.wrapper}
      >
        <Header>
          <Typography variant="h1" gutterBottom align="center">
            Join today
          </Typography>
          <Typography variant="h2" gutterBottom align="center">
            Find you next rental property.
          </Typography>
        </Header>
        <PricingSection />
      </Grid>
    </motion.div>
  );
}

export default Pricing;
