const closingCostConstants = {
    originationFee: (loanAmount,origination) => loanAmount * (origination / 100),
    adminFee: (loanAmount) => loanAmount < 251000 ? 650 : 900,
    discountPointsFee: (loanAmount, points) => loanAmount * (points / 100),
    underWritingFee: 0,
    docPrepFee: 0,
    commitmentFee: 0,
    creditReportFee: 25,
    appraisalFee: (loanAmount) => (loanAmount < 750000) ? 425 : 550,
    courierFee: 55,
    floodCertFee: (loanAmount) => (loanAmount < 750000) ? 15 : 35,
    taxServiceFee: 75,
    wireTransferFee: 35,
    lendersInspectionFee: 0,
    recordingFee: 125,
    notaryFee: 225,
    titleInsuranceFee: (loanAmount) => loanAmount * .005,
    closingEscrowFee: (loanAmount) => (loanAmount * .002) + 250,
    prePaidInterestFee: ( loanAmount, interestRate ) =>  ( ( loanAmount * interestRate ) / 365 ) * 15,
    homeownersInsuranceFee: (loanAmount) => {
        const cost = ( loanAmount < 750000 ) ? 900 : 1250;
        return ( loanAmount < 350000 ) ? 550 : cost;
    },
    mortgageInsImpoundFee: loanAmount => ( loanAmount * .0075 ) / 12,
    propertyTaxesImpoundFee: (loanAmount, propertyTaxes) => ( ( loanAmount * propertyTaxes ) / 12 ) * 4
};

export default closingCostConstants;
