import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { ButtonProps } from '@material-ui/core/Button';

import useStyles from './pricingSectionStyles';
import PricingCard from '../components/Cards/PricingCard/PricingCard';

const freeCard: {
  title: string;
  subTitle: string;
  button: {
    color?: ButtonProps['color'];
    variant?: ButtonProps['variant'];
    text?: string;
    payment?: boolean;
  };
  list: string[];
} = {
  title: 'Free',
  subTitle: '$0',
  button: {
    variant: 'outlined',
    color: 'primary',
    text: 'Sign up - free'
  },
  list: [
    'Calculate single property at a time',
    'Unlimited Calculations',
    'Edit values on every home',
    'Chat support'
  ]
};

const paidCard: {
  title: string;
  subTitle: string;
  button: {
    color?: ButtonProps['color'];
    variant?: ButtonProps['variant'];
    text?: string;
    payment?: boolean;
  };
  list: string[];
} = {
  title: 'Paid',
  subTitle: '$10/mo',
  button: {
    payment: true
  },
  list: [
    'Unlimited property calculations',
    'Unlimited saved properties',
    'Analyze 50+ properties with one click',
    'Export properties to Excel',
    'Detailed Dashboards',
    'Unlimited use of Action Board',
    'Edit values on every home',
    'Organize, track and collect properties',
    'Chat support'
  ]
};

const PricingSection = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div id="pricingSection" style={{ backgroundColor: '#ececec' }}>
      <div className={classes.root}>
        <Grid container spacing={3} justifyContent="center">
          <Grid className={classes.titleGrid} item xs={12}>
            <Typography
              className={classes.sectionTitle}
              variant="h3"
              align="center"
            >
              Pricing to suit all investors.
            </Typography>
            <Typography
              className={classes.sectionSubTitle}
              variant="subtitle1"
              align="center"
            >
              Get all the information you need at a glance
            </Typography>
          </Grid>
          <Grid item alignItems="center" xs={12} sm={6} md={12}>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="center"
            >
              <PricingCard {...freeCard} cssClass={classes.cardWrapper} />
              <PricingCard {...paidCard} cssClass={classes.paidCard} />
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PricingSection;
