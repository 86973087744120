import React from 'react';
import PropTypes from 'prop-types';
import { isEqual, debounce } from 'lodash';
import { connect } from 'formik';

class FormikAutoSave extends React.Component {
  save = debounce(() => {
    const { formik, onSave, values } = this.props;

    if (!formik.isValid) {
      return;
    }
    this.setState({ isSaving: true, saveError: undefined }, () => {
      // eslint-disable-next-line no-undef
      Promise.resolve(onSave(values)).then(
        () => this.setState({ isSaving: false, lastSaved: new Date() }),
        saveError => this.setState({ isSaving: false, saveError })
      );
    });
  }, 100);

  onChange = debounce(() => {
    const { formik, onChange, values } = this.props;
    if (!formik.isValid) {
      return;
    }
    if (onChange) {
      onChange(values);
    }
  }, 100);

  constructor(props) {
    super(props);
    this.state = {
      isSaving: false
    };
  }

  componentDidUpdate(prevProps) {
    const { values } = this.props;
    if (!isEqual(prevProps.values, values)) {
      this.save();
      this.onChange();
    }
  }

  render() {
    const { render } = this.props;
    return render ? render(this.state) : null;
  }
}

export default connect(FormikAutoSave);

FormikAutoSave.propTypes = {
  formik: PropTypes.shape({
    isValid: PropTypes.bool
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  values: PropTypes.shape({}),
  render: PropTypes.func
};

FormikAutoSave.defaultProps = {
  render: null,
  onChange: null,
  values: {}
};
