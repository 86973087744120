import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import React from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import history from './utils/history';
import maTheme from './theme';
import routes from './Routes';
import './styles.css';
import { useAuthToken } from './hooks/useAuthToken';
import { GlobalContextProvider } from './context/GlobalContext';

export default function ApolloComponent(): React.ReactElement {
  const cache = new InMemoryCache();
  const authToken = useAuthToken();

  const authHeader = {
    'content-type': 'application/json',
    authorization: authToken ? `Bearer ${authToken}` : ''
  };

  const publicHeader = {
    'content-type': 'application/json',
    'x-hasura-hasura-unauthorized-role': 'public'
  };

  const client = new ApolloClient({
    cache,
    connectToDevTools: true,
    uri: 'https://big-stingray-33.hasura.app/v1/graphql',
    headers: authToken ? authHeader : publicHeader
  });
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={maTheme[3]}>
        <GlobalContextProvider>
          <Router history={history}>{routes}</Router>
        </GlobalContextProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}
