import { gql } from '@apollo/client';

export const FETCH_USER = gql`
  query getUser($id: String!) {
    __typename
    users(where: { id: { _eq: $id } }) {
      id
      type
      email
      membershipEndDate
      calculationUsage
    }
  }
`;

export const FETCH_USER_BOARDS = gql`
  query user_boards($id: String!) {
    users_by_pk(id: $id) {
      boards {
        name
        id
      }
    }
  }
`;

export const DELETE_BOARD_BY_ID = gql`
  mutation deleteBoard($boardId: uuid!) {
    delete_boards_by_pk(id: $boardId) {
      name
    }
  }
`;

export const READ_PROPERTY = gql`
  query ReadProperty($id: uuid!) {
    property_by_pk(id: $id) {
      data
      board_id
    }
  }
`;

export const DELETE_PROPERTY_BY_ID = gql`
  mutation deleteProperty($id: uuid!) {
    delete_property_by_pk(id: $id) {
      id
    }
  }
`;

export const FETCH_USER_BOARDS_WITH_PROPERTY = gql`
  query boards_by_pk($id: uuid!) {
    boards_by_pk(id: $id) {
      properties {
        id
        status
        data
        home_id
      }
      name
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($id: String!, $type: String!, $stripeID: String) {
    update_users(
      where: { id: { _eq: $id } }
      _set: { type: $type, stripeID: $stripeID }
    ) {
      returning {
        email
        id
        type
        stripeID
      }
    }
  }
`;

export const UPDATE_PROPERTY_DATA = gql`
  mutation MyMutation($id: uuid!, $data: jsonb!) {
    update_property_by_pk(pk_columns: { id: $id }, _set: { data: $data }) {
      data
    }
  }
`;

export const UPDATE_PROPERTIES = gql`
  mutation insert_new_properties($properties: [property_insert_input!]!) {
    __typename
    insert_property(objects: $properties) {
      affected_rows
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation insert_new_campaign(
    $properties: [property_insert_input!]!
    $campaignId: uuid!
    $zipcode: Int!
    $priceMax: Int
    $priceMin: Int
    $emailed: Int!
    $status: String!
    $userId: String!
  ) {
    __typename
    insert_campaigns(
      objects: {
        emailed: $emailed
        id: $campaignId
        user_id: $userId
        status: $status
        zipcode: $zipcode
        priceMax: $priceMax
        priceMin: $priceMin
      }
    ) {
      affected_rows
    }
    insert_property(objects: $properties) {
      affected_rows
    }
  }
`;

export const INSERT_NEW_CAMPAIGN = gql`
  mutation insert_new_campaign(
    $properties: [property_insert_input!]!
    $campaignId: uuid!
    $zipcode: Int!
    $priceMax: Int
    $priceMin: Int
    $emailed: Int!
    $status: String!
    $userId: String!
    $capExPercent: Int!
    $downPaymentPercentage: Int!
    $interestRate: float8!
    $loanLengthYears: Int!
    $propertyManagementPercent: Int!
    $repairsPercent: Int!
    $vacancyPercent: Int!
    $homeInsurance: Int!
    $propertyTaxRate: float8!
    $fiveBedRoom: Int!
    $fourBedRoom: Int!
    $threeBedRoom: Int!
    $twoBedRoom: Int!
    $oneBedRoom: Int!
    $originationFee: float8!
    $points: float8!
  ) {
    __typename
    insert_campaigns(
      objects: {
        emailed: $emailed
        id: $campaignId
        user_id: $userId
        status: $status
        zipcode: $zipcode
        priceMax: $priceMax
        priceMin: $priceMin
      }
    ) {
      affected_rows
    }
    insert_calculationValues(
      objects: {
        campaignId: $campaignId
        capExPercent: $capExPercent
        downPaymentPercentage: $downPaymentPercentage
        fiveBedRoom: $fiveBedRoom
        fourBedRoom: $fourBedRoom
        homeInsurance: $homeInsurance
        interestRate: $interestRate
        loanLengthYears: $loanLengthYears
        oneBedRoom: $oneBedRoom
        points: $points
        originationFee: $originationFee
        propertyManagementPercent: $propertyManagementPercent
        propertyTaxRate: $propertyTaxRate
        repairsPercent: $repairsPercent
        threeBedRoom: $threeBedRoom
        twoBedRoom: $twoBedRoom
        vacancyPercent: $vacancyPercent
      }
    ) {
      affected_rows
    }
    insert_property(objects: $properties) {
      affected_rows
    }
  }
`;

export const FETCH_CAMPAIGNS = `
  query Campaigns {
    campaigns {
      priceMax
      priceMin
      zipcode
      user_id
      calculationValue
      propertyList
    }
  }
`;

export const GET_SINGLE_PROPERTY = gql`
  query GetSingleProperty($propertyId: uuid!) {
    __typename
    property(where: { id: { _eq: $propertyId } }) {
      address
      rentEstimate
      baths
      baths_full
      beds
      building_size
      cashOnCash
      cashflow
      closingCost
      description
      hoa_fee
      id
      last_update
      lot_size
      mls
      photo_count
      photos
      price
      prop_status
      prop_type
      property_id
      rdc_web_url
      thumbnail
      year_built
    }
  }
`;

export const GET_USER_CAMPAIGNS = gql`
  query Campaigns($userId: String!) {
    campaigns(where: { user_id: { _eq: $userId } }) {
      priceMax
      priceMin
      emailed
      status
      zipcode
      properties {
        address
        rentEstimate
        baths
        baths_full
        beds
        building_size
        campaignId
        cashOnCash
        cashflow
        closingCost
        description
        error
        fetch
        hoa_fee
        last_update
        id
        lot_size
        mls
        photo_count
        photos
        price
        prop_status
        prop_type
        property_id
        rdc_web_url
        thumbnail
        year_built
      }
      calculationValue {
        capExPercent
        downPaymentPercentage
        fiveBedRoom
        fourBedRoom
        homeInsurance
        interestRate
        loanLengthYears
        oneBedRoom
        originationFee
        points
        propertyManagementPercent
        propertyTaxRate
        repairsPercent
        threeBedRoom
        twoBedRoom
        vacancyPercent
      }
    }
  }
`;

export const GET_USER_CAMPAIGN_HOMES = gql`
  query GetSingleCampaign($userId: String!, $zipcode: Int!) {
    campaigns(
      where: { user_id: { _eq: $userId }, zipcode: { _eq: $zipcode } }
    ) {
      id
      priceMax
      priceMin
      emailed
      status
      zipcode
      properties {
        address
        rentEstimate
        baths
        baths_full
        beds
        building_size
        campaignId
        cashOnCash
        cashflow
        closingCost
        description
        error
        fetch
        hoa_fee
        last_update
        id
        lot_size
        mls
        photo_count
        photos
        price
        prop_status
        prop_type
        property_id
        rdc_web_url
        thumbnail
        year_built
      }
      calculationValue {
        capExPercent
        downPaymentPercentage
        fiveBedRoom
        fourBedRoom
        homeInsurance
        interestRate
        loanLengthYears
        oneBedRoom
        originationFee
        points
        propertyManagementPercent
        propertyTaxRate
        repairsPercent
        threeBedRoom
        twoBedRoom
        vacancyPercent
      }
    }
  }
`;

// TODO: Update associated calculations value & update propertyList
// 1. get campaignID
// 2. grab calculationValue based off of campaignID and update values
// 3. loop through propertyList that match campaignID and update cashflow & cashOnCash
export const UPDATE_SINGLE_CAMPAIGN = gql`
  mutation updateCampaigns(
    $zipcode: Int!
    $emailed: Int!
    $status: String!
    $priceMax: Int
    $priceMin: Int
    $user_id: String!
  ) {
    __typename
    update_campaigns(
      where: { user_id: { _eq: $user_id }, zipcode: { _eq: $zipcode } }
      _set: {
        homes: $homes
        capExPercent: $capExPercent
        downPaymentPercentage: $downPaymentPercentage
        emailed: $emailed
        fiveBedRoom: $fiveBedRoom
        fourBedRoom: $fourBedRoom
        homeInsurance: $homeInsurance
        interestRate: $interestRate
        loanLengthYears: $loanLengthYears
        oneBedRoom: $oneBedRoom
        priceMax: $priceMax
        priceMin: $priceMin
        propertyManagementPercent: $propertyManagementPercent
        propertyTaxRate: $propertyTaxRate
        repairsPercent: $repairsPercent
        originationFee: $originationFee
        points: $points
        status: $status
        threeBedRoom: $threeBedRoom
        twoBedRoom: $twoBedRoom
        user_id: $user_id
        vacancyPercent: $vacancyPercent
        zipcode: $zipcode
      }
    ) {
      affected_rows
    }
  }
`;
